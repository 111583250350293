import * as yup from 'yup';

class CreateClientIdModel {
  static readonly createMerchantClient = yup.object({
    merchantClientId: yup.string().nullable().optional(),
    rootUrl: yup.string().nullable().optional(),
    redirectUris: yup
      .array()
      .of(yup.string().url("'Redirect URI' must be a valid URL."))
      .optional(),
    companyName: yup.string().nullable().optional(),
    firstName: yup.string().nullable().optional(),
    lastName: yup.string().nullable().optional(),
    email: yup.string().nullable().optional(),
    phoneNumber: yup.string().nullable().optional(),
    webURL: yup.string().nullable().optional(),
    applicationDomainURL: yup.string().nullable().optional(),
    // isGuestModeEnabled: yup.boolean().nullable().optional(),
    isLive: yup.boolean().nullable().optional(),
  });
}

export default CreateClientIdModel;
