import React, { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TableSortLabel,
  Switch,
  Pagination,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CommonConstants from 'src/constants/common.constant';
import UtilityService from 'src/services/utlits.service';
import { TableColumnData, TableRowData } from 'src/types/table.model';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface SquareProps {
  gradient: string;
  columnId: string | number | symbol;
  requestType: number;
}

const Square: React.FC<SquareProps> = React.memo(
  ({ gradient, columnId, requestType }) => {
    return (
      <Box
        sx={{
          background: `${gradient}`,
          height: '40px',
          width: '40px',
          marginRight: '14px',
          borderRadius: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '@media (max-width: 600px)': {
            height: '30px',
            width: '30px',
            marginRight: '10px',
          },
        }}
      >
        {columnId === 'admin' && (
          <PersonIcon
            sx={{
              marginTop: '4px',
              color: 'white',
              marginLeft: '4px',
              marginRight: '4px',
              width: '30px',
              height: '30px',
              '@media (max-width: 600px)': {
                width: '20px',
                height: '20px',
              },
            }}
          />
        )}
        {columnId === 'merchantClientId' && (
          <StoreOutlinedIcon
            sx={{
              marginTop: '4px',
              color: 'white',
              marginLeft: '4px',
              marginRight: '4px',
              width: '30px',
              height: '30px',
              '@media (max-width: 600px)': {
                width: '20px',
                height: '20px',
              },
            }}
          />
        )}
        {columnId === 'merchantRequestedClientId' &&
          (requestType === CommonConstants.requestType.newAdmin ? (
            <PersonIcon
              sx={{
                marginTop: '4px',
                color: 'white',
                marginLeft: '4px',
                marginRight: '4px',
                width: '30px',
                height: '30px',
                '@media (max-width: 600px)': {
                  width: '20px',
                  height: '20px',
                },
              }}
            />
          ) : (
            <StoreOutlinedIcon
              sx={{
                marginTop: '4px',
                color: 'white',
                marginLeft: '4px',
                marginRight: '4px',
                width: '30px',
                height: '30px',
                '@media (max-width: 600px)': {
                  width: '20px',
                  height: '20px',
                },
              }}
            />
          ))}
      </Box>
    );
  }
);

export interface DataTableProps<T> {
  columns: TableColumnData<T>[];
  rows: T[];
  page: number;
  rowsPerPage: number;
  loading?: boolean;
  withCheckBox?: boolean;
  onRowClick?: (row: T) => void;
  onRowSelect?: (selected: string[]) => void;
  selectedRows?: string[];
  onPageChange: (page: number) => void;
  totalRecords?: number;
}

type Order = 'asc' | 'desc';

const useGradients = (rows: any[], isActiveMerchantsView: boolean) => {
  const rowActiveMerchantsColors = useSelector(
    (state: any) => state.superAdmin.rowActiveMerchantsColors
  );

  const gradients = useMemo(() => {
    return rows.reduce(
      (acc, row) => {
        if (isActiveMerchantsView) {
          if (rowActiveMerchantsColors[row.merchantId]) {
            acc[row.merchantId] = rowActiveMerchantsColors[row.merchantId];
          } else {
            const newGradient = UtilityService.generateLinearGradient();
            acc[row.merchantId] = newGradient;
          }
        } else {
          acc[row.id] = acc[row.id] || UtilityService.generateLinearGradient();
        }
        return acc;
      },
      {} as Record<string, string>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowActiveMerchantsColors,
    rows.map((row) => row.id).join(','),
    isActiveMerchantsView,
  ]);

  return gradients;
};

const DataTable = <T extends TableRowData>({
  columns,
  rows,
  page,
  rowsPerPage,
  loading = false,
  withCheckBox = false,
  onRowClick,
  onRowSelect,
  selectedRows = [],
  onPageChange,
  totalRecords = 0,
}: DataTableProps<T>) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof T | ''>('');

  const { t } = useTranslation();
  const location = useLocation();
  const isActiveMerchantsView =
    location.pathname === '/active-merchants' ||
    location.pathname === 'manage-requests';

  const gradients = useGradients(rows, isActiveMerchantsView);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((row) => row.id as string);
      onRowSelect?.(newSelected);
      return;
    }
    onRowSelect?.([]);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    onRowSelect?.(newSelected);
  };

  const handleSwitchChange = (id: string) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, disabled: !row.disabled };
      }
      return row;
    });

    onRowSelect?.(updatedRows.map((row) => row.id as string));
  };

  const isSelected = (id: string) => selectedRows.indexOf(id) !== -1;

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    onPageChange(newPage);
  };

  const handleRequestSort = (property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const uniqueRows = useMemo(() => {
    const seen = new Set();
    return rows.filter((row) => {
      const uniqueKey = `${row.id}-${row.email || 'no-email'}`;
      if (seen.has(uniqueKey)) {
        return false;
      }
      seen.add(uniqueKey);
      return true;
    });
  }, [rows]);

  const sortedRows = useMemo(() => {
    if (orderBy === '') return uniqueRows;

    return uniqueRows.sort((a, b) => {
      const aValue = String(a[orderBy]).toLowerCase();
      const bValue = String(b[orderBy]).toLowerCase();

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [uniqueRows, order, orderBy]);

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' bgcolor='white'>
          <TableHead>
            <TableRow>
              {withCheckBox && (
                <TableCell padding='checkbox'>
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < rows.length
                    }
                    checked={
                      rows.length > 0 && selectedRows.length === rows.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={String(column.id)}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  {column.sort ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id as keyof T)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              height: loading ? 365 : rows.length === 0 ? 300 : 'auto',
            }}
          >
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length + (withCheckBox ? 1 : 0)}
                  align='center'
                >
                  <CircularProgress />
                  <Typography variant='body2' mt={1}>
                    {t('TABLE.LOADING')}...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : rows.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length + (withCheckBox ? 1 : 0)}
                  align='center'
                  width='100%'
                >
                  <Typography variant='body2'>{t('TABLE.NO_DATA')}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              sortedRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.id}-${index}`}
                    selected={isItemSelected}
                    onClick={() => {
                      handleClick(row.id);
                      onRowClick && onRowClick(row);
                    }}
                  >
                    {withCheckBox && (
                      <TableCell
                        padding='checkbox'
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClick(row.id);
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => (
                      <TableCell key={String(column.id)} align={column.align}>
                        {column.render ? (
                          column.id === 'disabled' ? (
                            <Switch
                              checked={row.disabled}
                              onClick={(e) => e.stopPropagation()}
                              onChange={() => handleSwitchChange(row.id)}
                            />
                          ) : (
                            <div onClick={(e) => e.stopPropagation()}>
                              {column.render(row)}
                            </div>
                          )
                        ) : (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {column.withSquareColor && (
                              <Square
                                gradient={
                                  isActiveMerchantsView
                                    ? gradients[row.merchantId]
                                    : gradients[row.id]
                                }
                                columnId={column.id}
                                requestType={row.requestType}
                              />
                            )}
                            <span>{row[column.id]}</span>
                          </div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && rows.length > 0 && (
        <Box display='flex' justifyContent='center' mt={2}>
          <Pagination
            count={Math.ceil(totalRecords / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color='primary'
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Paper>
  );
};

export default DataTable;
