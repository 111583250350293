import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux-store/store';
import { superAdminSliceAction } from '../../super-admin-reducer';
import { winkLoginSliceActions } from 'src/components/wink-login/wink-login.reducer';
import { drawerActions } from 'src/components/sidedrawer/drawer-reducer';

import { useSuperAdminInfo } from 'src/hooks/useIsSuperAdmin';
import WinkLoginService from 'src/components/wink-login/wink-login.service';

import SettingsModal from 'src/components/settings-modal/settings-modal.component';
import ActiveClientListView from './active-client-list-view';

import styles from './active-client-list.module.scss';
import './active-client-list.module.scss';
import CommonConstants from 'src/constants/common.constant';

export type HandleRowClickType = (row: any) => void;

const ActiveClientList: React.FC = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [shouldOpenDrawer, setShouldOpenDrawer] = useState(false);
  const [clickCounter, setClickCounter] = useState(0);

  const { containerClassName } = useSuperAdminInfo();

  const settingsModalIsOpen = useSelector(
    (state: RootState) => state.superAdmin.isSettingsModalOpen,
  );
  const merchantSelected = useSelector(
    (state: RootState) => state.superAdmin.merchantSelected,
  );
  const userDataRedux = useSelector((state: any) => state.winkLogin.userData);

  const superAdmin =
    userDataRedux.userType === CommonConstants.userType.superAdmin;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(superAdminSliceAction.closeSettingsModal());
  };

  const handleRowClick: HandleRowClickType = (row) => {
    if (row.status === 'Active' && !settingsModalIsOpen) {
      setShouldOpenDrawer(true);
      dispatch(superAdminSliceAction.setMerchantSelected(row));
      setClickCounter((prev) => prev + 1);
    }
  };

  useEffect(() => {
    (async () => {
      if (
        merchantSelected?.merchantId &&
        merchantSelected?.merchantId !== userDataRedux.merchantId
      ) {
        dispatch(winkLoginSliceActions.setFetchingUserIdentify(true));
        setGeneralLoading(true);
        const resp = await WinkLoginService.getUserProfile(
          merchantSelected?.merchantId,
        );
        dispatch(winkLoginSliceActions.setFetchingUserIdentify(false));
        setGeneralLoading(false);
        if (resp) {
          dispatch(winkLoginSliceActions.setUserData(resp));
          if (shouldOpenDrawer) {
            dispatch(drawerActions.openDrawer(merchantSelected));
          }
        }
      } else if (merchantSelected?.merchantId === userDataRedux.merchantId) {
        if (shouldOpenDrawer) {
          dispatch(drawerActions.openDrawer(merchantSelected));
        }
      }
    })();
  }, [
    dispatch,
    merchantSelected,
    merchantSelected?.merchantId,
    userDataRedux.merchantId,
    shouldOpenDrawer,
    clickCounter,
  ]);

  const handleSuperAdminAction = (actionId: string) => {
    switch (actionId) {
      case 'merchantProfile':
        navigate('/merchant-profile');
        break;
      case 'inviteMerchantAdmin':
        navigate('/assign-adminto-clientId');
        break;
      case 'adminInvitations':
        navigate('/manage-admin', {
          state: { merchantSelected, to: 'invitations' },
        });
        break;
      case 'modifyAdminUser':
        navigate('/manage-admin', {
          state: { merchantSelected, to: 'status' },
        });
        break;
      case 'enableProductionAccess':
        console.log('Clicked enableProductionAccess');
        break;
      case 'disableMerchant':
        console.log('Clicked disableMerchant');
        break;

      default:
        break;
    }
    dispatch(superAdminSliceAction.closeSettingsModal());
  };

  const settingsSuperAdminOptionsModal = [
    {
      text: t('LABEL.MERCHANT_PROFILE'),
      actionId: 'merchantProfile',
      withIcon: true,
    },
    {
      text: t('LABEL.INVITE_MERCHANT_ADMIN'),
      actionId: 'inviteMerchantAdmin',
      withIcon: true,
    },
    {
      text: t('LABEL.MANAGE_ADMIN_INVITATIONS'),
      actionId: 'adminInvitations',
      withIcon: true,
    },
    {
      text: t('LABEL.MODIFY_ADMIN_USER'),
      actionId: 'modifyAdminUser',
      withIcon: true,
    },
    {
      text: t('LABEL.ENABLE_PROD_ACCESS'),
      actionId: 'enableProductionAccess',
      withIcon: false,
    },
    {
      text: t('LABEL.DIABLE_MERCHANT'),
      actionId: 'disableMerchant',
      withIcon: false,
    },
  ];

  const handleClientAdminAction = (actionId: string) => {
    switch (actionId) {
      case 'merchantProfile':
        navigate('/merchant-profile');
        break;
      case 'inviteMerchantAdmin':
        navigate('/assign-adminto-clientId');
        break;
      case 'requestProductionAccess':
        console.log('Clicked requestProductionAccess');
        break;
      default:
        break;
    }
    dispatch(superAdminSliceAction.closeSettingsModal());
  };

  const settingsClientAdminOptionsModal = [
    {
      text: t('LABEL.MERCHANT_PROFILE'),
      actionId: 'merchantProfile',
      withIcon: true,
    },
    {
      text: t('LABEL.INVITE_MERCHANT_ADMIN'),
      actionId: 'inviteMerchantAdmin',
      withIcon: true,
    },
    {
      text: t('LABEL.REQUEST_PROD_ACCESS'),
      actionId: 'requestProductionAccess',
      withIcon: true,
    },
  ];

  return (
    <div
      id='active_merchants_container'
      className={classNames(styles.layout_main_container, {
        [styles.super_admin]: containerClassName.includes('super-admin'),
      })}
    >
      <ActiveClientListView
        isFetching={isFetching}
        setIsFetching={setIsFetching}
        generalLoading={generalLoading}
        setShouldOpenDrawer={setShouldOpenDrawer}
        handleRowClick={handleRowClick}
      />
      <SettingsModal
        isOpen={settingsModalIsOpen}
        containerId={() =>
          document.getElementById('active_merchants_container')
        }
        options={
          superAdmin
            ? settingsSuperAdminOptionsModal
            : settingsClientAdminOptionsModal
        }
        handleClose={handleClose}
        handleAction={
          superAdmin ? handleSuperAdminAction : handleClientAdminAction
        }
        title='Merchant ID:'
        subtitle={merchantSelected?.company}
      />
    </div>
  );
};

export default ActiveClientList;
