import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import {
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Typography,
  Menu,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  white,
  envSelectProd,
  envSelectSand,
  envSelectColor,
  loginAccessInfo,
} from 'src/assets/styles/variables';
import WinkLogo from 'src/assets/images/wink-logo-white.png';
import style from 'src/components/common/header/header.module.scss';
import { winkLoginSliceActions } from 'src/components/wink-login/wink-login.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { superAdminSliceAction } from 'src/containers/super-admin/super-admin-reducer';

const HeaderComponent: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openPorfileMenu = Boolean(anchorEl);

  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const userDataState = useSelector((state: any) => state.winkLogin.userData);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { keycloak } = useKeycloak();

  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    localStorage.setItem('logoutUser', 'true');
    dispatch(winkLoginSliceActions.setAccessToken(''));
    dispatch(winkLoginSliceActions.setUserData({}));
    keycloak.logout({ redirectUri: window.location.origin });
  };

  useEffect(() => {
    selectedEnvironment &&
      dispatch(superAdminSliceAction.setEnvironment(selectedEnvironment));
  }, [dispatch, selectedEnvironment]);

  return (
    <Grid
      container
      className={style.container}
      alignItems='center'
      justifyContent='space-between'
      wrap='wrap'
    >
      <Grid
        item
        xs={12}
        sm={2}
        md={4}
        justifyContent={{
          xs: 'center',
          sm: 'flex-start',
        }}
        display='flex'
      >
        <Link to='/' className={style.clickeable}>
          <img
            data-testid='logoImg'
            className={style.winkLogo}
            src={WinkLogo}
            alt='Logo'
          />
        </Link>
      </Grid>

      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        display='flex'
        justifyContent={{ xs: 'center', md: 'flex-end' }}
        alignItems='center'
        gap={2}
        flexWrap='wrap'
        py={{
          xs: 1,
          md: 'auto',
        }}
      >
        <Box display='flex' alignItems='center'>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={selectedEnvironment}
              onChange={(e) => setSelectedEnvironment(e.target.value)}
              sx={{
                width: { xs: '100%', md: 225 },
                maxHeight: 30,
                color: envSelectColor,
                marginTop: '3px',
                fontWeight: 500,
                fontSize: 15,
                textTransform: 'uppercase',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${envSelectColor}`,
                },
                '& .MuiSelect-icon': {
                  color: '#9197A48F',
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: 5,
                    backgroundColor: white,
                    padding: '5px',
                  },
                },
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <span>{t('LABEL.ENVIRONMENT_SELECTOR')}</span>;
                }
                return selected;
              }}
            >
              <MenuItem value='Production'>
                <Box display='flex' alignItems='center'>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: envSelectProd,
                      marginRight: 1,
                      padding: '5px',
                    }}
                  />
                  {t('LABEL.PRODUCTION')}
                </Box>
              </MenuItem>
              <Divider />
              <MenuItem value='Sandbox'>
                <Box display='flex' alignItems='center'>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      backgroundColor: envSelectSand,
                      marginRight: 1,
                      padding: '5px',
                    }}
                  />
                  {t('LABEL.SANDBOX')}
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Link to='/settings' className={style.clickeable}>
          <Box display='none' alignItems='center' gap='8px'>
            <SettingsIcon />
            <span>{t('LABEL.SETTINGS')}</span>
          </Box>
        </Link>
        <Divider
          flexItem
          orientation='vertical'
          sx={{ color: envSelectColor, opacity: 0.6, my: '10px' }}
        />

        <Box
          display='flex'
          flexDirection='column'
          alignItems={{ xs: 'center', sm: 'flex-end' }}
          justifyContent='flex-end'
          sx={{
            transition: 'color 0.3s ease',
            '&:hover': {
              cursor: 'pointer',
            },
            '&:hover *': {
              color: loginAccessInfo,
              transition: 'color 0.3s ease',
            },
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={handleClickProfile}
          >
            <Box
              sx={{
                width: 30,
                height: 30,
                bgcolor: '#65B0B880',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 1,
              }}
            >
              <Typography variant='subtitle2' color='white'>
                {(userDataState?.firstName ? userDataState.firstName[0] : '') +
                  (userDataState?.lastName ? userDataState.lastName[0] : '')}
              </Typography>
            </Box>
            <Typography sx={{ color: 'white', mr: 1 }}>
              {userDataState.firstName} {userDataState.lastName}
            </Typography>
            <ArrowDropDownIcon sx={{ color: 'white' }} />
          </Box>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openPorfileMenu}
            onClose={handleCloseProfile}
          >
            <MenuItem onClick={handleCloseProfile} sx={{display:'none'}}>
              <ListItemIcon>
                <PersonIcon fontSize='small' sx={{ color: 'gray' }} />
              </ListItemIcon>
              <ListItemText primary={t('LABEL.PROFILE')} />
            </MenuItem>
            <MenuItem onClick={logoutUser}>
              <ListItemIcon>
                <LogoutIcon fontSize='small' sx={{ color: 'gray' }} />
              </ListItemIcon>
              <ListItemText primary={t('BUTTON.SIGN_OUT')} />
            </MenuItem>
          </Menu>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeaderComponent;
