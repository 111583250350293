import { httpClient } from 'src/services/http.service';

import CommonConstants from 'src/constants/common.constant';
import MerchantModel from './merchant-admin.model';

const client = httpClient(true);
class MerchantAdminService {
  static activeMerchant = async () => {
    return client.get(
      CommonConstants.api.getMerchantList,
      null,
      MerchantModel.activeMerchantList,
    );
  };

  static merchantWinkTag = async (data: any) => {
    return client.post(
      CommonConstants.api.getMerchantWinkTag,
      data,
      MerchantModel.winkTagList,
    );
  };

  static joinToMerchant = async (data: any) => {
    return client.post(
      CommonConstants.api.joinToMerchant,
      data,
      MerchantModel.joinMerchant,
    );
  };

  static merchantInvitations = async (
    searchText: string,
    pageNumber = 1,
    pageSize = 10,
  ) => {
    const payload = {
      SearchText: searchText,
      PageNumber: pageNumber,
      PageSize: pageSize,
    };

    return client.post(
      CommonConstants.api.getMerchantAdminInvitations,
      payload,
      MerchantModel.merchantAdminInvitations,
    );
  };

  static merchantAdmins = async (
    searchText: string,
    pageNumber = 1,
    pageSize = 10,
  ) => {
    const payload = {
      searchText,
      pageNumber,
      pageSize,
    };

    return client.post(
      CommonConstants.api.getMerchantAdmins,
      payload,
      MerchantModel.merchantAdmin,
    );
  };

  static updateAdminStatus = async (payload: {
    merchantClientId: string;
    winkTag: string;
    adminStatus: number;
  }) => {
    return client.instance.put(
      CommonConstants.api.updateMerchantAdminStatus,
      payload,
    );
  };

  static deleteAdminInvitation = async (payload: {
    requestTokens: string[];
  }) => {
    return client.instance.delete(
      CommonConstants.api.deleteMerchantAdminInvitation,
      {
        data: payload,
      },
    );
  };

  static deleteAdmin = async (payload: { clientUserIds: number[] }) => {
    return client.instance.delete(CommonConstants.api.deleteMerchantAdmin, {
      data: payload,
    });
  };
}

export default MerchantAdminService;
