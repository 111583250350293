import CommonConstants from 'src/constants/common.constant';
import { httpClient } from 'src/services/http.service';
import CreateClientIdModel from './create-client-id.model';
import UtilityService from 'src/services/utlits.service';
import { mapProductTypeToAccessType } from 'src/containers/merchant-profile/merchant-profile.services';

class CreateClientIdServices {
  static createNewMerchantClient = async (data: any, isSuperAdmin: boolean) => {
    try {
      const formattedRedirectUris = data.redirectUris
        .map((uri: { url: string }) => UtilityService.formatDomain(uri.url))
        .filter((domain: string) => domain !== '');

      const fullData = {
        merchantClientId: data.merchantClientId,
        firstName: data.firstName,
        lastName: data.lastName,
        applicationDomainURL: UtilityService.formatDomain(
          data.applicationDomainURL,
        ),
        webURL: UtilityService.formatDomain(data.webURL),
        redirectUris: formattedRedirectUris,
        companyName: data.companyName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        isLive: data.isLive,
        applicationAccessType: mapProductTypeToAccessType(data.serviceType),
        EnabledMarketingOptIn: data.EnabledMarketingOptIn,
      };

      const client = httpClient();
      return client.post(
        isSuperAdmin
          ? CommonConstants.api.createMerchantClient
          : CommonConstants.api.requestMerchantClient,
        fullData,
        CreateClientIdModel.createMerchantClient,
      );
    } catch (e: any) {
      return Promise.reject(e.errors);
    }
  };
}

export default CreateClientIdServices;
