/* eslint-disable react-hooks/exhaustive-deps */
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { winkLoginSliceActions } from 'src/components/wink-login/wink-login.reducer';
import WinkLoginService from 'src/components/wink-login/wink-login.service';
import CommonConstants from 'src/constants/common.constant';
import Loader from '../loader/loader.component';

const IdleTimeOutHandler: React.FC = () => {
  const userData = useSelector((state: any) => state.winkLogin.userData);
  const merchantSelected = useSelector(
    (state: any) => state.superAdmin.merchantSelected,
  );
  const [isLoading, setIsLoading] = useState(false);

  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  let idleTime = 0;
  const events = [
    'click',
    'load',
    'keydown',
    'mousemove',
    'mousedown',
    'scroll',
    'keypress',
  ];

  const logoutUser = async () => {
    keycloak.logout({ redirectUri: window.location.origin });
  };

  const refreshToken = () => {
    if (keycloak.authenticated) {
      setIsLoading(true);
      keycloak
        ?.updateToken(CommonConstants.idleTimeCountdown.idleTime)
        .then(async function (refreshed) {
          if (refreshed) {
            dispatch(winkLoginSliceActions.setAccessToken(keycloak.token));
            const resp = await WinkLoginService.getUserProfile(
              merchantSelected?.merchantId ?? '',
            );
            if (resp) {
              dispatch(winkLoginSliceActions.setUserData(resp));
            }
            handleIdleTime();
            setIsLoading(false);
          } else {
            console.debug('Token is still valid');
            setIsLoading(false);
          }
        })
        .catch(function () {
          console.debug(
            'Failed to refresh the token, or the session has expired',
          );
          setIsLoading(false);
        });
    }
  };

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, handleIdleTime);
    });
  };
  const handleIdleTime = () => {
    idleTime = 0;
  };

  const timerIncrement = () => {
    idleTime++;
    const curTime = new Date();
    if (
      keycloak?.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.exp
    ) {
      const expTime = new Date(
        keycloak?.tokenParsed?.exp *
          CommonConstants.idleTimeCountdown.oneSecond,
      );
      if (
        curTime.getTime() >= expTime.getTime() &&
        idleTime < CommonConstants.idleTimeCountdown.idleTime
      ) {
        refreshToken();
      }
    }
    if (idleTime >= CommonConstants.idleTimeCountdown.idleTime) {
      // logout user if idle for more than 5 mins
      console.debug('logout user');
      logoutUser();
    }
  };

  useEffect(() => {
    if (Object.keys(userData)?.length > 0) {
      // Increment the idle time counter every second.
      const idleInterval = setInterval(timerIncrement, 1000);
      addEvents();
      return () => {
        clearTimeout(idleInterval);
      };
    } else {
      return () => {};
    }
  }, [addEvents, timerIncrement, userData]);

  return (
    <>
      {' '}
      <Loader loading={isLoading} />
    </>
  );
};
export default IdleTimeOutHandler;
