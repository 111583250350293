import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Divider,
  Container,
  TextField,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  Tooltip,
  Box,
  Checkbox,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { InfoOutlined } from '@mui/icons-material';
import { RootState } from 'src/redux-store/store';
import { superAdminSliceAction } from '../super-admin-reducer';

import { CustomIconButton } from 'src/containers/merchant-profile/merchant-profile.view';
import Loader from 'src/components/common/loader/loader.component';
import { useSuperAdminInfo } from 'src/hooks/useIsSuperAdmin';
import CommonConstants from 'src/constants/common.constant';
import CreateClientIdServices from './create-client-id.services';
import { fieldColor, grey, white } from 'src/assets/styles/variables';
import styles from './create-client-id.module.scss';
import classNames from 'classnames';
import PopupComponent from 'src/components/popup/popup-component.component';
import ButtonComponent from 'src/components/button/button.component';
import UtilityService from 'src/services/utlits.service';

type IClient = {
  id: string;
};

const CreateClientID: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { containerClassName } = useSuperAdminInfo();
  const [newClient, setNewClient] = useState<IClient>({
    id: '',
  });
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openPopupAgreeTerms, setOpenPopupAgreeTerms] =
    useState<boolean>(false);

  const [popupMessage, setPopupMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const loading = useSelector((state: RootState) => state.superAdmin.loading);
  const environment = useSelector((state: any) => state.superAdmin.environment);
  const isSuperAdmin =
    useSelector((state: any) => state?.winkLogin?.userData?.userType) === 1;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      applicationDomainURL: '',
      webURL: '',
      redirectUris: [{ url: '' }],
      serviceType: 'Both',
      isLive: environment === t('LABEL.PRODUCTION'),
      agreeTerms: false,
      EnabledMarketingOptIn: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'redirectUris',
  });

  const formData = watch();

  useEffect(() => {
    dispatch(superAdminSliceAction.setheadingName(t('LABEL.CREATE_MERCHANT')));
  }, [dispatch, t]);

  const onSubmit = async (data: any) => {
    if (!isSuperAdmin && !data.agreeTerms) {
      setOpenPopupAgreeTerms(true);
      return;
    }

    dispatch(superAdminSliceAction.setSuperAdminLoading(true));

    try {
      const response: any =
        await CreateClientIdServices.createNewMerchantClient(
          formData,
          isSuperAdmin,
        );
      if (response?.isSuccess && response.merchantClientId) {
        setNewClient({ id: response.merchantClientId });
        setIsError(false);
        setOpenPopup(true);
        setPopupMessage(t('MESSAGE.MERCHANT_REQUEST_CREATED'));
      } else {
        console.error('Failed to create merchant client:', response?.Message);
        setOpenPopup(true);
        setPopupMessage(t('MESSAGE.MERCHANT_REQUEST_FAIL'));
        setIsError(true);
      }
    } catch (error) {
      console.error('API call failed', error);
      setOpenPopup(true);
      setPopupMessage(t('MESSAGE.MERCHANT_REQUEST_FAIL'));
      setIsError(true);
    } finally {
      dispatch(superAdminSliceAction.setSuperAdminLoading(false));
    }
  };

  const handleClosePopup = () => {
    if (!isError) {
      isSuperAdmin
        ? navigate('/active-merchants')
        : navigate('/active-merchantId');
    } else {
      setOpenPopup(false);
    }
  };
  const validatePhoneNumber = (value: any) => {
    return UtilityService.formatPhoneField(value)
  };
  const handlePhoneChange = (e: any) => {
    e.target.value = UtilityService.handlePhoneChange(e);
  };
  return (
    <div
      className={classNames(styles.layout_main_container, styles.container, {
        [styles.super_admin]: containerClassName.includes('super-admin'),
      })}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Loader loading={loading} />
        <Typography variant='h2'>
          {isSuperAdmin
            ? t('LABEL.CREATE_MERCHANT')
            : t('LABEL.REQUEST_MERCHANT')}
        </Typography>
        <Divider className={styles.container__divider} />
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {' '}
              <Typography
                variant='h3'
                className={styles.container__h3}
                color='#8D929C'
              >
                {t('MESSAGE.LEGAL_NAME')}
              </Typography>
            </Grid>
            <Grid item xs={12} marginTop={-1}>
              <TextField
                fullWidth
                required
                label={t('PLACEHOLDER.USER_COMPANY_NAME')}
                type='text'
                error={!!errors.companyName}
                {...register('companyName', {
                  required: t('ERROR_MESSAGE.COMPANY_REQUIRED'),
                  pattern: {
                    value: CommonConstants.validation.alphaNumeric,
                    message: t('ERROR_MESSAGE.COMPANY_INVALID'),
                  },
                  minLength: {
                    value: 2,
                    message: t('ERROR_MESSAGE.MINIMUM_CHAR_REQUIRED'),
                  },
                })}
                helperText={
                  errors.companyName
                    ? (errors.companyName as FieldError).message
                    : ''
                }
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} marginBottom={-1}>
              {' '}
              <Typography
                variant='h3'
                className={styles.container__h3}
                color='#8D929C'
              >
                {t('LABEL.SUPERADMIN_ENTER_DETAILS')}:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PLACEHOLDER.USER_FIRST_NAME')}
                fullWidth
                required
                {...register('firstName', {
                  required: t('ERROR_MESSAGE.FIRSTNAME_REQUIRED'),
                  pattern: {
                    value: CommonConstants.validation.name,
                    message: t('ERROR_MESSAGE.FIRSTNAME_INVALID'),
                  },
                  minLength: {
                    value: 2,
                    message: t('ERROR_MESSAGE.MINIMUM_CHAR_REQUIRED'),
                  },
                })}
                error={!!errors.firstName}
                helperText={
                  errors.firstName
                    ? (errors.firstName as FieldError).message
                    : ''
                }
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PLACEHOLDER.USER_LAST_NAME')}
                type='text'
                fullWidth
                required={true}
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
                {...register('lastName', {
                  required: t('ERROR_MESSAGE.LASTNAME_REQUIRED'),
                  pattern: {
                    value: CommonConstants.validation.name,
                    message: t('ERROR_MESSAGE.LASTNAME_INVALID'),
                  },
                  minLength: {
                    value: 2,
                    message: t('ERROR_MESSAGE.MINIMUM_CHAR_REQUIRED'),
                  },
                })}
                error={!!errors.lastName}
                helperText={
                  errors.lastName ? (errors.lastName as FieldError).message : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PLACEHOLDER.USER_EMAIL')}
                type='email'
                fullWidth
                required={true}
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
                {...register('email', {
                  required: t('ERROR_MESSAGE.EMAIL_REQUIRED'),
                  pattern: {
                    value: CommonConstants.validation.email,
                    message: t('ERROR_MESSAGE.EMAIL_INVALID'),
                  },
                })}
                error={!!errors.email}
                helperText={
                  errors.email ? (errors.email as FieldError).message : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='phoneNumber'
                control={control}
                rules={{
                  required: t('ERROR_MESSAGE.PHONE_REQUIRED'),
                  validate: validatePhoneNumber, // Validate using the custom regex function
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('LABEL.PHONE_NUMBER')}
                    type='phone'
                    fullWidth
                    required={true}
                    onChange={(e) => {
                      handlePhoneChange(e); // Sanitize input
                      field.onChange(e); // React Hook Form field change
                    }}
                    sx={{
                      backgroundColor: fieldColor,
                      '& .MuiFormHelperText-root': {
                        fontSize: '0.75rem',
                        backgroundColor: white,
                        margin: 0,
                        paddingLeft: '10px',
                      },
                    }}
                    error={!!errors.phoneNumber}
                    helperText={
                      errors.phoneNumber
                        ? (errors.phoneNumber as FieldError).message
                        : ''
                    }
                    inputProps={{ maxLength: '13' }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} marginBottom={-1}>
              {' '}
              <Typography
                variant='h3'
                className={styles.container__h3}
                color='#8D929C'
              >
                {t('MESSAGE.DOMAINS_INFO')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box width='100%' pb={3}>
                <TextField
                  label={t('PLACEHOLDER.WEB_URL')}
                  type='url'
                  fullWidth
                  required={true}
                  sx={{
                    backgroundColor: fieldColor,
                    '& .MuiFormHelperText-root': {
                      fontSize: '0.75rem',
                      backgroundColor: white,
                      margin: 0,
                      paddingLeft: '10px',
                    },
                  }}
                  {...register('webURL', {
                    required: t('ERROR_MESSAGE.WEB_URL_REQUIRED'),
                    pattern: {
                      value: CommonConstants.validation.links,
                      message: t('ERROR_MESSAGE.INVALID_URL'),
                    },
                  })}
                  error={!!errors.webURL}
                  helperText={
                    errors.webURL ? (errors.webURL as FieldError).message : ''
                  }
                />
              </Box>
              <Box display='flex' gap={2} alignItems='center'>
                <Box width='97%'>
                  <TextField
                    label={t('PLACEHOLDER.APPLICATION_DOMAIN_URL')}
                    type='url'
                    fullWidth
                    required={true}
                    sx={{
                      backgroundColor: fieldColor,
                      '& .MuiFormHelperText-root': {
                        fontSize: '0.75rem',
                        backgroundColor: white,
                        margin: 0,
                        paddingLeft: '10px',
                      },
                    }}
                    {...register('applicationDomainURL', {
                      required: t('ERROR_MESSAGE.APPLICATION_URL_REQUIRED'),
                      pattern: {
                        value: CommonConstants.validation.links,
                        message: t('ERROR_MESSAGE.INVALID_URL'),
                      },
                    })}
                    error={!!errors.applicationDomainURL}
                    helperText={
                      errors.applicationDomainURL
                        ? (errors.applicationDomainURL as FieldError).message
                        : ''
                    }
                  />
                </Box>
                <Box width='3%'>
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={3000}
                    title={
                      <span style={{ fontSize: '14px' }}>
                        URL for your server application that will direct users
                        to Wink inside your app or browser
                      </span>
                    }
                  >
                    <IconButton>
                      <InfoOutlined
                        sx={{
                          color: '#8D929C',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display='flex' flexDirection='column' gap={2}>
                {fields.map((field, index) => (
                  <Box key={field.id} display='flex' alignItems='center'>
                    <TextField
                      fullWidth
                      label={t('PLACEHOLDER.USER_WHITE_LISTED_DOMAIN')}
                      {...register(`redirectUris.${index}.url`, {
                        required: t('ERROR_MESSAGE.REDIRECT_URL_REQUIRED'),
                        pattern: {
                          value: CommonConstants.validation.links,
                          message: t('ERROR_MESSAGE.INVALID_URL'),
                        },
                      })}
                      required
                      type='url'
                      error={!!errors?.redirectUris?.[index]?.url}
                      helperText={
                        errors?.redirectUris?.[index]?.url
                          ? (errors.redirectUris[index]?.url as FieldError)
                              ?.message
                          : ''
                      }
                    />
                    {index === 0 ? (
                      <>
                        <CustomIconButton
                          isAdd={true}
                          onClick={() => append({ url: '' })}
                        />
                        <Tooltip
                          enterTouchDelay={0}
                          leaveTouchDelay={3000}
                          title={
                            <span style={{ fontSize: '14px' }}>
                              URL for your server application where Wink will
                              redirect users after a successful login to your
                              app
                            </span>
                          }
                        >
                          <IconButton>
                            <InfoOutlined
                              sx={{
                                color: '#8D929C',
                                width: '20px',
                                height: '20px',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <CustomIconButton
                        isAdd={false}
                        onClick={() => remove(index)}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} mt={isSuperAdmin ? 4 : 'auto'}>
              {' '}
              <Typography
                variant='h3'
                className={styles.container__h3}
                color='#8D929C'
              >
                {t('LABEL.SUPERADMIN_SELECT_PRODUCT')}:
              </Typography>
            </Grid>

            <Grid item xs={12} mb={isSuperAdmin ? 2 : 'auto'}>
              <Controller
                name='serviceType'
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '70%',
                      margin: 'auto',
                    }}
                  >
                    <FormControlLabel
                      value='Login'
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {t('PLACEHOLDER.USER_SERVICE_TYPE_WL')}
                          <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={3000}
                            title={
                              <span style={{ fontSize: '14px' }}>
                                Multi-factor password-less authentication
                                solutions
                              </span>
                            }
                          >
                            <IconButton>
                              <InfoOutlined
                                sx={{
                                  color: '#8D929C',
                                  width: '18px',
                                  height: '18px',
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                    />

                    <FormControlLabel
                      value='Payment'
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {t('PLACEHOLDER.USER_SERVICE_TYPE_WP')}
                          <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={3000}
                            title={
                              <span style={{ fontSize: '14px' }}>
                                Biometric payments and express checkout
                                solutions
                              </span>
                            }
                          >
                            <IconButton>
                              <InfoOutlined
                                sx={{
                                  color: '#8D929C',
                                  width: '18px',
                                  height: '18px',
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                    />
                    <FormControlLabel
                      value='Both'
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {t('PLACEHOLDER.USER_SERVICE_TYPE_B')}
                        </div>
                      }
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            {!isSuperAdmin && (
              <Grid item xs={12}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register('agreeTerms')}
                        sx={{
                          color: grey,
                          '&.Mui-checked': { color: grey },
                        }}
                      />
                    }
                    label={t('MESSAGE.CHECK_TERMS_CONDITIONS')}
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register('EnabledMarketingOptIn')}
                        sx={{
                          color: grey,
                          '&.Mui-checked': { color: grey },
                        }}
                      />
                    }
                    label={t('MESSAGE.CHECK_COMMUNICATIONS')}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} textAlign='center'>
              <ButtonComponent
                variant='outlined'
                type='submit'
                disabled={
                  !isValid ||
                  isSubmitting ||
                  (!isSuperAdmin && !formData.agreeTerms)
                }
              >
                {isSuperAdmin
                  ? t('LABEL.CREATE_MERCHANT')
                  : t('BUTTON.REQUEST_MERCHANTID')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </Container>
      </form>
      <PopupComponent
        open={openPopup}
        onClose={handleClosePopup}
        title={popupMessage}
        error={isError}
        inputValue={newClient?.id}
        label={t('PLACEHOLDER.MERCHANTID')}
      />
      <PopupComponent
        open={openPopupAgreeTerms}
        onClose={() => setOpenPopupAgreeTerms(false)}
        title={t('MESSAGE.CHECK_AGREE')}
      />
    </div>
  );
};

export default CreateClientID;
