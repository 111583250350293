import React, { useEffect } from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import MultiInput from '../../../components/multi-input/multi-input.component';
import style from './assign-admin-to-clientId.module.scss';
import ButtonComponent from '../../../components/button/button.component';
import PopupComponent from '../../../components/popup/popup-component.component';
import ActiveClientServices from './assign-admin-to-clientId.services';
import { useDispatch, useSelector } from 'react-redux';
import CommonConstants from 'src/constants/common.constant';
import { RootState } from 'src/redux-store/store';
import MerchantAdminService from 'src/containers/merchant-admin/merchant-admin.service';
import { useNavigate } from 'react-router-dom';
import { superAdminSliceAction } from '../super-admin-reducer';

const AssignAdmintToClientIDView: React.FC<{
  dataEntered: boolean;
  popupOpen: boolean;
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
  options: any[];
  winkTagList: any[];
  winkTagCount: number;
  handleWinkTagSendInvitation: (event: React.FormEvent) => void;
  handleEmailSendInvitation: (event: React.FormEvent) => void;
  handleClosePopup: () => void;
  getSelectedWinkTag: (value: any) => void;
  handleChange: (event: any, value: string | null) => void;
  searchClient: (event: any) => void;
  t: (key: string) => string;
  dispatch: any;
  setOptions: React.Dispatch<React.SetStateAction<any[]>>;
  setWinkTagList: React.Dispatch<React.SetStateAction<any[]>>;
}> = ({
  dataEntered,
  popupOpen,
  emails,
  setEmails,
  winkTagList,
  winkTagCount,
  handleWinkTagSendInvitation,
  handleEmailSendInvitation,
  handleClosePopup,
  getSelectedWinkTag,
  searchClient,
  t,
  setWinkTagList,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDataState = useSelector((state: any) => state.winkLogin.userData);
  const isSuperAdmin =
    userDataState?.userType === CommonConstants.userType.superAdmin;
  const merchantSelected = useSelector(
    (state: RootState) => state.superAdmin.merchantSelected,
  );

  useEffect(() => {
    (async () => {
      if (isSuperAdmin) {
        const allWinkTagList = await ActiveClientServices.getWinkTagList('');
        setWinkTagList(allWinkTagList.data.map((val: any) => val.winkTag));
      } else {
        const params = {
          searchText: '',
          pageNumber: 0,
          pageSize: 0,
        };
        const allWinkTagList =
          await MerchantAdminService.merchantWinkTag(params);
        setWinkTagList(allWinkTagList.data.map((val: any) => val.winkTag));
      }
    })();
  }, []);

  const backToSettings = () => {
    isSuperAdmin
      ? navigate('/active-merchants')
      : navigate('/active-merchantId');
    dispatch(superAdminSliceAction.openSettingsModal(merchantSelected));
  };
  return (
    <Grid>
      <Box className={style.clientIDHead}>
        <Box className={style.crossButton} onClick={backToSettings}>
          X
        </Box>
        <Box className={style.clientIDHeadingText}>
          {isSuperAdmin
            ? t('LABEL.ASSIGN_ADMIN_HEADING')
            : t('LABEL.INVITE_ADMIN')}
        </Box>
        <Divider />
        <Box justifyContent='center' display='flex' marginTop={2}>
          <Typography
            variant='h6'
            className={style.merchantId}
            sx={{ lineHeight: '40px !important', fontSize: '18px !important' }}
          >
            {t('PLACEHOLDER.MERCHANTID')} : {merchantSelected?.merchantClientId}
          </Typography>
        </Box>
      </Box>
      <Box className={style.invitation}>
        <form className={style.sendInvitationForm}>
          <Typography className={style.sendInvitationFormText}>
            {t('LABEL.SEND_EMAIL')}
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'row', gap: '9px' }}>
            <ReactMultiEmail
              style={{
                alignContent: 'center',
                borderRadius: '10px',
                padding: '0.8em 0.5em',
                opacity: dataEntered ? 1 : 0.5,
                pointerEvents: dataEntered ? 'auto' : 'none',
                maxWidth: '100%',
                height: '29px',
              }}
              emails={emails}
              onChange={
                dataEntered ? (_emails) => setEmails(_emails) : undefined
              }
              autoFocus={dataEntered}
              onFocus={dataEntered ? () => {} : undefined}
              onBlur={dataEntered ? () => {} : undefined}
              getLabel={(email, index, removeEmail) => (
                <Box data-tag key={index}>
                  <Box data-tag-item>{email}</Box>
                  <span
                    data-tag-handle
                    onClick={dataEntered ? () => removeEmail(index) : undefined}
                  >
                    ×
                  </span>
                </Box>
              )}
            />
            <ButtonComponent
              type='submit'
              variant='outlined'
              color='secondary'
              disabled={emails.length < 1}
              sx={{
                height: '35px',
                marginTop: '8px',
              }}
              onClick={handleEmailSendInvitation}
            >
              {t('BUTTON.SEND_INVITATION')}
            </ButtonComponent>
          </Box>
        </form>

        <form className={style.sendInvitationForm}>
          <Box className={style.sendInvitationFormText}>
            {t('LABEL.ENTER_WINKTAG')}
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', gap: '9px' }}>
            {dataEntered ? (
              <MultiInput
                selectedWinkTag={getSelectedWinkTag}
                disabled={!dataEntered}
                options={winkTagList}
                searchClient={searchClient}
              />
            ) : (
              <Box
                sx={{
                  maxWidth: '100%',
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '0.8em 0.5em',
                }}
              ></Box>
            )}
            <Box
              style={{
                height: '55px',
                display: 'flex',
                flexDirection: 'row',
                gap: '9px',
              }}
            >
              <Box className={style.label}>
                {t('LABEL.USER_ROLE')} <b> {t('LABEL.ADMIN')}</b>
              </Box>
              <ButtonComponent
                type='submit'
                variant='outlined'
                color='secondary'
                disabled={
                  winkTagCount === undefined || winkTagCount < 1 || !dataEntered
                }
                sx={{
                  height: '35px',
                  marginTop: '10px',
                  width: '190px',
                }}
                onClick={handleWinkTagSendInvitation}
              >
                {t('BUTTON.SEND_INVITATION')}
              </ButtonComponent>
            </Box>
          </Box>
        </form>
      </Box>
      <PopupComponent
        open={popupOpen}
        onClose={handleClosePopup}
        title={t('LABEL.POPUP_MEG')}
      />
    </Grid>
  );
};

export default AssignAdmintToClientIDView;
