import CommonConstants from 'src/constants/common.constant';
import { httpClient } from 'src/services/http.service';
import ActiveClientModel from './assign-admin-to-clientId.model';

class ActiveClientServices {
  static sendInvitation = (
    merchantId: string,
    email: any[],
    winkTag: any[],
  ) => {
    const client = httpClient();
    return client.post(
      CommonConstants.api.sendInvitation,
      {
        merchantId: merchantId,
        emails: email,
        winkTags: winkTag,
      },
      ActiveClientModel.sendInvitation,
    );
  };

  static getWinkTagList = (search: string) => {
    const client = httpClient();
    return client.post(
      CommonConstants.api.getAllActiveClientWinkTagList,
      {
        searchText: search,
        pageNumber: 0,
        pageSize: 0,
      },
      ActiveClientModel.getWinkTagList,
    );
  };
}
export default ActiveClientServices;
