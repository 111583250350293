import { createSlice } from '@reduxjs/toolkit';

const winkLoginSlice = createSlice({
  name: 'winkLogin',
  initialState: {
    userData: {},
    accessToken: '',
    fetchingUserIdentify: false,
  },
  reducers: {
    setAccessToken(state, { payload }) {
      state.accessToken = payload;
    },
    setUserData(state, { payload }) {
      state.userData = payload;
    },
    setFetchingUserIdentify(state, { payload }) {
      state.fetchingUserIdentify = payload;
    },
  },
});

const { actions } = winkLoginSlice;
export const winkLoginSliceActions = actions;
export default winkLoginSlice.reducer;
