import CommonConstants from 'src/constants/common.constant';
import { httpClient } from 'src/services/http.service';
import WinkLoginModel from './wink-login.model';

class WinkLoginService {
  static getUserProfile = (merchantId?: any) => {
    const client = httpClient();
    return client.post(
      CommonConstants.api.getUserProfile,
      merchantId ? { merchantId: merchantId } : null,
      WinkLoginModel.userProfile,
    );
  };

  static getMerchnatUserProfileData = () => {};
}

export default WinkLoginService;
