import store from '../redux-store/store';

export const getToken: any = () => {
  const accessToken: any = store.getState().winkLogin.accessToken;
  return accessToken;
};

export const getMerchantAccessToken: any = () => {
  const userData: any = store.getState().winkLogin.userData;
  const merchantToken = userData?.merchantAccessToken;
  return merchantToken;
};
