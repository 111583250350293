import yup from 'src/services/validator.service';
class HomeModel {
  static readonly ResponseSchema = yup
    .object({
      data: yup.object().nullable(),
      isSuccess: yup.boolean().defined(),
      message: yup.string().defined().nullable(),
    })
    .defined();
}
export default HomeModel;
