import React, { FC } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

import { CustomButtonProps } from 'src/types/button.model';
import { buttonBackgroundGradient } from 'src/assets/styles/variables';

const GradientButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'gradient',
})<{ gradient: boolean }>(({ theme, gradient }) => ({
  ...(gradient && {
    background: buttonBackgroundGradient,
    color: 'white',
    '&:hover': {
      background: buttonBackgroundGradient,
      filter: 'brightness(1.1)',
    },
    '&:active': {
      background: buttonBackgroundGradient,
      filter: 'brightness(0.9)',
    },
    '&:focus': {
      background: buttonBackgroundGradient,
      boxShadow: `0 0 0 3px ${theme.palette.primary.main}33`,
    },
    '&.Mui-disabled': {
      background: buttonBackgroundGradient,
      opacity: 0.5,
      color: 'white',
    },
  }),
}));

const ButtonComponent: FC<CustomButtonProps> = ({
  customStyle,
  gradient = false,
  sx,
  ...props
}) => {
  return (
    <GradientButton
      {...props}
      sx={{
        width: 'fit-content',
        ...(sx as any),
        ...(customStyle as any),
      }}
      gradient={gradient}
    >
      {props.children}
    </GradientButton>
  );
};

export default ButtonComponent;
