import React from 'react';
import MultiInputView from './multi-input.view';

type IProps = {
  options: any[];
  disabled: boolean;
  selectedWinkTag: (e: any) => void;
  searchClient: (e: any) => void;
};
const MultiInput: React.FC<IProps> = (props: any) => {
  return (
    <>
      <MultiInputView
        options={props.options}
        disabled={props.disabled}
        selectedWinkTag={props.selectedWinkTag}
        searchClient={props.searchClient}
      />
    </>
  );
};

export default MultiInput;
