import yup from 'src/services/validator.service';
class MerchantDashboard {
  static readonly processorList = yup
    .object({
      processors: yup.array().of(
        yup.object({
          id: yup.number(),
          name: yup.string().defined(),
        }),
      ),
    })
    .defined();
}
export default MerchantDashboard;
