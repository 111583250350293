import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { superAdminSliceAction } from '../super-admin-reducer';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const AdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDataRedux = useSelector((state: any) => state.winkLogin.userData);
  const params = useParams();
  const title = Object.values(params).join('/');

  useEffect(() => {
    const headingName = t(`LABEL.${title.toUpperCase().replace(/\s+/g, '_')}`);
    dispatch(superAdminSliceAction.setheadingName(headingName));
  }, [dispatch, t, title, userDataRedux]);

  return <Grid container>{title}</Grid>;
};

export default AdminDashboard;
