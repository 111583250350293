import CommonConstants from 'src/constants/common.constant';
import { httpClient } from 'src/services/http.service';
import UtilityService from 'src/services/utlits.service';
import MerchantProfileModel from './merchant-profile.model';
import { FormData } from './merchant-profile.component';

export const mapProductTypeToAccessType = (productType: string): number => {
  switch (productType) {
    case 'Login':
      return 1;
    case 'Payment':
      return 2;
    case 'Both':
      return 0;
    default:
      return 0;
  }
};

class MerchantProfileServices {
  static getMerchantProfile = () => {
    const client = httpClient();
    return client.get(
      CommonConstants.api.merchantProfile,
      null,
      MerchantProfileModel.getProfile,
    );
  };

  static putMerchantProfile = async (data: FormData) => {
    const formattedRedirectUris = data.redirectUris
      .map((uri: { url: string }) => UtilityService.formatDomain(uri.url))
      .filter((domain: string) => domain !== '');

    const mappedData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      companyName: data.companyName,
      streetAddress: data.streetAddress,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      applicationDomainURL: UtilityService.formatDomain(
        data.applicationDomainURL,
      ),
      webURL: UtilityService.formatDomain(data.webURL),
      redirectUris: formattedRedirectUris,
      applicationAccessType: mapProductTypeToAccessType(data.productType),
      paymentGateway: '',
      developmentEnvironment: '',
      comments: '',
      country: '',
    };

    const client = httpClient();
    try {
      const validData =
        await MerchantProfileModel.putProfile.validate(mappedData);
      const res = await client.put(CommonConstants.api.merchantProfile, validData, MerchantProfileModel.merchantProfileResponse);
      return res;
    } catch (error) {
      console.error('Error in putMerchantProfile:', error);
      throw error;
    }
  };
}
export default MerchantProfileServices;
