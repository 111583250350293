import yup from 'src/services/validator.service';
class ActiveClientModel {
  static readonly getAllActiveClient = yup.object({
    pageNumber: yup.number().defined(),
    totalRecords: yup.number().defined(),
    pageSize: yup.number().defined(),
    clientList: yup.array().defined(),
    message: yup.string().nullable().defined(),
  });
}
export default ActiveClientModel;
