import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function MultiInputView(props) {
  const { options } = props;
  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Autocomplete
        onChange={(event, value) => {
          props.selectedWinkTag(value);
        }}
        multiple
        id='tags-outlined'
        options={options}
        getOptionLabel={(option) => option}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={props.disabled}
            onInput={(event) => props.searchClient(event)}
          />
        )}
      />
    </Stack>
  );
}
