import React, { useCallback } from 'react';
import WinkLoginView from './wink-login.view';
import { configuration } from 'src/config';
import { useKeycloak } from '@react-keycloak/web';

const WinkLoginComponent: React.FC = () => {
  const merchantClientId = configuration.winkLoginClientId;

  const { keycloak } = useKeycloak();

  const handleLogin = useCallback(async () => {
    await keycloak.login();
  }, [keycloak]);

  return (
    <>
      {merchantClientId && !keycloak.authenticated && (
        <WinkLoginView onLogin={handleLogin} />
      )}
    </>
  );
};

export default WinkLoginComponent;
