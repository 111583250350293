import { httpClient } from 'src/services/http.service';
import HomeModel from '../home/home.model';
import CommonConstants from 'src/constants/common.constant';

const client = httpClient(true);

class AcceptRequestService {
  static acceptRequest = async (data: any) => {
    try {
      const response = await client.post(
        CommonConstants.api.getAcceptRequest,
        data,
        HomeModel.ResponseSchema,
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default AcceptRequestService;
