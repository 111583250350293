/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import i18n from 'i18next';
import UtilityService from 'src/services/utlits.service';
import { apm } from '@elastic/apm-rum';
import store from 'src/redux-store/store';
import { setRedirectPath } from './errorSlice';

let showError = true;

class ErrorHandlingService {
  static handleErrorStatusCode = async (errorResponse: any) => {
    if (!errorResponse.status) {
      const msg = { toastType: 'error', toastMsg: errorResponse.toString() };
      apm.captureError(msg.toastMsg);
      console.debug('error response', msg);
    } else {
      switch (errorResponse.status) {
        case 504:
          {
            const msg = {
              toastType: 'error',
              toastMsg: i18n.t('TOAST.504_ERROR'),
            };
            showError && UtilityService.showToast(msg);
            apm.captureError(msg.toastMsg);
          }
          break;
        case 400:
          {
            const msg = {
              toastType: 'error',
              toastMsg: errorResponse?.data?.Message,
            };
            UtilityService.showToast(msg);
            apm.captureError(msg.toastMsg);
          }
          break;
        case 401:
          {
            const msg = {
              toastType: 'error',
              toastMsg:
                errorResponse.data.Message ??
                i18n.t('MESSAGE.PAGE401_MESSAGE_1'),
            };
            showError && UtilityService.showToast(msg);
            showError = false;
            apm.captureError(msg.toastMsg);
            store.dispatch(setRedirectPath('/page401'));
          }
          break;
        case 403:
          {
            const msg = {
              toastType: 'error',
              toastMsg: i18n.t('MESSAGE.STATUS403_ERR'),
            };
            UtilityService.showToast(msg);
            apm.captureError(msg.toastMsg);
          }

          break;
        case 404:
          {
            const toastMessage = this.taostErrorMessage(
              errorResponse.data.Message,
            );
            const msg = { toastType: 'error', toastMsg: toastMessage };
            UtilityService.showToast(msg);
            apm.captureError(msg.toastMsg);
            // store.dispatch(setRedirectPath("/page404"));
          }

          break;
        case 500:
          {
            const toastMessage = this.taostErrorMessage(errorResponse.data);
            const msg = { toastType: 'error', toastMsg: toastMessage };
            UtilityService.showToast(msg);
            apm.captureError(msg.toastMsg);
          }
          break;
        default: {
          const msg = { toastType: 'error', toastMsg: errorResponse.message };
          UtilityService.showToast(msg);
          apm.captureError(msg.toastMsg);
        }
      }
    }
  };

  static taostErrorMessage = (responceData: any) => {
    let toastMessage = '';
    if (
      responceData &&
      responceData.Data &&
      Object.keys(responceData.Data).length > 0
    ) {
      Object.entries(responceData.Data).forEach(([key, value]: [any, any]) => {
        toastMessage += `${key} : ${value.join('. ')}. ` + '\n';
      });
    } else {
      toastMessage = responceData.Message;
    }
    return toastMessage;
  };
}

export default ErrorHandlingService;
