type Configuration = {
  environment: string;
  basePath: string;
  apiEndPoint: string;
  winkLoginAuthURL: string;
  winkLoginClientId: string;
  winkLoginRealm: string;
  showLoginLog: boolean;
  winkLoginUrl: string;
  merchantPortal: string;
};
const dataMock = {
  basePath: '',
  apiEndPoint: 'https://mockdata.com',
  environment: 'Env',
  winkLoginAuthURL: 'https://test.com/',
  winkLoginRealm: 'testRealm',
  winkLoginClientId: 'client',
  showLoginLog: true,
  winkLoginUrl: 'https://test.com/',
  merchantPortal: 'https://test.com/',
};
export const configuration =
  ((window as any).env as Configuration) ?? (dataMock as Configuration);
