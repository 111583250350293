import yup from 'src/services/validator.service';
class WinkLoginModel {
  static readonly userProfile = yup
    .object({
      userType: yup.number().defined(),
      winkTag: yup.string().defined(),
      companyName: yup.string().nullable().defined(),
      userId: yup.string().nullable().defined(),
      merchantClientId: yup.string().nullable().defined(),
      merchantId: yup.string().nullable().defined(),
      loginMode: yup.string().nullable().defined(),
      merchantAccessToken: yup.string().defined(),
      firstName: yup.string().nullable().defined(),
      lastName: yup.string().nullable().defined(),
      isLive: yup.boolean().defined(),
      message: yup.string().nullable().defined(),
    })
    .defined();
}
export default WinkLoginModel;
