import React, { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Drawer,
  Box,
  Typography,
  ListItemText,
  ListItemButton,
  ListItem,
  List,
  Divider,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';

import { AppDispatch, RootState } from 'src/redux-store/store';
import { drawerActions } from './drawer-reducer';

import UtilityService from 'src/services/utlits.service';
import WinkLogo from 'src/assets/images/wink-logo-white.png';
import style from 'src/components/common/header/header.module.scss';
import { white } from 'src/assets/styles/variables';

const SideDrawer: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const { isOpen, selectedRow } = useSelector(
    (state: RootState) => state.drawer,
  );
  const navigate = useNavigate();

  const handleCloseDrawer = () => {
    dispatch(drawerActions.closeDrawer());
  };

  const [expanded, setExpanded] = useState<string | null>(null);

  // Avoid re-render and change bg color on click events
  const headerBox = useMemo(
    () => (
      <Box
        sx={{
          mt: 4,
          py: 3,
          px: 1,
          color: white,
          backgroundImage:
            'linear-gradient(358deg, #303867 0%, #5762a3 32.5%, rgba(87, 98, 163, 0.75) 52.6%, #5762a3 67.5%, #303867 100%)',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            background: UtilityService.generateLinearGradient(),
            height: '40px',
            width: '40px',
            marginRight: '14px',
            borderRadius: '6px',
          }}
        >
          <StoreOutlinedIcon
            style={{
              marginTop: '4px',
              color: 'white',
              marginLeft: '4px',
              marginRight: '4px',
              width: 'auto',
              height: 'auto',
            }}
          />
        </div>
        <Typography fontSize='20px'>{selectedRow?.name}</Typography>
      </Box>
    ),
    [selectedRow?.name],
  );

  const handleExpandClick = (itemText: string) => {
    setExpanded(expanded === itemText ? null : itemText);
  };

  const navItems = [
    {
      text: t('LABEL.PRODUCT_SETTINGS'),
      action: () => {
        // navigate('/');
      },
      subSections: [
        {
          text: t('PLACEHOLDER.USER_SERVICE_TYPE_WL'),
        },
        {
          text: t('PLACEHOLDER.USER_SERVICE_TYPE_WP'),
        },
        {
          text: t('LABEL.CHECKOUT'),
        },
        {
          text: t('LABEL.WHITE_LABEL'),
        },
      ],
    },
    {
      text: t('LABEL.DASHBOARDS'),
      action: () => {
        navigate('/dashboards');
        handleCloseDrawer();
      },
      subSections: [],
    },
    {
      text: t('LABEL.DEVELOPERS'),
      action: () => {
        // navigate('/');
      },
      subSections: [],
    },
    {
      text: t('LABEL.SUPPORT'),
      action: () => {
        // navigate('/');
      },
      subSections: [],
    },
  ];

  return (
    <Drawer open={isOpen} onClose={handleCloseDrawer}>
      <Box
        className={style.container}
        alignItems='center'
        justifyContent='space-between'
        sx={{
          py: '22px !important',
          width: 'auto !important',
          justifyContent: 'center',
        }}
      >
        <Link to='/' className={style.clickeable}>
          <img
            data-testid='logoImg'
            className={style.winkLogo}
            src={WinkLogo}
            alt='Logo'
          />
        </Link>
      </Box>
      <Box
        sx={{
          px: 2,
        }}
      >
        <Box>
          {headerBox}
          <List
            sx={{
              mt: 3,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {navItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: '100%',
                }}
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      item?.action();
                      handleExpandClick(item.text);
                    }}
                    sx={{ width: '100%', gap: 2 }}
                  >
                    <ListItemText
                      sx={{
                        marginLeft: '15px',
                        fontSize: '13px',
                        fontWeight: 800,
                      }}
                      primary={item.text}
                    />
                    {item.subSections.length > 0 ? (
                      expanded === item.text ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    ) : null}
                  </ListItemButton>

                  {expanded === item.text && item.subSections.length > 0 && (
                    <List component='div' disablePadding>
                      {item.subSections.map((subSection, subIndex) => (
                        <ListItemButton
                          key={subIndex}
                          sx={{
                            pl: 4,
                          }}
                        >
                          <ListItemText
                            primary={subSection.text}
                            sx={{
                              fontSize: '12px',
                              color: 'grey.600',
                            }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  )}
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideDrawer;
