import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';

import UtilityService from 'src/services/utlits.service';

import eclipse from 'src/assets/images/Ellipse.png';
import style from 'src/components/common/statistic-bar/statistic-bar.module.scss';
import { grey } from '@mui/material/colors';

type iProps = {
  heading: string;
  statisticCount: {
    activeClientIdsCount: 0;
    activeAdminsCount: 0;
    newRequestsCount: 0;
  };
};

const StatisticBar: React.FC<iProps> = ({ statisticCount }) => {
  const { t } = useTranslation();

  const gradients = useMemo(() => {
    return {
      activeClientIdsCount: UtilityService.generateLinearGradient(),
      activeAdminsCount: UtilityService.generateLinearGradient(),
      newRequestsCount: UtilityService.generateLinearGradient(),
    };
  }, []);

  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={12} className={style.container}>
        <Box display='inline-block' className={style.statsBox}>
          <Typography className={style.statisticText}>
            {t('LABEL.STATS')}:
          </Typography>
        </Box>

        <Divider
          orientation='vertical'
          variant='middle'
          flexItem
          sx={{
            marginLeft: '1%',
            marginRight: '5%',
            my: 0,
            color: grey,
          }}
        />

        <Box
          display='inline-flex'
          alignItems='center'
          className={style.statisticBox}
        >
          <div
            style={{
              background: gradients.activeClientIdsCount,
              height: '25px',
              width: '25px',
              marginRight: '14px',
              borderRadius: '6px',
            }}
          >
            <StoreOutlinedIcon className={style.statIcon} />
          </div>
          <Typography className={style.statisticText}>
            {t('LABEL.ACTIVE_MERCHANTS')}
          </Typography>
          <Typography className={style.statisticNumber}>
            {statisticCount?.activeClientIdsCount}
          </Typography>

          <span>
            <img className={style.eclipse} src={eclipse} alt='indicator' />
          </span>
        </Box>

        <Box
          display='inline-flex'
          alignItems='center'
          className={style.statisticBox}
        >
          <div
            style={{
              background: gradients.activeAdminsCount,
              height: '25px',
              width: '25px',
              marginRight: '14px',
              borderRadius: '6px',
            }}
          >
            <PersonIcon className={style.statIcon} />
          </div>

          <Typography className={style.statisticText}>
            {t('LABEL.ACTIVE_ADMINS')}
          </Typography>
          <Typography className={style.statisticNumber}>
            {statisticCount?.activeAdminsCount}
          </Typography>
          <span>
            <img className={style.eclipse} src={eclipse} alt='indicator' />
          </span>
        </Box>

        <Box
          display='inline-flex'
          alignItems='center'
          className={style.statisticBox}
        >
          <Typography className={style.statisticText}>
            {t('LABEL.NEW_REQUESTS')}
          </Typography>
          <Typography className={style.statisticNumber}>
            +{statisticCount?.newRequestsCount}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StatisticBar;
