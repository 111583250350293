import React, { useState, useEffect } from 'react';
import AssignAdmintToClientIDView from './assign-admin-to-clientId.view';
import ActiveClientServices from './assign-admin-to-clientId.services';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CommonConstants from 'src/constants/common.constant';
import MerchantAdminService from 'src/containers/merchant-admin/merchant-admin.service';
import { RootState } from 'src/redux-store/store';

const AssignAdmintToClientID: React.FC = () => {
  const [dataEntered, setDataEntered] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [, setSelectedMerchant] = React.useState('');
  const [merchant] = React.useState([]);
  const [selectedWinkTag, setSelectedWinkTag] = React.useState([]);
  const [winkTagCount, setSelectedOptionCount] = useState<number | undefined>(
    undefined,
  );
  const [options, setOptions] = useState<any[]>([]);
  const [winkTagList, setWinkTagList] = useState<any[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDataState = useSelector((state: any) => state.winkLogin.userData);
  const isSuperAdmin =
    userDataState?.userType === CommonConstants.userType.superAdmin;

  const merchantSelected = useSelector(
    (state: RootState) => state.superAdmin.merchantSelected,
  );
  const handleWinkTagSendInvitation = async (event: React.FormEvent) => {
    event.preventDefault();
    const res = await ActiveClientServices.sendInvitation(
      merchantSelected?.merchantId,
      [],
      selectedWinkTag,
    );
    if (res.isSuccess === true && res.isEmailSent) {
      setPopupOpen(true);
    }
    setSelectedWinkTag([]);
  };

  const handleEmailSendInvitation = async (event: React.FormEvent) => {
    event.preventDefault();
    const res = await ActiveClientServices.sendInvitation(
      merchantSelected?.merchantId,
      emails,
      [],
    );
    if (res.isSuccess === true && res.isEmailSent) {
      setPopupOpen(true);
    }
    setEmails([]);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const getSelectedWinkTag = (value: any) => {
    setSelectedWinkTag(value);
    setSelectedOptionCount(value.length);
  };

  const handleChange = (event: any, value: string | null) => {
    if (value !== null) {
      setSelectedMerchant(merchant[options.indexOf(value)]);
    }
    setDataEntered(!!value);
  };

  const searchClient = async (event: any) => {
    if (isSuperAdmin) {
      const allWinkTagList = await ActiveClientServices.getWinkTagList(
        event.target.value,
      );
      setWinkTagList(allWinkTagList.data.map((val: any) => val.winkTag));
    } else {
      const params = {
        searchText: event.target.value,
        pageNumber: 0,
        pageSize: 0,
      };
      const allWinkTagList = await MerchantAdminService.merchantWinkTag(params);
      setWinkTagList(allWinkTagList.data.map((val: any) => val.winkTag));
    }
  };

  useEffect(() => {
    if (merchantSelected) {
      setSelectedMerchant(merchantSelected?.merchantClientId);
      setDataEntered(!!merchantSelected?.merchantClientId);
    }
  }, [merchantSelected]);

  return (
    <AssignAdmintToClientIDView
      dataEntered={dataEntered}
      popupOpen={popupOpen}
      emails={emails}
      setEmails={setEmails}
      options={options}
      winkTagList={winkTagList}
      winkTagCount={winkTagCount || 0} // Providing a default value
      handleWinkTagSendInvitation={handleWinkTagSendInvitation}
      handleEmailSendInvitation={handleEmailSendInvitation}
      handleClosePopup={handleClosePopup}
      getSelectedWinkTag={getSelectedWinkTag}
      handleChange={handleChange}
      searchClient={searchClient}
      t={t}
      dispatch={dispatch}
      setOptions={setOptions}
      setWinkTagList={setWinkTagList}
    />
  );
};

export default AssignAdmintToClientID;
