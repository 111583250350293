import { Dialog } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
// import { useSelector } from 'react-redux';
import spinner from 'src/assets/images/Wink-Loading.gif';

type IProps = {
  loading?: boolean;
};
const Loader: React.FC<IProps> = ({ loading = false }) => {
  const { initialized } = useKeycloak();
  return (
    <Dialog
      data-testid='loader-dialog'
      open={loading || false || !initialized}
      sx={{
        '& .MuiBackdrop-root': {
          opacity: '0.1 !important',
        },
      }}
      PaperProps={{
        style: {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: 'none',
          overflow: 'inherit',
        },
      }}
    >
      <img
        data-testid='loader-img'
        src={spinner}
        style={{ position: 'absolute', width: '100px' }}
        alt='loader'
      />
    </Dialog>
  );
};

export default Loader;
