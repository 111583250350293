import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './wink-login.module.scss';
import loginBg from 'src/assets/images/WinkLoginGradient.png';
import loginButtonBg from 'src/assets/images/red-bg-login.png';
import logo from 'src/assets/images/wink-logo-white.png';
import ButtonComponent from '../button/button.component';
import { primaryColor } from 'src/assets/styles/variables';

type iProps = {
  onLogin(): Promise<void>;
};

const WinkLoginView: React.FC<iProps> = ({ onLogin }) => {
  const { t } = useTranslation();
  const loginUser = () => {
    localStorage.removeItem('logoutUser');
    onLogin();
  };
  return (
    <Grid
      container
      sx={{
        background: `${primaryColor} url(${loginBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0px -209px',
        height: '100vh',
      }}
      spacing={2}
      display='flex'
      className={style.login}
    >
      <Grid container>
        <Grid item md={6} className={style.logo}>
          <img src={`${logo}`} alt='logo' />
        </Grid>
        <Grid item md={6} sx={{ padding: '80px 0 0 0' }}>
          <Grid
            item
            md={12}
            sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}
            className={style.loginBoxWrapper}
          >
            <Grid className={style.loginBox}>
              <img
                className={style.loginBg}
                src={`${loginButtonBg}`}
                alt='login bg'
              />
              <Grid className={style.loginContent}>
                <Box className={style.signupOrLoginWrapper}>
                  <Typography className={style.signupOrLogin}>
                    {' '}
                    {t('LABEL.LOGIN_OR_SIGNUP')}
                  </Typography>
                </Box>
                <Box className={style.loginDescWrapper}>
                  <Typography className={style.loginDesc}>
                    {' '}
                    {t('LABEL.LOGIN_DESC')}
                  </Typography>
                </Box>
                <Box className={style.loginBtn}>
                  <ButtonComponent
                    variant='contained'
                    color='primary'
                    size='large'
                    sx={{ background: primaryColor }}
                    onClick={loginUser}
                  >
                    {t('BUTTON.LOGIN_WITH_WINK')}
                  </ButtonComponent>
                </Box>
                <Box className={style.loginAccessInfoWrapper}>
                  <Typography className={style.loginAccessInfo}>
                    {' '}
                    {t('LABEL.LOGIN_ACCESS_INFO')}
                  </Typography>
                </Box>
              </Grid>
              <Grid className={style.loginFooter}>
                <Typography>{t('LABEL.POWERED_BY')}</Typography>
                <img src={logo} width='46px' alt='footer logo' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WinkLoginView;
