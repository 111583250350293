import CommonConstants from 'src/constants/common.constant';
import { httpClient } from 'src/services/http.service';
import ActiveClientModel from './active-client.model';

class ActiveClientServices {
  static getAllActiveClient = (
    searchText: string = '',
    pageNumber: number = 1,
    pageSize: number = 10,
  ) => {
    const client = httpClient();
    return client.post(
      CommonConstants.api.getAllClientList,
      {
        searchText,
        pageNumber,
        pageSize,
      },
      ActiveClientModel.getAllActiveClient,
    );
  };
}
export default ActiveClientServices;
