import { configuration } from 'src/config';
class CommonConstants {
  static readonly api = {
    getStatisticCount: 'merchant/statistical/count',
    acceptRequest: 'merchant/user/acceptRequest',
    getUserProfile: `${configuration.basePath}/merchant/user/identify`,
    merchantProfile: `${configuration.basePath}/merchant/merchant-profile`,
    getActiveClientList: `${configuration.basePath}/merchant/getClient`,
    getAllClientList: `${configuration.basePath}/merchant/client/search`,
    getAllActiveClientWinkTagList: `${configuration.basePath}/merchant/user/search`,
    getAcceptRequest: '/merchant/user/acceptRequest',
    sendInvitation: `${configuration.basePath}/merchant/user/merchant-admin`,
    createMerchantClient: 'merchant/merchant',
    requestMerchantClient: 'merchant/merchant-request',
    getMerchantClientById: 'merchant/merchant-request/{merchantId}',
    getMerchantRequest: 'merchant/merchant-request/search',
    getMerchantWinkTag: 'merchant/user/search-winktag',
    getMerchantList: 'merchant/user/merchants',
    actionOnMerchantRequest: 'merchant/merchant-request/status/{merchantId}',
    joinToMerchant: 'merchant/merchant-admin-request',
    getMerchantAdmins: `merchant/merchant-admin/search`,
    getMerchantAdminInvitations: 'merchant/merchant-admin/invitation/search',
    updateMerchantAdminStatus: `merchant/merchant-admin/status`,
    deleteMerchantAdminInvitation: `merchant/merchant-admin-invitation`,
    listOfPaymentProcessor: `merchant/master-lookup/thirdPartyPaymentProcessor`,
    listOfFraudMProcessor: `merchant/master-lookup/thirdPartyFraudProcessor`,
    saveThirdPartyProcessor: `merchant/merchant-thirdparty-integration`,
    getThirdPartyCredential: `merchant/merchant-thirdparty-credential`,
    deleteMerchantAdmin: `merchant/merchant-admin`,
    thirdPartyCredential: `merchant/merchant-thirdparty-credentials`,
  };

  static readonly environment = {
    production: 'prod',
    development: 'dev',
    stage: 'stage',
  };

  static readonly validation = {
    links: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
    name: /^[a-zA-Z]+$/,
    alphaNumeric: /^[a-zA-Z0-9_ ]*$/,
    phone: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    zipCode: /^\d{5}(?:-\d{4})?$/
  };

  static readonly serviceType = {
    both: 0,
    login: 1,
    payment: 2,
  };

  static readonly userType = {
    user: 0,
    superAdmin: 1,
    merchantAdmin: 2,
  };

  static readonly requestType = {
    newMerchant: 1,
    newAdmin: 2,
  };

  static readonly status = {
    active: 2,
    pending: 0,
  };

  static readonly idleTimeCountdown = {
    idleTime: 300,
    countDown: 30,
    oneSecond: 1000,
    sessionIdleTime: 780,
  };
}

export default CommonConstants;
