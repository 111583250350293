import React, { useEffect, FC, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ButtonComponent from '../../../components/button/button.component';
import TableComponent from 'src/components/table/table.component';
import PopupComponent from 'src/components/popup/popup-component.component';
import Loader from 'src/components/common/loader/loader.component';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';

import { TableColumnData, TableRowData } from 'src/types/table.model';

import SuperAdminService from '../super-admin.service';

import merchantAvatarImage from 'src/assets/images/merchant-icon.svg';
import adminAvatarImage from 'src/assets/images/admin-icon.svg';

import CommonConstants from 'src/constants/common.constant';
import { lightGreen } from 'src/assets/styles/variables';
import style from './approve-request.module.scss';

interface ClientAdminRow extends TableRowData {
  id: string;
  name: string;
  company: string;
  mobile: string;
  disabled: boolean;
  requestType: number;
}

const ApproveRequestView: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [merchantRequest, setMerchantRequest] = useState<ClientAdminRow[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<ClientAdminRow>();
  const [open, setOpen] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);
    return () => clearTimeout(handler);
  }, [searchText]);

  const getMerchantRequestData = async (
    currentPage: number,
    pageSize: number
  ) => {
    setIsLoading(true);
    const param = {
      searchText: debouncedSearchText,
      pageSize,
      pageNumber: currentPage,
      merchantUserRequestStatus: 0,
    };
    try {
      const data = await SuperAdminService.getMerchantRequest(param);
      setMerchantRequest(data?.merchantInitiatedRequests || []);
      setTotalRecords(data?.totalRecords || 0);
    } finally {
      setIsLoading(false);
    }
  };

  const getMerchantRequestById = async (id: string) => {
    setGeneralLoading(true);
    try {
      const data = await SuperAdminService.getMerchantRequestById(id);
      setDialogData(data);
      setOpenDialog(true);
    } finally {
      setGeneralLoading(false);
    }
  };

  useEffect(() => {
    getMerchantRequestData(page, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, debouncedSearchText]);

  const getStatusMessage = (status: number) => {
    switch (status) {
      case 2:
        return t('MESSAGE.MERCHANT_REQUEST_ACCEPT');
      case 3:
        return t('MESSAGE.MERCHANT_REQUEST_DENY');
      default:
        return '';
    }
  };

  const setErrorState = () => {
    setIsError(true);
    setMessage(t('ERROR_MESSAGE.MERCHANT_REQUEST_ERROR'));
    setOpen(true);
  };

  const actionOnMerchantRequest = async (status: any, merchantId: any) => {
    setGeneralLoading(true);
    setSearchText('');
    try {
      const response = await SuperAdminService.actionOnMerchantRequest(
        merchantId,
        status
      );
      const isSuccess = response?.isSuccess;

      setIsError(!isSuccess);
      setMessage(
        isSuccess
          ? getStatusMessage(status)
          : t('ERROR_MESSAGE.MERCHANT_REQUEST_ERROR')
      );
      setOpen(true);

      if (isSuccess) {
        getMerchantRequestData(page, rowsPerPage);
      }
    } catch {
      setErrorState();
    } finally {
      setGeneralLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPage(1);
  };

  const clientRows: ClientAdminRow[] = useMemo(
    () =>
      merchantRequest.map((val) => ({
        id: val.id ?? '',
        merchantRequestedClientId: val.merchantClientId ?? '',
        name: `${val.firstName} ${val.lastName}`,
        company: val.companyName ?? '',
        requestType: val.requestType ?? 0,
        mobile: val.phoneNumber ?? '',
        email: val.email ?? '',
      })),
    [merchantRequest]
  ) as [];

  const mockedColumns: TableColumnData<ClientAdminRow>[] = useMemo(
    () => [
      {
        id: 'merchantRequestedClientId',
        label: t('TABLE.COLUMN_MERCHANTID'),
        withSquareColor: true,
        sort: true,
      },
      { id: 'name', label: t('TABLE.COLUMN_NAME'), sort: true },
      { id: 'company', label: t('TABLE.COLUMN_COMPANY'), sort: true },
      {
        id: 'requestType',
        label: t('TABLE.COLUMN_TYPE'),
        sort: true,
        align: 'center',
        render: (row) => (
          <Box
            sx={{
              background:
                row.requestType === CommonConstants.requestType.newAdmin
                  ? 'linear-gradient(90deg, #9AD7D2 0%, #75B1AC 100%)'
                  : 'linear-gradient(90deg, #8BB5CC 0%, #255C7F 100%)',
              height: '25px',
              width: '100px',
              textAlign: 'center',
              alignContent: 'center',
              justifySelf: 'center',
              borderRadius: '5px',
              color: 'white',
            }}
          >
            <Typography fontSize={12}>
              {row.requestType === CommonConstants.requestType.newMerchant &&
                t('LABEL.NEW_MERCHANT')}
              {row.requestType === CommonConstants.requestType.newAdmin &&
                t('LABEL.NEW_ADMIN')}
            </Typography>
          </Box>
        ),
      },
      {
        id: 'actions',
        label: '',
        align: 'center',
        minWidth: 320,
        render: (row) => (
          <Box display='flex' gap='15px' justifyContent='center'>
            <ButtonComponent
              gradient
              size='small'
              sx={{ minWidth: '100px' }}
              onClick={() => actionOnMerchantRequest(2, row.id)}
            >
              {t('BUTTON.ACCEPT')}
            </ButtonComponent>
            <ButtonComponent
              variant='outlined'
              color='secondary'
              size='small'
              sx={{ minWidth: '100px' }}
              onClick={() => actionOnMerchantRequest(3, row.id)}
            >
              {t('BUTTON.DENY')}
            </ButtonComponent>
            <ButtonComponent
              sx={{ minWidth: '100px' }}
              onClick={() => {
                getMerchantRequestById(row.id);
              }}
            >
              <VisibilityOutlinedIcon sx={{ color: lightGreen }} />
            </ButtonComponent>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const handleClosePopup = () => {
    setOpen(false);
    navigate('/manage-requests');
  };

  const renderMerchantDialog = () => (
    <DialogContent sx={{ backgroundColor: '#D9D9D9', p: 3 }}>
      <Box textAlign='center' sx={{ pt: 2, pb: 4, px: 2 }}>
        <img
          src={merchantAvatarImage}
          alt='Merchant Icon'
          style={{ width: 60, height: 60, marginBottom: 4 }}
        />
        <Typography variant='h6' component='div' gutterBottom fontWeight={600}>
          {dialogData?.companyName}
        </Typography>
        <Typography variant='body1' fontWeight={400}>
          ({dialogData?.merchantClientId})
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 2 }}>
        <Typography
          gutterBottom
          variant='subtitle1'
          component='div'
          fontWeight={600}
        >
          {t('LABEL.DEVELOPER_ACCESS')}
        </Typography>
        <Grid container spacing={1} pl={4}>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.NAME')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.firstName} {dialogData?.lastName}
          </Grid>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.EMAIL')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.email}
          </Grid>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.PHONE_NUMBER')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.phoneNumber}
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 2 }}>
        <Typography
          gutterBottom
          variant='subtitle1'
          component='div'
          fontWeight={600}
        >
          {t('LABEL.DOMAIN_INFORMATION')}
        </Typography>
        <Grid container spacing={1} pl={4}>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.APPLICATION_DOMAIN')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.webURL ? dialogData.webURL : 'N/A'}
          </Grid>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.REDIRECT_DOMAIN')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData &&
            Array.isArray(dialogData.redirectUris) &&
            dialogData.redirectUris.length > 0
              ? dialogData.redirectUris[0]
              : 'N/A'}
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 2 }}>
        <Typography
          gutterBottom
          variant='subtitle1'
          component='div'
          fontWeight={600}
        >
          {t('LABEL.PRODUCTS')}:
        </Typography>
        <Grid container spacing={1} pl={4} pt={2}>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.PRODUCT_REQUEST')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.applicationAccessType === 0
              ? t('PLACEHOLDER.USER_SERVICE_TYPE_B')
              : dialogData?.applicationAccessType === 1
                ? t('PLACEHOLDER.USER_SERVICE_TYPE_WL')
                : dialogData?.applicationAccessType === 2
                  ? t('PLACEHOLDER.USER_SERVICE_TYPE_WP')
                  : 'N/A'}
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
  );

  const renderAdminDialog = () => (
    <DialogContent sx={{ backgroundColor: '#D9D9D9', p: 3 }}>
      <Box textAlign='center' sx={{ pt: 2, pb: 4, px: 2 }}>
        <img
          src={adminAvatarImage}
          alt='User Icon'
          style={{ width: 60, height: 60, marginBottom: 4 }}
        />
        <Typography variant='h6' component='div' gutterBottom fontWeight={600}>
          {dialogData?.firstName} {dialogData?.lastName}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 2 }}>
        <Typography
          gutterBottom
          variant='subtitle1'
          component='div'
          fontWeight={600}
        >
          {t('LABEL.USER_INFORMATION')}
        </Typography>
        <Grid container spacing={1} pl={4}>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.EMAIL')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.email}
          </Grid>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.PHONE_NUMBER')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.phoneNumber}
          </Grid>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.WINKTAG')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.winkTag}
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 2 }}>
        <Typography
          gutterBottom
          variant='subtitle1'
          component='div'
          fontWeight={600}
        >
          {t('LABEL.MERCHANT_TO_JOIN')}
        </Typography>
        <Grid container spacing={1} pl={4}>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('PLACEHOLDER.MERCHANTID')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.merchantClientId ? dialogData.merchantClientId : 'N/A'}
          </Grid>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.COMPANY_NAME')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.companyName ? dialogData.companyName : 'N/A'}
          </Grid>
          <Grid item xs={5} sx={{ fontWeight: 500 }}>
            {t('LABEL.APPLICATION_DOMAIN')}:
          </Grid>
          <Grid item xs={7}>
            {dialogData?.webURL ? dialogData.webURL : 'N/A'}
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
  );

  return (
    <>
      <Box className={style.header}>
        <Grid container alignItems='center'>
          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={4} className={style.headerContent}>
            <Typography variant='h2' className={style.headerTitle}>
              {t('LABEL.MANAGE_REQUESTS')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={style.searchContainer}>
            <TextField
              label={t('PLACEHOLDER.TABLE_SEARCH')}
              value={searchText}
              onChange={handleSearchChange}
              variant='outlined'
              size='small'
              sx={{ width: '100%', maxWidth: '300px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {searchText ? (
                      <CloseIcon onClick={() => setSearchText('')} />
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ margin: '10px 30px 10px 30px' }} />
      </Box>
      <TableComponent
        columns={mockedColumns}
        rows={clientRows}
        page={page}
        rowsPerPage={rowsPerPage}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        withCheckBox={false}
        loading={isLoading}
      />
      <Dialog
        onClose={() => setOpenDialog(false)}
        aria-labelledby='customized-dialog-title'
        open={openDialog}
        maxWidth='sm'
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: 600,
            borderRadius: '8px',
          },
        }}
      >
        <IconButton
          aria-label='close'
          onClick={() => setOpenDialog(false)}
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>

        {dialogData?.requestType === 1
          ? renderMerchantDialog()
          : renderAdminDialog()}
      </Dialog>
      <PopupComponent
        open={open}
        onClose={handleClosePopup}
        title={message}
        error={isError}
      />
      <Loader loading={generalLoading} />
    </>
  );
};

export default ApproveRequestView;
