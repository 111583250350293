import React from 'react';
import Table, { DataTableProps } from './table.view';
import { TableRowData } from 'src/types/table.model';

interface TableComponentProps<T extends TableRowData>
  extends DataTableProps<T> {
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
  totalRecords: number;
  loading?: boolean;
}

const TableComponent = <T extends TableRowData>({
  columns,
  rows,
  page,
  rowsPerPage,
  withCheckBox,
  onRowClick,
  onRowSelect,
  selectedRows,
  onPageChange,
  totalRecords,
  loading,
}: TableComponentProps<T>) => {
  return (
    <Table
      columns={columns}
      rows={rows}
      page={page}
      rowsPerPage={rowsPerPage}
      withCheckBox={withCheckBox}
      onRowClick={onRowClick}
      onRowSelect={onRowSelect}
      selectedRows={selectedRows}
      onPageChange={onPageChange}
      totalRecords={totalRecords}
      loading={loading}
    />
  );
};

export default TableComponent;
