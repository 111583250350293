// Font sizes
export const ForntFamily = 'Rubik, sans-serif';
export const xlFontSize = '24px';
export const bgFontSize = '20px';
export const mdFontSize = '16px';
export const smFontSize = '15px';
export const xsFontSize = '14px';
export const xxsFontSize = '11px';

export const fontFamily = 'Rubik';
export const allFontsFamilies = 'Rubik, Arial, sans-serif';

export const primaryColor = '#3f4890';
export const secondaryColor = '#ff0054';
export const disabledColor = '#00000050';
export const tableBorderColor = 'black';
export const boxShadow = '#D7E0F2';
export const borderColor = '#C4C4C4';
export const headerColor = '#11168F';
export const clientColor = '#A5A6C8';
export const clientBox = '#8889B0';
export const eclipse = '#E83189';
export const fieldColor = '#f7f8fa';

export const white = '#fff';
export const blue = '#3f4392';
export const lightGreen = '#75B1ACF2';
export const green = '#34C4BB';
export const grey = '#676C76';
export const red = '#FF1744';

export const envSelectProd = '#00C49F';
export const envSelectSand = '#FFBB28';
export const envSelectColor = 'rgba(255, 255, 255, 0.60)';

export const required = '#db2828';

export const fontColor1 = grey;
export const fontColor2 = secondaryColor;
export const fontColor3 = '#001c71';
export const loginAccessInfo = '#d1d3ea';
export const disabledButtonColor = '#EOE0E0';
export const disabledButtonBgColor = '#676c7669';

export const primaryButtonBorderRadius = '57px';
export const secondaryButtonBorderRadius = '12px';

export const appBackgroud = '#F4F5FA';
export const textFieldBackground = '#D7E0F2';
export const buttonBackgroundGradient =
  'linear-gradient(90deg, #E4318B 0%, #9042B8 100%);';
export const cardBackgroundGradient =
  'linear-gradient(90deg, rgba(224, 50, 141, 0.85) 1.05%, rgba(175, 94, 167, 0.77) 19.96%, rgba(119, 85, 193, 0.65) 35.75%, rgba(51, 74, 224, 0.56) 49.26%, rgba(120, 83, 192, 0.67) 70.98%, rgba(181, 90, 164, 0.77) 86.62%, rgba(232, 49, 137, 0.85) 98.27%);';
