import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './page404.module.scss';
import ButtonComponent from 'src/components/button/button.component';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <main className={style['errorPage']}>
        <div className={style['errorPage__message']}>
          <h1 className={style['errorPage__title']}>
            {t('MESSAGE.404_ERROR_TITLE')}
          </h1>
          <div className={style['errorPage__error']}>
            <h2 className={style['errorPage__error-text']}>
              {t('MESSAGE.PAGE404_MESSAGE_1')}
            </h2>
          </div>
        </div>
        <div className={style['errorPage__button-wrapper']}>
          <ButtonComponent
            color='primary'
            variant='contained'
            onClick={() => navigate('/')}
          >
            {t('BUTTON.RETURN_HOME')}
          </ButtonComponent>
        </div>
      </main>
    </div>
  );
};

export default Page404;
