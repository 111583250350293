import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import winkLoginReducer from 'src/components/wink-login/wink-login.reducer';
import { configuration } from 'src/config';
import CommonConstants from 'src/constants/common.constant';
import superAdminReducer from 'src/containers/super-admin/super-admin-reducer';
import errorReducer from 'src/components/common/error-handler/errorSlice';
import drawerReducer from 'src/components/sidedrawer/drawer-reducer';

const rootReducer = combineReducers({
  winkLogin: winkLoginReducer,
  superAdmin: superAdminReducer,
  error: errorReducer,
  drawer: drawerReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: persistedReducer,
  devTools:
    configuration.environment !== CommonConstants.environment.production,
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
