import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { drawerActions } from 'src/components/sidedrawer/drawer-reducer';
import { RootState } from 'src/redux-store/store';

import DashboardView from './dashboard.view';
import DashboardServices from './dashboard.services';

export type dashboard = {
  id: string;
  title: string;
  logoFileUrl: string;
  url: string;
  username: string;
  password: string;
};

const DashboardComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(0);
  const { selectedRow } = useSelector((state: RootState) => state.drawer);
  const [dashboards, setDashboards] = useState<dashboard[]>([]);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [openPopupError, setOpenPopupError] = useState(false);
  const [popUpTitleError, setPopUpTitleError] = useState<string>('');

  const fetchData = useCallback(async () => {
    try {
      setGeneralLoading(true);
      const res = await DashboardServices.getThirdPartyCredential(tabValue, 0);
      if (res) {
        setDashboards(res?.merchantThirdPartyDetails);
      } else {
        setOpenPopupError(true);
        setPopUpTitleError(t('ERROR_MESSAGE.ERROR_FETCH_DASHBOARDS'));
      }
    } catch (error) {
      console.error('Error in request:', error);
      setOpenPopupError(true);
      setPopUpTitleError(t('ERROR_MESSAGE.ERROR_FETCH_DASHBOARDS'));
    } finally {
      setGeneralLoading(false);
    }
  }, [t, tabValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData, tabValue]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    dispatch(drawerActions.openDrawer(selectedRow));
    navigate('/');
  };

  return (
    <DashboardView
      tabValue={tabValue}
      onTabChange={handleTabChange}
      onClose={handleClose}
      dashboards={dashboards}
      fetchData={fetchData}
      generalLoading={generalLoading}
      setGeneralLoading={setGeneralLoading}
      setDashboards={setDashboards}
      setOpenPopupError={setOpenPopupError}
      openPopupError={openPopupError}
      popUpTitleError={popUpTitleError}
      setPopUpTitleError={setPopUpTitleError}
    />
  );
};

export default DashboardComponent;
