import React from 'react';
import {
  Box,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Button,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import avatarImage from 'src/assets/images/merchant-icon.svg';
import styles from './settings-modal.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from '../common/loader/loader.component';

export interface SettingsOption {
  text: string;
  actionId: string;
  withIcon?: boolean;
}

interface SettingsModalProps {
  isOpen: boolean;
  containerId: () => HTMLElement | null;
  options: SettingsOption[];
  handleClose: () => void;
  handleAction: (actionId: string) => void;
  title: string;
  subtitle?: string;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  isOpen,
  containerId,
  options,
  handleClose,
  handleAction,
  title,
  subtitle,
}) => {
  const { t } = useTranslation();
  const fetchingUserIdentify = useSelector(
    (state: any) => state.winkLogin.fetchingUserIdentify,
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='settings-modal-title'
      aria-describedby='settings-modal-description'
      id='settings_modal'
      container={containerId}
      sx={{
        position: 'absolute',
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgb(0 0 0 / 18%)',
          },
        },
      }}
    >
      <Box className={styles.modalBox}>
        <Box
          sx={{
            maxWidth: '80%',
          }}
        >
          <Box className={styles.avatarBox}>
            <img src={avatarImage} alt='Avatar' className={styles.avatar} />
          </Box>

          <Typography variant='h6' component='h2' className={styles.modalTitle}>
            {title}
            {subtitle ? (
              <span className={styles.modalSubTitle}>{` ${subtitle}`}</span>
            ) : (
              ''
            )}
          </Typography>
          {fetchingUserIdentify ? (
            <Loader loading />
          ) : (
            <List className={styles.list}>
              {options.map((option, index) => (
                <ListItem
                  key={index}
                  onClick={() => handleAction(option.actionId)}
                  className={styles.listItem}
                >
                  <ListItemButton className={styles.listItemButton}>
                    <ListItemText
                      className={styles.listItemText}
                      primary={option.text}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        fontSize: '15px',
                      }}
                    />
                    {option.withIcon && (
                      <ListItemIcon className={styles.listItemIcon}>
                        <SettingsIcon className={styles.icon} />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </Box>

        <Button
          onClick={handleClose}
          variant='contained'
          className={styles.closeButton}
        >
          {t('LABEL.CLOSE')}
        </Button>
      </Box>
    </Modal>
  );
};

export default SettingsModal;
