import { httpClient } from 'src/services/http.service';
import SuperAdminModel from './super-admin.model';
import CommonConstants from 'src/constants/common.constant';

const client = httpClient(true);
class SuperAdminService {
  static statisticCount = async () => {
    return client.get(
      CommonConstants.api.getStatisticCount,
      null,
      SuperAdminModel.statisticCount
    );
  };

  static getMerchantRequest = async (data: any) => {
    return client.post(
      CommonConstants.api.getMerchantRequest,
      data,
      SuperAdminModel.merchantRequest
    );
  };

  static getMerchantRequestById = async (merchantId: string) => {
    return client.get(
      `${CommonConstants.api.getMerchantClientById.replace('{merchantId}', merchantId)}`,
      null,
      SuperAdminModel.merchantClientById
    );
  };

  static actionOnMerchantRequest = async (merchantId: any, data: any) => {
    return client.put(
      `${CommonConstants.api.actionOnMerchantRequest.replace('{merchantId}', merchantId)}`,
      { status: data },
      SuperAdminModel.actionOnMerchantRequest
    );
  };
}

export default SuperAdminService;
