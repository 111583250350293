import React, { Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Box, Dialog } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import Loader from 'src/components/common/loader/loader.component';
import style from './app.module.scss';
import spinner from 'src/assets/images/Wink-Loading.gif';
import AppRoutes from 'src/AppRoutes';
import theme from '../../theme';
import Layout from 'src/components/layout/layout.component';
import { useDispatch, useSelector } from 'react-redux';
import { winkLoginSliceActions } from 'src/components/wink-login/wink-login.reducer';
import WinkLoginService from 'src/components/wink-login/wink-login.service';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router-dom';
import { superAdminSliceAction } from '../super-admin/super-admin-reducer';
import SuperAdminService from '../super-admin/super-admin.service';
import CommonConstants from 'src/constants/common.constant';
import IdleTimeOutHandler from 'src/components/common/idlel-time-out-handler/Idle-time-out-handler.component';

const LoadingComponent = () => {
  return (
    <Grid
      display='flex'
      sx={{ paddingTop: '2rem' }}
      justifyContent='center'
      alignItems='center'
    >
      <Box className='loadingWrap'>
        <Dialog
          open={true}
          sx={{
            '& .MuiBackdrop-root': {
              opacity: '0.1 !important',
            },
          }}
          PaperProps={{
            style: {
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'none',
              overflow: 'inherit',
            },
          }}
        >
          <img
            src={spinner}
            alt='spinner'
            style={{ position: 'absolute', width: '200px' }}
          />
        </Dialog>
      </Box>
    </Grid>
  );
};

function App() {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const userDataState = useSelector((state: any) => state.winkLogin.userData);

  const location = useLocation();
  const isErrorPage =
    location.pathname === '/page401' || location.pathname === '/page404'; // add here other error pages
  const acceptRequestPage = location.pathname === '/accept-request';
  const userLogout = localStorage.getItem('logoutUser') ?? false;

  useEffect(() => {
    if (keycloak.authenticated && keycloak.token !== undefined) {
      dispatch(winkLoginSliceActions.setAccessToken(keycloak.token));
    }
  }, [dispatch, keycloak.authenticated, keycloak.token]);

  // Calling user data API
  useEffect(() => {
    (async () => {
      if (
        keycloak.authenticated &&
        Object.keys(userDataState).length === 0 &&
        !acceptRequestPage &&
        userLogout !== 'true'
      ) {
        const resp = await WinkLoginService.getUserProfile();
        if (resp) {
          dispatch(winkLoginSliceActions.setUserData(resp));
        }
      }
    })();
  }, [
    keycloak.authenticated,
    userDataState,
    acceptRequestPage,
    dispatch,
    userLogout,
  ]);

  useEffect(() => {
    (async () => {
      if (
        userDataState?.merchantAccessToken !== undefined &&
        userDataState?.merchantAccessToken !== '' &&
        userDataState.userType === CommonConstants.userType.superAdmin
      ) {
        const res = await SuperAdminService.statisticCount();
        if (res) {
          dispatch(superAdminSliceAction.setSuperAdminStatisticCount(res));
        }
      }
    })();
  }, [dispatch, userDataState]);

  return (
    <I18nextProvider i18n={i18n}>
      <IdleTimeOutHandler />
      <Grid
        className={style.appContainer}
        sx={{ padding: 0 }}
        data-testid='routes'
      >
        <ThemeProvider theme={theme}>
          <ToastContainer hideProgressBar />
          <Loader></Loader>
          <Suspense fallback={<LoadingComponent />}>
            {keycloak.authenticated && !isErrorPage && !acceptRequestPage && (
              <Layout />
            )}
            <Box
              className={
                keycloak.authenticated && !isErrorPage && !acceptRequestPage
                  ? style.superAdminUser
                  : ''
              }
            >
              <AppRoutes />
            </Box>
          </Suspense>
        </ThemeProvider>
      </Grid>
    </I18nextProvider>
  );
}

export default App;
