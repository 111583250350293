import yup from 'src/services/validator.service';
class SuperAdminModel {
  static readonly statisticCount = yup
    .object({
      activeClientIdsCount: yup.number().defined(),
      activeAdminsCount: yup.number().defined(),
      newRequestsCount: yup.string().defined(),
      message: yup.string().nullable().defined(),
    })
    .defined();
  static readonly merchantRequest = yup
    .object({
      pageNumber: yup.number().defined(),
      totalRecords: yup.number().defined(),
      pageSize: yup.number().defined(),
      merchantInitiatedRequests: yup.array().of(
        yup.object({
          merchantClientId: yup.string().defined(),
          firstName: yup.string().defined(),
          lastName: yup.string().defined(),
          companyName: yup.string().defined(),
          email: yup.string(),
          phoneNumber: yup.string(),
          status: yup.number().defined(),
          requestType: yup.number().defined(),
        }),
      ),
    })
    .defined();
  static readonly merchantClientById = yup
    .object({
      merchantClientId: yup.string().defined(),
      firstName: yup.string().defined(),
      lastName: yup.string().defined(),
      companyName: yup.string().defined(),
      email: yup.string().defined(),
      phoneNumber: yup.string().defined(),
      webURL: yup.string().nullable().defined(),
      winkTag: yup.string().nullable().defined(),
      redirectUris: yup
        .array()
        .of(yup.string().url().defined())
        .transform((value, originalValue) => {
          if (
            typeof originalValue === 'string' &&
            originalValue.startsWith('System.Collections.Generic.List')
          ) {
            return [];
          }
          return value;
        })
        .nullable()
        .defined(),
      applicationAccessType: yup.number().defined(),
      status: yup.number().defined(),
      requestType: yup.number().defined(),
      message: yup.string().nullable().defined(),
    })
    .defined();
  static readonly actionOnMerchantRequest = yup
    .object({
      isSuccess: yup.boolean().defined(),
      message: yup.string().nullable().defined(),
    })
    .defined();
}
export default SuperAdminModel;
