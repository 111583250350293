import React, { useEffect, useState } from 'react';
import AcceptRequestView from './accept-request.view';
import AcceptRequestService from './accept-request.services';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { configuration } from 'src/config';

const AcceptRequest: React.FC = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state: any) => state.winkLogin.accessToken);
  const queryParam = new URLSearchParams(window.location.search);
  const requestToken = queryParam.get('requesttoken') ?? '';
  const ru = queryParam.get('ru') ?? '';
  const [data, setData] = useState({});
  const rToken = requestToken !== '' ? requestToken : ru !== '' ? ru : '';

  const { keycloak } = useKeycloak();
  const redirect_uri = encodeURIComponent(
    `${configuration.merchantPortal}accept-request?requesttoken=${rToken}`,
  );
  const loginUrl = `${configuration.winkLoginUrl}?client_id=${configuration.winkLoginClientId}&redirect_uri=${redirect_uri}`;
  
  // case to handle return from login
  useEffect(() => {
    if (
      keycloak.token !== undefined &&
      Object.keys(data).length === 0 && requestToken !== ''
    ) {
      (async () => {
        try {
          const res = await AcceptRequestService.acceptRequest({
            requestToken: requestToken,
          });
          if (res?.isSuccess) {
            setData(res);
            navigate('/');
          } else {
            navigate('/page401');
          }
        } catch (error) {
          console.error('Error in request:', error);
          navigate('/page404');
        }
      })();
    }
  }, [data, keycloak.token, navigate, requestToken]);

  const handleAccept = async () => {
    if(keycloak.authenticated){
      // handles accept request flow
      try {
        const res = await AcceptRequestService.acceptRequest({
          requestToken: ru,
        });
        if (res?.isSuccess) {
          setData(res);
          navigate('/');
        }else{
          navigate('/page401');
        }
      } catch (error) {
        console.error('Error in request:', error);
        navigate('/page404');
      }
      } else{
      window.parent.location.replace(loginUrl);
      }
  }

  return <AcceptRequestView handleAccept={handleAccept} />;
};

export default AcceptRequest;
