import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ErrorState {
  redirectPath: string | null;
}

export const initialState: ErrorState = {
  redirectPath: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setRedirectPath(state, action: PayloadAction<string>) {
      state.redirectPath = action.payload;
    },
    clearRedirectPath(state) {
      state.redirectPath = null;
    },
  },
});

export const { setRedirectPath, clearRedirectPath } = errorSlice.actions;
export default errorSlice.reducer;
