import { Components, Theme } from '@mui/material/styles';
import { grey, secondaryColor } from 'src/assets/styles/variables';

const RadioGroupOverrides: Components<Theme> = {
  MuiRadio: {
    styleOverrides: {
      root: {
        color: grey,
        '&.Mui-checked': {
          color: secondaryColor,
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        color: grey,
      },
    },
  },
};

export default RadioGroupOverrides;
