import React, { useEffect, useMemo, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AdminDashboard from 'src/containers/super-admin/dashboard/super-admin-dashboard.component';
import ManageClientAdmins from 'src/containers/super-admin/manage-client-admins/manage-client-admins.component';
import AssignAdmintToClientID from 'src/containers/super-admin/assign-admin-to-client-id/assign-admin-to-clientId.component';
import CreateClientID from './containers/super-admin/create-clientId/create-client-id.view';
import MerchantProfile from 'src/containers/merchant-profile/merchant-profile.component';
import ViewAllClientIds from 'src/containers/super-admin/view-all-clientIds/view-all-clientIds.component';
import Dashboards from 'src/containers/dashboard/dashboard.component';
import ApproveRequest from 'src/containers/super-admin/admin-requests/approve-request-component';
import AcceptRequest from 'src/containers/accept-request/accept-request.component';
import Home from 'src/containers/home/home.component';
import Page401 from './components/common/error-handler/page401';
import Page404 from './components/common/error-handler/page404';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux-store/store';
import StatisticBar from './components/common/statistic-bar/statistic-bar.component';
import CommonConstants from './constants/common.constant';
import JoinMerchant from './containers/merchant-admin/join-merchant/join-merchant.component';

type NavItem = {
  text: string;
  path: string;
  action: () => void;
  disabled?: boolean;
};

const AppRoutes: React.FC = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const headingName = useSelector((state: any) => state.superAdmin.headingName);
  const statistic = useSelector(
    (state: any) => state.superAdmin.superAdminStatisticCount
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const redirectPath = useSelector(
    (state: RootState) => state.error.redirectPath
  );
  const userDataState = useSelector((state: any) => state.winkLogin.userData);

  const [activeTab, setActiveTab] = useState(0);

  const navItems: NavItem[] = useMemo(
    () =>
      userDataState?.userType === CommonConstants.userType.superAdmin
        ? [
            {
              text: t('LABEL.ACTIVE_MERCHANTS'),
              path: '/active-merchants',
              action: () => {
                navigate('/active-merchants');
              },
            },
            {
              text: t('LABEL.CREATE_MERCHANT'),
              path: '/create-merchant',
              action: () => {
                navigate('/create-merchant');
              },
            },
            {
              text: t('LABEL.MERCHANT_ADMINS'),
              path: '/manage-admin',
              action: () => {
                navigate('/manage-admin');
              },
            },
            {
              text: t('LABEL.MANAGE_REQUESTS'),
              path: '/manage-requests',
              action: () => {
                navigate('/manage-requests');
              },
            },
          ]
        : [
            {
              text: t('LABEL.MERCHANTS'),
              path: '/active-merchantId',
              action: () => {
                navigate('/active-merchantId');
              },
            },
            {
              text: t('LABEL.REQUEST_MERCHANT'),
              path: '/request-merchantId',
              action: () => {
                navigate('/request-merchantId');
              },
            },
            {
              text: t('LABEL.JOIN_MERCHANT'),
              path: '/join-merchant',
              action: () => {
                navigate('/join-merchant');
              },
            },
          ],
    [navigate, t, userDataState.userType]
  );

  const allowedTabPaths =
    userDataState.userType === CommonConstants.userType.superAdmin
      ? [
          '/active-merchants',
          '/create-merchant',
          '/manage-requests',
          '/assign-adminto-clientId',
          '/merchant-profile',
          '/manage-admin',
        ]
      : ['/', '/request-merchantId', '/active-merchantId', '/join-merchant'];

  useEffect(() => {
    const currentPath: any = location.pathname;
    const currentIndex = navItems.findIndex(
      (item) => item.path === currentPath
    );
    if (currentIndex !== -1) {
      setActiveTab(currentIndex);
    }

    // Redirect to /active-merchants if user is an admin and not already on an allowed path
    if (keycloak && keycloak.authenticated && currentPath === '/') {
      if (userDataState.userType === CommonConstants.userType.superAdmin) {
        currentPath !== '/accept-request' && navigate('/active-merchants', { replace: true });
      } else if (
        userDataState.userType === CommonConstants.userType.user ||
        userDataState.userType === CommonConstants.userType.merchantAdmin
      ) {
        currentPath !== '/accept-request' && navigate('/active-merchantId', { replace: true });
      }
    }
  }, [
    location.pathname,
    userDataState,
    navigate,
    navItems,
    userDataState.userType,
    keycloak,
  ]);

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath);
    }
  }, [redirectPath, navigate, dispatch]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    navItems[newValue].action();
  };

  const isErrorPage =
    location.pathname === '/page401' || location.pathname === '/page404'; // add here other error pages

  return (
    <>
      {keycloak.authenticated &&
        userDataState.userType === CommonConstants.userType.superAdmin &&
        !isErrorPage &&
        location.pathname !== '/dashboards' && (
          <StatisticBar heading={headingName} statisticCount={statistic} />
        )}
      {keycloak.authenticated &&
        userDataState.userType !== undefined &&
        allowedTabPaths.includes(location.pathname) && (
          <Box
            sx={{
              px: 1.5,
            }}
          >
            <Tabs
              value={activeTab}
              variant='scrollable'
              onChange={handleTabChange}
              aria-label='manage clientIds tabs'
              sx={{
                borderRadius: '12px',
                background: 'white',
                boxShadow: '0px 4px 8.5px 0px rgba(162, 162, 162, 0.25)',
                padding: '14px',
                m: '8px 0',
              }}
            >
              {navItems.map((item, index) => (
                <Tab
                  key={index}
                  label={item.text}
                  onClick={item.action}
                  sx={{
                    textTransform: 'none',
                    borderBottom: '1px solid grey',
                  }}
                  {...(item.disabled ? { disabled: true } : {})}
                />
              ))}
            </Tabs>
          </Box>
        )}
      {
        <Box
          sx={{
            p: location.pathname === '/' ? 0 : 1.5,
            borderRadius: '14px',
          }}
        >
          {keycloak.authenticated && userDataState.userType !== undefined ? (
            userDataState.userType === CommonConstants.userType.superAdmin ? (
              <Routes>
                <Route index element={<AdminDashboard />} />
                <Route path='/manage-admin' element={<ManageClientAdmins />} />
                <Route path='/create-merchant' element={<CreateClientID />} />
                <Route
                  path='/active-merchants'
                  element={<ViewAllClientIds />}
                />
                <Route path='/dashboards' element={<Dashboards />} />
                <Route
                  path='/assign-adminto-clientId'
                  element={<AssignAdmintToClientID />}
                />
                <Route path='/merchant-profile' element={<MerchantProfile />} />
                <Route path='/manage-requests' element={<ApproveRequest />} />
                <Route path='/accept-request' element={<AcceptRequest />} />
                <Route path='/page401' element={<Page401 />} />
                <Route path='/page404' element={<Page404 />} />
                <Route path='*' element={<AdminDashboard />} />
              </Routes>
            ) : (
              (userDataState?.userType === CommonConstants.userType.user ||
                userDataState?.userType ===
                  CommonConstants.userType.merchantAdmin) && (
                <>
                  <Routes>
                    <Route
                      index
                      path='/active-merchantId'
                      element={<ViewAllClientIds />}
                    />
                    <Route path='/dashboards' element={<Dashboards />} />
                    <Route
                      path='/request-merchantId'
                      element={<CreateClientID />}
                    />
                    <Route
                      path='/assign-adminto-clientId'
                      element={<AssignAdmintToClientID />}
                    />
                    <Route path='/join-merchant' element={<JoinMerchant />} />
                    <Route
                      path='/merchant-profile'
                      element={<MerchantProfile />}
                    />
                    <Route path='/page401' element={<Page401 />} />
                    <Route path='/page404' element={<Page404 />} />
                  </Routes>
                </>
              )
            )
          ) : (
            <Routes>
              <Route index element={<Home />} />
              <Route path='/page401' element={<Page401 />} />
              <Route path='/page404' element={<Page404 />} />
              <Route path='accept-request' element={<AcceptRequest />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          )}
        </Box>
      }
    </>
  );
};

export default AppRoutes;
