import React, { useState } from 'react';
import { Typography, Divider, Container, TextField, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fieldColor, white } from 'src/assets/styles/variables';
import styles from 'src/containers/merchant-admin/join-merchant/join-merchant.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/common/loader/loader.component';
import ButtonComponent from 'src/components/button/button.component';
import CommonConstants from 'src/constants/common.constant';
import { FieldError, useForm } from 'react-hook-form';
import { RootState } from 'src/redux-store/store';
import MerchantAdminService from '../merchant-admin.service';
import PopupComponent from 'src/components/popup/popup-component.component';
import { useNavigate } from 'react-router-dom';
import { superAdminSliceAction } from 'src/containers/super-admin/super-admin-reducer';

type userData = {
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
};
const JoinMerchantView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);

  const loading = useSelector((state: RootState) => state.superAdmin.loading);
  const userData: userData = useSelector(
    (state: any) => state.winkLogin.userData,
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      phoneNumber: userData?.contactNo,
      merchantId: '',
    },
  });
  const formData = watch();

  const onSubmit = async () => {
    dispatch(superAdminSliceAction.setSuperAdminLoading(true));
    await MerchantAdminService.joinToMerchant({
      merchantClientId: formData.merchantId,
    }).then((res: any) => {
      if (res?.isSuccess) {
        setOpenPopup(true);
      }
    }).finally(() =>{
      dispatch(superAdminSliceAction.setSuperAdminLoading(false));
    });
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
    navigate('/active-merchantId');
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Loader loading={loading} />
        <Typography variant='h2'>{t('LABEL.JOIN_MERCHANT')}</Typography>
        <Divider className={styles.container__divider} />
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} marginBottom={-1}>
              {' '}
              <Typography variant='h3' className={styles.container__h3}>
                {t('LABEL.USER_INFORMATION')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PLACEHOLDER.USER_FIRST_NAME')}
                fullWidth
                value={formData?.firstName}
                required
                disabled
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PLACEHOLDER.USER_LAST_NAME')}
                type='text'
                value={formData?.lastName}
                fullWidth
                disabled
                required={true}
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PLACEHOLDER.USER_EMAIL')}
                type='email'
                value={formData?.email}
                fullWidth
                disabled
                required={true}
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PLACEHOLDER.USER_MOBILE_NUMBER')}
                type='phone'
                value={formData?.phoneNumber}
                fullWidth
                required={true}
                disabled
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} marginBottom={-1}>
              {' '}
              <Typography variant='h3' className={styles.container__h3}>
                {t('LABEL.ENTER_MERCHANTID')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PLACEHOLDER.MERCHANTID')}
                type='text'
                fullWidth
                required={true}
                sx={{
                  backgroundColor: fieldColor,
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                    backgroundColor: white,
                    margin: 0,
                    paddingLeft: '10px',
                  },
                }}
                {...register('merchantId', {
                  required: t('ERROR_MESSAGE.MERCHANTID_REQUIRED'),
                  pattern: {
                    value: CommonConstants.validation.alphaNumeric,
                    message: t('ERROR_MESSAGE.MERCHANTID_INVALID'),
                  },
                  minLength: {
                    value: 2,
                    message: t('ERROR_MESSAGE.MINIMUM_CHAR_REQUIRED'),
                  },
                })}
                error={!!errors.merchantId}
                helperText={
                  errors.merchantId
                    ? (errors.merchantId as FieldError).message
                    : ''
                }
              />
            </Grid>
            <Grid item sm={6} xs={6} md={4}>
              <Typography
                variant='h3'
                textAlign='left'
                className={styles.container__h3}
              >
                {t('LABEL.MERCHANT_REQUEST')}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign='center'>
              <ButtonComponent
                variant='outlined'
                type='submit'
                disabled={!isValid || isSubmitting}
              >
                {t('BUTTON.SUBMIT_REQUEST')}
              </ButtonComponent>
            </Grid>
          </Grid>
        </Container>
      </form>
      <PopupComponent
        open={openPopup}
        onClose={handleClosePopup}
        title={t('MESSAGE.SUCCESSFUL_JOIN_MERCHANT')}
        inputValue={formData?.merchantId}
        label={t('PLACEHOLDER.MERCHANTID')}
      />
    </div>
  );
};

export default JoinMerchantView;
