import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DrawerState {
  isOpen: boolean;
  selectedRow: any;
}

const initialState: DrawerState = {
  isOpen: false,
  selectedRow: null,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer(state, action: PayloadAction<any>) {
      state.isOpen = true;
      state.selectedRow = action.payload;
    },
    closeDrawer(state) {
      state.isOpen = false;
    },
  },
});

export const drawerActions = drawerSlice.actions;
export default drawerSlice.reducer;
