import { Components, Theme } from '@mui/material/styles';

const ButtonOverrides: Components<Theme> = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        padding: '8px 22px',
        textTransform: 'capitalize',
        minWidth: '150px',
      },
    },
  },
};

export default ButtonOverrides;
