import { Components, Theme } from '@mui/material/styles';
import { grey, primaryColor } from 'src/assets/styles/variables';

const TextFieldOverrides: Components<Theme> = {
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
          backgroundColor: 'inherit',
        },
        borderRadius: '8px',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '8px',

        '& fieldset': {
          borderColor: grey,
        },
      },
      input: {
        borderRadius: '8px',
        backgroundColor: 'inherit',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: grey,
        '&.Mui-focused': {
          color: `${primaryColor} !important`,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: grey,
        '&.Mui-focused': {
          color: `${primaryColor} !important`,
        },
        '&.MuiFormLabel-filled': {
          color: `${primaryColor} !important`,
        },
      },
    },
  },
};

export default TextFieldOverrides;
