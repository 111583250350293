import { useSelector } from 'react-redux';

export function useSuperAdminInfo() {
  const isSuperAdmin =
    useSelector((state: any) => state?.winkLogin?.userData?.userType) === 1;
  const containerClassName = isSuperAdmin
    ? 'layout_main_container super-admin'
    : 'layout_main_container';

  return { isSuperAdmin, containerClassName };
}
