import React from 'react';
import WinkLoginComponent from 'src/components/wink-login/wink-login.component';

const Home: React.FC = () => {
  return (
    <>
      <WinkLoginComponent />
    </>
  );
};

export default Home;
