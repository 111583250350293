import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { configuration } from 'src/config';

const whitelist = ['en'];
const defaultLanguage = 'en';
const options: any = {
  lng: 'en',
  initImmediate: false,
  whitelist,
  fallbackLng: defaultLanguage,
  load: 'languageOnly',
  ns: ['translations'],
  defaultNS: 'translations',
  detection: {
    lookupQuerystring: 'lang',
  },
  backend: {
    loadPath: `${configuration.basePath}/locales/{{lng}}/{{ns}}.json`,
  },
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
    format: (value: any, format: any) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },

  react: {
    wait: true,
    useSuspense: false,
  },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
