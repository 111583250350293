import { Components, Theme } from '@mui/material/styles';
import { disabledColor, grey } from 'src/assets/styles/variables';

const TabsOverrides: Components<Theme> = {
  MuiTab: {
    styleOverrides: {
      root: {
        '&:not(.Mui-selected)': {
          color: grey,
        },
        '&.Mui-disabled': {
          color: disabledColor,
        },
      },
    },
  },
};

export default TabsOverrides;
