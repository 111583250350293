import { Components, Theme } from '@mui/material/styles';
import { red, white } from 'src/assets/styles/variables';

const TableOverrides: Components<Theme> = {
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'white',
        },
        '&:nth-of-type(even)': {
          backgroundColor: 'white',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.01)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          backgroundColor: '#f5f5f5',
        },
      },
    },
  },
  MuiPagination: {
    styleOverrides: {
      root: {
        display: 'flex',
        marginTop: '16px',
        marginBottom: '16px',
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: red,
          color: white,
          borderRadius: '50%',
        },
      },
    },
  },
};

export default TableOverrides;
