import React from 'react';
import WinkLogo from 'src/assets/images/wink-logo-colored.png';
import {
  Box,
  CardContent,
  Dialog,
  Typography,
  Button,
  Card,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type IConfirmAction = {
  action: () => void;
  text: string;
};
interface PopupComponentProps {
  open: boolean;
  onClose: () => void;
  title: string;
  error?: boolean;
  inputValue?: string;
  label?: string;
  confirmAction?: IConfirmAction;
}

const PopupComponent: React.FC<PopupComponentProps> = ({
  open,
  onClose,
  title,
  error,
  inputValue,
  label,
  confirmAction,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '17px',
          padding: 3,
          width: 'fit-content',
          maxWidth: '400px',
        },
      }}
    >
      <Box
        className='box'
        sx={{
          width: '100%',
          height: 'auto',
          position: 'relative',
          padding: 0,
          margin: 0,
        }}
      >
        <Card
          variant='elevation'
          sx={{
            borderRadius: 1,
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={WinkLogo}
              alt='Logo'
              style={{
                marginTop: '-2vh',
                padding: '10px',
                maxHeight: '40px',
              }}
            />
            <Typography
              sx={{
                fontFamily: 'Rubik, sans-serif',
                fontSize: '20px',
                color: error ? 'red' : '',
                textAlign: 'center',
                marginBottom: '10px',
                marginTop: '1.3rem',
                padding: '5px',
              }}
            >
              {title}
            </Typography>
            <br />
            {inputValue && (
              <TextField
                label={label}
                value={inputValue}
                disabled
                sx={{ marginTop: '20px', minWidth: '180px' }}
              />
            )}
            <Box display='flex' gap={2} sx={{ marginTop: '20px' }}>
              <Button
                variant='outlined'
                color='secondary'
                onClick={onClose}
                sx={{
                  minWidth: '100px',
                }}
              >
                {t('LABEL.CLOSE')}
              </Button>
              {confirmAction?.text && (
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={confirmAction.action}
                  sx={{
                    minWidth: '100px',
                  }}
                >
                  {confirmAction.text}
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  );
};

export default PopupComponent;
