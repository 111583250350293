import React, { useState, useEffect } from 'react';
import {
  useFieldArray,
  useForm,
  Controller,
  FieldError,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Container,
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import { InfoOutlined } from '@mui/icons-material';

import ButtonComponent from '../../components/button/button.component';
import PopupComponent from 'src/components/popup/popup-component.component';
import { FormData } from './merchant-profile.component';

import { fieldColor, grey, white } from 'src/assets/styles/variables';
import style from './merchant-profile.module.scss';
import { useNavigate } from 'react-router-dom';
import UtilityService from 'src/services/utlits.service';
import CommonConstants from 'src/constants/common.constant';

interface CustomIconButtonProps {
  isAdd: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  isAdd,
  onClick,
  disabled,
}) => (
  <IconButton
    onClick={onClick}
    disabled={disabled}
    sx={{
      color: 'grey',
      border: '3px solid grey',
      backgroundColor: 'transparent',
      width: 32,
      height: 32,
      '.MuiSvgIcon-root': {
        fontSize: '2rem',
      },
      ml: '14px',
    }}
  >
    {isAdd ? <AddIcon /> : <RemoveIcon />}
  </IconButton>
);

export const mapAccessTypeToProductType = (accessType: number) => {
  const typeMap = ['Both', 'Login', 'Payment'];
  return typeMap[accessType] || 'Both';
};

const MerchantProfileView: React.FC<{
  isSuperAdmin?: boolean;
  merchantProfile: { [key: string]: any };
  backToSettings: () => void;
  submitForm: (data: FormData) => Promise<void>;
  setOpenPopup: (open: boolean) => void;
  openPopUp?: boolean;
  popUpText?: string;
  profileUpdated: boolean;
}> = ({
  merchantProfile,
  backToSettings,
  submitForm,
  setOpenPopup,
  openPopUp = false,
  popUpText = '',
  profileUpdated = false,
}) => {
  const [expanded, setExpanded] = useState<string | false>('compInfo');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitting},
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      firstName: merchantProfile?.merchant?.firstName || '',
      lastName: merchantProfile?.merchant?.lastName || '',
      email: merchantProfile?.merchant?.email || '',
      phoneNumber: merchantProfile?.merchant?.phoneNumber || '',
      companyName: merchantProfile?.merchant?.companyName || '',
      streetAddress: merchantProfile?.merchantAddress?.streetAddress || '',
      state: merchantProfile?.merchantAddress?.state || '',
      postalCode: merchantProfile?.merchantAddress?.postalCode || '',
      city: merchantProfile?.merchantAddress?.city || '',
      webURL: merchantProfile?.merchant?.webURL || '',
      applicationDomainURL:
        merchantProfile?.merchant?.applicationDomainURL || '',
      redirectUris:
        merchantProfile?.merchant?.redirectUris?.length > 0
          ? merchantProfile?.merchant?.redirectUris.map((uri: string) => ({
              url: uri,
            }))
          : [{ url: '' }],
      productType: mapAccessTypeToProductType(
        merchantProfile?.merchant?.applicationAccessType,
      ),
      naChecked: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'redirectUris',
  });

  const handleChangePanel =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(
        isExpanded ? panel : panel === 'compInfo' ? 'addInfo' : 'compInfo',
      );
    };

  const onSubmit = async (data: FormData) => {
    /*  if (!data.agreeTerms) {
      setOpenPopup(true);
      return;
    } */
    await submitForm(data);
  };

  const handleNAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('naChecked', event.target.checked);
    if (event.target.checked) {
      fields.forEach((_, index) => {
        setValue(`redirectUris.${index}.url`, '', { shouldValidate: false });
      });
    }
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({ url: '' });
    }
  }, [append, fields.length]);

  const validatePhoneNumber = (value: any) => {
    return UtilityService.formatPhoneField(value)
  };
  const handlePhoneChange = (e: any) => {
    e.target.value = UtilityService.handlePhoneChange(e);
  };

  const handleZipValidation = (value:any) =>{
    if(value.length < 5 || !CommonConstants.validation.zipCode.test(value)){
      return 'Please enter valid zipCode'
    }
  }
  return (
    <Container>
      <Box className={style.clientIDHead}>
        <Box className={style.clientIDHeadingText}>
          {t('LABEL.MERCHANT_PROFILE')}
        </Box>
        <Divider className={style.container__divider} />

        <Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={style.merchantProfileForm}
          >
            <Accordion
              defaultExpanded
              expanded={expanded === 'compInfo'}
              onChange={handleChangePanel('compInfo')}
            >
              <AccordionSummary expandIcon={<EditIcon color='primary' />}>
                <Typography fontWeight={500} color={grey} fontSize={20}>
                  {t('LABEL.COMPANY_INFORMATION')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display='flex'
                  gap={2}
                  flexDirection='column'
                  m='auto'
                  width='90%'
                >
                  <Typography variant='body2' gutterBottom textAlign='center'>
                    {t('MESSAGE.COMPANY_INFORMATION_FORM')}
                  </Typography>
                  <Typography variant='body2' gutterBottom textAlign='center'>
                    {t('MESSAGE.COMPLETE_BELOW_FORM')}
                  </Typography>
                  <Typography variant='body2' gutterBottom textAlign='start'>
                    {t('LABEL.TELL_ABOUT_YOUR_COMPANY')}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t('PLACEHOLDER.WEBSITE_URL')}
                        {...register('webURL', {
                          required: 'Website URL is required',
                        })}
                        error={!!errors.webURL}
                        helperText={errors.webURL?.message}
                        required
                        type='url'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={t('PLACEHOLDER.USER_FIRST_NAME')}
                        {...register('firstName')}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        required
                        type='text'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={t('PLACEHOLDER.USER_LAST_NAME')}
                        {...register('lastName')}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        required
                        type='text'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={t('PLACEHOLDER.USER_EMAIL')}
                        {...register('email')}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        required
                        type='email'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Controller
                      name='phoneNumber'
                      control={control}
                      rules={{
                        required: t('ERROR_MESSAGE.PHONE_REQUIRED'),
                        validate: validatePhoneNumber, // Validate using the custom regex function
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('LABEL.PHONE_NUMBER')}
                          type='phone'
                          fullWidth
                          required={true}
                          onChange={(e) => {
                            handlePhoneChange(e); // Sanitize input
                            field.onChange(e); // React Hook Form field change
                          }}
                          sx={{
                            backgroundColor: fieldColor,
                            '& .MuiFormHelperText-root': {
                              fontSize: '0.75rem',
                              backgroundColor: white,
                              margin: 0,
                              paddingLeft: '10px',
                            },
                          }}
                          error={!!errors.phoneNumber}
                          helperText={
                            errors.phoneNumber
                              ? (errors.phoneNumber as FieldError).message
                              : ''
                          }
                          inputProps={{ maxLength: '13' }}
                        />
                      )}
                    />
                    </Grid>
                  </Grid>
                  <Typography
                    variant='body2'
                    gutterBottom
                    textAlign='center'
                    color='#8D929C'
                  >
                    {t('MESSAGE.LEGAL_NAME')}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t('PLACEHOLDER.USER_COMPANY_NAME')}
                        {...register('companyName')}
                        required
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t('PLACEHOLDER.STREET_ADDRESS')}
                        {...register('streetAddress')}
                        error={!!errors.streetAddress}
                        helperText={errors.streetAddress?.message}
                        required
                        type='text'
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label={t('PLACEHOLDER.CITY')}
                        {...register('city',
                          {pattern: {
                            value: CommonConstants.validation.name,
                            message: t('ERROR_MESSAGE.CITY_INVALID'),
                          },}
                        )}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label={t('PLACEHOLDER.STATE_REGION')}
                        {...register('state')}
                        error={!!errors.state}
                        helperText={errors.state?.message}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <Controller
                      name='postalCode'
                      control={control}
                      rules={{
                        validate: handleZipValidation
                      }}
                      render={({ field }) => (
                        <TextField
                        fullWidth
                        value={field.value}
                        label={t('PLACEHOLDER.POSTAL_CODE')}
                        onChange={(e) => {
                          UtilityService.validatePostalCode(e); 
                          field.onChange(e);
                        }}
                        error={!!errors.postalCode}
                        helperText={errors.postalCode?.message}
                        required
                        inputProps={{ maxLength: '13', minLength:'5'}}
                        />
                      )}
                    />
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion
              defaultExpanded
              expanded={expanded === 'addInfo'}
              onChange={handleChangePanel('addInfo')}
            >
              <AccordionSummary expandIcon={<EditIcon color='primary' />}>
                <Typography fontWeight={500} color={grey} fontSize={20}>
                  {t('LABEL.ADDITIONAL_INFO')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display='flex'
                  gap={2}
                  flexDirection='column'
                  m='auto'
                  width='90%'
                >
                  <FormControl component='fieldset'>
                    <FormLabel
                      component='legend'
                      sx={{
                        fontWeight: 400,
                        fontSize: '18px',
                        mb: '18px',
                      }}
                    >
                      {t('LABEL.PRODUCT_REQUEST')}
                    </FormLabel>
                    <Controller
                      name='productType'
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          row
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '70%',
                            margin: 'auto',
                          }}
                        >
                          <FormControlLabel
                            value='Login'
                            control={<Radio />}
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {t('PLACEHOLDER.USER_SERVICE_TYPE_WL')}
                                <Tooltip
                                  enterTouchDelay={0}
                                  leaveTouchDelay={3000}
                                  title='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequa'
                                >
                                  <IconButton>
                                    <InfoOutlined
                                      sx={{
                                        color: '#8D929C',
                                        width: '18px',
                                        height: '18px',
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />

                          <FormControlLabel
                            value='Payment'
                            control={<Radio />}
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {t('PLACEHOLDER.USER_SERVICE_TYPE_WP')}
                                <Tooltip
                                  enterTouchDelay={0}
                                  leaveTouchDelay={3000}
                                  title='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequa'
                                >
                                  <IconButton>
                                    <InfoOutlined
                                      sx={{
                                        color: '#8D929C',
                                        width: '18px',
                                        height: '18px',
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                          <FormControlLabel
                            value='Both'
                            control={<Radio />}
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {t('PLACEHOLDER.USER_SERVICE_TYPE_B')}
                                <Tooltip
                                  enterTouchDelay={0}
                                  leaveTouchDelay={3000}
                                  title='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequa'
                                >
                                  <IconButton>
                                    <InfoOutlined
                                      sx={{
                                        color: '#8D929C',
                                        width: '18px',
                                        height: '18px',
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>

                  <Typography fontWeight={400} color={grey} fontSize={18}>
                    {t('LABEL.DOMAIN_DETAILS')}
                  </Typography>
                  <Box width='90%' m='auto'>
                    <Typography
                      variant='body2'
                      gutterBottom
                      textAlign='center'
                      color='#8D929C'
                    >
                      {t('MESSAGE.COMPANY_DOMAIN_URI')}
                    </Typography>

                    <TextField
                      fullWidth
                      label='Application Domain URL'
                      required
                      {...register('applicationDomainURL')}
                      error={!!errors.applicationDomainURL}
                      helperText={errors.applicationDomainURL?.message}
                      type='url'
                    />
                  </Box>
                  <Box
                    width='90%'
                    m='auto'
                    display='flex'
                    flexDirection='column'
                  >
                    <Typography
                      variant='body2'
                      gutterBottom
                      textAlign='center'
                      color='#8D929C'
                    >
                      {t('MESSAGE.REDIRECT_URI')}
                    </Typography>

                    <Box display='flex' justifyContent='flex-end'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={watch('naChecked')}
                            onChange={handleNAChange}
                            sx={{
                              color: grey,
                              '&.Mui-checked': { color: grey },
                            }}
                          />
                        }
                        label='N/A'
                        sx={{ color: grey }}
                      />
                    </Box>
                    <Box display='flex' flexDirection='column' gap={2}>
                      {fields.map((field, index) => (
                        <Box key={field.id} display='flex' alignItems='center'>
                          <TextField
                            fullWidth
                            label={t('PLACEHOLDER.USER_WHITE_LISTED_DOMAIN')}
                            {...register(`redirectUris.${index}.url`)}
                            disabled={watch('naChecked')}
                            required={!watch('naChecked')}
                            type='url'
                            defaultValue={field.url}
                          />
                          {index === 0 ? (
                            <CustomIconButton
                              isAdd={true}
                              onClick={() => append({ url: '' })}
                              disabled={watch('naChecked')}
                            />
                          ) : (
                            <CustomIconButton
                              isAdd={false}
                              onClick={() => remove(index)}
                              disabled={watch('naChecked')}
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Box display='flex' justifyContent='center' mt={5} gap={3}>
              <ButtonComponent
                type='submit'
                variant='outlined'
                color='primary'
                onClick={backToSettings}
              >
                {t('LABEL.CLOSE')}
              </ButtonComponent>
              <ButtonComponent
                type='submit'
                variant='contained'
                color='primary'
                disabled={!isValid || isSubmitting}
              >
                {t('BUTTON.SAVE')}
              </ButtonComponent>
            </Box>
          </form>
        </Box>
      </Box>
      <PopupComponent
        open={openPopUp}
        onClose={() => {
          setOpenPopup(false);
          if (profileUpdated) navigate('/');
        }}
        title={popUpText}
      />
    </Container>
  );
};

export default MerchantProfileView;
