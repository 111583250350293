import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './page404.module.scss';
import ButtonComponent from 'src/components/button/button.component';
import { Box, Typography } from '@mui/material';

const Page401 = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box className={style.errorPage}>
        <Box className={style.errorPage__message}>
          <Typography
            fontWeight={800}
            fontSize={36}
            className={style['errorPage__title--401']}
          >
            {t('MESSAGE.401_ERROR_TITLE')}
          </Typography>
          <Box className={style['errorPage__error--401']}>
            <Typography
              fontWeight={600}
              fontSize={20}
              className={style['errorPage__error-text']}
            >
              {t('MESSAGE.PAGE401_MESSAGE_1')}
            </Typography>
          </Box>
          <Box className={style['errorPage__error--401']}>
            <Typography
              fontWeight={600}
              fontSize={20}
              className={style['errorPage__error-text']}
            >
              {t('MESSAGE.PAGE401_MESSAGE_2')}
            </Typography>
          </Box>
        </Box>
        <Box className={style['errorPage__button-wrapper']}>
          <ButtonComponent
            color='primary'
            variant='contained'
            onClick={() => (window.location.pathname = '/')}
          >
            {t('BUTTON.RETURN_HOME')}
          </ButtonComponent>
        </Box>
      </Box>
    </Box>
  );
};

export default Page401;
