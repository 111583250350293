import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { apm } from '@elastic/apm-rum';
import * as yup from 'yup';
import ErrorHandlingService from 'src/components/common/error-handler/errorloging.service';
import { configuration } from 'src/config';
import { getToken, getMerchantAccessToken } from './auth.service';

const createMethod = (xhr: AxiosInstance) => ({
  instance: xhr,

  post: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig,
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.post(url, data, config);
      return schema
        .validate(response.data, { stripUnknown: true })
        .then(() => {
          return response.data;
        })
        .catch((e) => {
          apm.captureError(e.message);
          throw e.message;
        });
    } catch (err) {
      return null;
    }
  },

  get: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig,
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.get(url, {
        ...config,
        params: data,
      });
      return schema
        .validate(response.data, { stripUnknown: true })
        .then(() => {
          return response.data;
        })
        .catch((e) => {
          apm.captureError(e.message);
          throw e.message;
        });
    } catch (errc: any) {
      if (errc?.status === 404) {
        apm.captureError(errc.data.Message);
        return { data: [], Message: errc.data.Message };
      }
      return null;
    }
  },

  put: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig,
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.put(url, data, config);

      return schema
        .validate(response.data)
        .then(() => {
          return response.data;
        })
        .catch((e) => {
          apm.captureError(e.message);
          throw e.message;
        });
    } catch (err) {
      return null;
    }
  },

  delete: async <D, S extends yup.SchemaOf<any>>(
    url: string,
    data: D,
    schema: S,
    config?: AxiosRequestConfig,
  ): Promise<yup.InferType<S>> => {
    try {
      const response = await xhr.delete(url, { ...config, data });
      return schema
        .validate(response.data, { stripUnknown: true })
        .then(() => response.data)
        .catch((e: any) => {
          apm.captureError(e.message);
          throw new Error(e.message);
        });
    } catch (err) {
      console.error('Delete request failed:', err);
      throw err;
    }
  },
});

export const httpClient = (
  isBasicAuth: any = false,
  apiEndpoint: any = '',
): any => {
  const baseURL = apiEndpoint === '' ? configuration.apiEndPoint : apiEndpoint;

  const xhr = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
    transformRequest: [
      (data) => {
        // This is for uploading files
        if (data instanceof FormData) {
          return data;
        }
        return JSON.stringify(data);
      },
    ],
  });

  // Set the AUTH token for any request
  xhr.interceptors.request.use(async (oldConfig: any) => {
    const config = {
      ...oldConfig,
    };
    const token = getToken();
    const merchantToken = getMerchantAccessToken();
    config.headers.Authorization = `Bearer ${token}`;
    if (merchantToken !== undefined && merchantToken !== '') {
      config.headers['X-MP-Access'] = merchantToken;
    }
    return config;
  });

  // We'll override Axios default error handler
  xhr.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      ErrorHandlingService.handleErrorStatusCode(error.response || error);
      apm.captureError(error);
      return Promise.reject(error.response || error);
    },
  );
  return createMethod(xhr);
};
