import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from 'src/components/button/button.component';
type iProps = {
  handleAccept: () => void;
};
const AcceptRequestView: React.FC<iProps> = ({handleAccept }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
        <Box textAlign='center' sx={{ margin: 3 }}>
          <Typography fontSize={16} fontWeight={800}>
            {t('MESSAGE.AGREE_REQUEST')}
          </Typography>
          <ButtonComponent
            color='secondary'
            variant='contained'
            sx={{ margin: 1 }}
            onClick={handleAccept}
          >
            {t('BUTTON.ACCEPT')}
          </ButtonComponent>
          <ButtonComponent
            color='primary'
            variant='contained'
            onClick={() => {
              navigate('/');
            }}
          >
            {t('BUTTON.DENY')}
          </ButtonComponent>
        </Box>
    </>
  );
};
export default AcceptRequestView;
