import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'src/containers/app/app.component';
import { configuration } from 'src/config';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './components/common/error-handler/error-boundaries.view';
import { Provider } from 'react-redux';
import store from 'src/redux-store/store';
import { apm } from '@elastic/apm-rum';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloakConfig from './keycloak-login/keycloak';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error);
};
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorHandler}
    onError={(error) => apm.captureError(error)}
  >
    <Router basename={`${configuration.basePath}`}>
      <ReactKeycloakProvider
        authClient={keycloakConfig}
        onEvent={eventLogger}
        initOptions={{
          onLoad: 'check-sso',
          pkceMethod: 'S256',
          silentCheckSsoRedirectUri:
            window.location.origin + '/silent-check-sso.html',
          checkLoginIframe: true,
          silentCheckSsoFallback: true,
          enableLogging: true,
        }}
        autoRefreshToken={false}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </ReactKeycloakProvider>
    </Router>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
