import React, { useEffect, useState } from 'react';
import { Controller, useForm, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  Typography,
  Card,
  CardActionArea,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  DialogActions,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import Loader from 'src/components/common/loader/loader.component';
import PopupComponent from 'src/components/popup/popup-component.component';
import ButtonComponent from 'src/components/button/button.component';
import { dashboard } from './dashboard.component';

import DashboardServices from './dashboard.services';

import { fieldColor, white } from 'src/assets/styles/variables';
import CommonConstants from 'src/constants/common.constant';
import style from './dashboard.module.scss';

interface DashboardViewProps {
  generalLoading: boolean;
  setDashboards: React.Dispatch<React.SetStateAction<dashboard[]>>;
  setGeneralLoading: React.Dispatch<React.SetStateAction<boolean>>;
  tabValue: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  onClose: () => void;
  dashboards: dashboard[];
  fetchData: () => void;
  setOpenPopupError: React.Dispatch<React.SetStateAction<boolean>>;
  setPopUpTitleError: React.Dispatch<React.SetStateAction<string>>;
  openPopupError: boolean;
  popUpTitleError: string;
}

type processorList = {
  id: string;
  name: string;
};

interface ViewCredentialsDialogProps {
  open: boolean;
  onClose: () => void;
  dashboard: {
    logoFileUrl?: string;
    title: string;
    username: string;
    password: string;
  };
}

const ViewCredentialsDialog: React.FC<ViewCredentialsDialogProps> = ({
  open,
  onClose,
  dashboard,
}) => {
  const { t } = useTranslation();
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '10px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', padding: '20px 24px' }}>
        {dashboard.logoFileUrl ? (
          <img
            src={dashboard.logoFileUrl}
            alt={`${dashboard.title} logo`}
            style={{ width: '180px', display: 'block', margin: '0 auto' }}
          />
        ) : (
          <Typography
            variant='h5'
            fontWeight='bold'
            color='primary.main'
            sx={{ my: 6 }}
          >
            {dashboard.title}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '32px',
          pt: '4px !important',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          mt: 2,
        }}
      >
        <TextField
          label='User name'
          value={dashboard.username}
          InputLabelProps={{
            style: { fontSize: '1rem', color: 'grey.700' },
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => handleCopy(dashboard.username)}
                  sx={(theme) => ({
                    color: theme.palette.grey[500],
                  })}
                >
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              fontSize: '1.1rem',
              padding: '10px 14px',
            },
          }}
          fullWidth
          sx={{
            borderRadius: '8px',
          }}
        />
        <TextField
          label='Password'
          value={dashboard.password}
          type='password'
          InputLabelProps={{
            style: { fontSize: '1rem', color: 'grey.700' },
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => handleCopy(dashboard.password)}
                  sx={(theme) => ({
                    color: theme.palette.grey[500],
                  })}
                >
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              fontSize: '1.1rem',
              padding: '10px 14px',
            },
          }}
          fullWidth
          sx={{
            borderRadius: '8px',
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          padding: '16px',
          my: 4,
        }}
      >
        <ButtonComponent
          type='submit'
          variant='contained'
          color='primary'
          onClick={onClose}
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {t('LABEL.CLOSE')}
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

const DashboardView: React.FC<DashboardViewProps> = ({
  tabValue,
  onTabChange,
  onClose,
  dashboards,
  fetchData,
  generalLoading,
  setGeneralLoading,
  setDashboards,
  setOpenPopupError,
  openPopupError,
  setPopUpTitleError,
  popUpTitleError,
}) => {
  const { t } = useTranslation();
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openCredentialsDialog, setOpenCredentialsDialog] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listOfProcessor, setListOfProcessor] = useState<processorList[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [popUpLabel, setPopUpLabel] = useState<string>('');
  const [popUpTitle, setPopUpTitle] = useState<string>('');
  const [dashboardToDelete, setDashboardToDelete] = useState<string>('');
  const [selectedDashboard, setSelectedDashboard] = useState<dashboard | null>(
    null,
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
    reset,
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      userName: '',
      password: '',
      processors: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      logoFileUrl: '',
      title: '',
    },
  });
  const formData = watch();

  useEffect(() => {
    (async () => {
      if (openSaveDialog) {
        if (tabValue === 0) {
          const res = await DashboardServices.getPaymentProcessorList();
          setListOfProcessor(res.data.processors);
        }
        if (tabValue === 1) {
          const res = await DashboardServices.getFraudProcessorList();
          setListOfProcessor(res.data.processors);
        }
      }
    })();
  }, [openSaveDialog, tabValue]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleDialogClose = () => {
    setOpenSaveDialog(false);
    setOpenEditDialog(false);
    setOpenViewDialog(false);
    reset();
  };

  const handleEditOpen = (data: any) => {
    setOpenEditDialog(true);
    setValue('userName', data.username);
    setValue('password', data.password);
    setValue('processors', data.id);
    setValue('title', data.title);
    setValue('logoFileUrl', data.logoFileUrl);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (openSaveDialog) {
      await DashboardServices.saveThirdPartyProcessor({
        username: formData.userName,
        password: formData.password,
        thirdPartyIntegrationType: 0,
        entityId: formData.processors,
      })
        .then((res: any) => {
          if (res?.isSuccess) {
            setOpenSaveDialog(false);
            reset();
            fetchData();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (openEditDialog) {
      await DashboardServices.updateThirdPartyProcessor(formData.processors, {
        username: formData.userName,
        password: formData.newPassword,
      })
        .then((res: any) => {
          if (res?.isSuccess) {
            setOpenEditDialog(false);
            reset();
            fetchData();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const validateCurrentPassword = (value: any) => {
    if (value !== formData.password) {
      return t('ERROR_MESSAGE.CURRENT_PASSWORD_MISMATCH');
    }
    return true;
  };

  const validateNewPassword = (value: any) => {
    if (value === formData?.currentPassword) {
      return t('ERROR_MESSAGE.NEW_PASSWORD_SAME_AS_CURRENT');
    }
    return true;
  };

  const validatePasswordsMatch = (value: any) => {
    if (formData?.newPassword !== value) {
      return t('ERROR_MESSAGE.NEW_PASSWORD_MISMATCH');
    }
    if (value === formData?.password) {
      return t('ERROR_MESSAGE.NEW_PASSWORD_SAME_AS_CURRENT');
    }
    return true;
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setPopUpTitle('');
    setPopUpLabel('');
  };

  const handleClosePopupError = () => {
    setOpenPopupError(false);
    setPopUpTitleError('');
  };

  const handleDeleteDashboard = () => {
    setOpenPopup(true);
    setPopUpTitle('You are deleting dashboard');
    setPopUpLabel('');
  };

  const confirmDeleteDashboard = async () => {
    try {
      setGeneralLoading(true);
      const response =
        await DashboardServices.deleteThirdPartyCredential(dashboardToDelete);

      if (response?.isSuccess) {
        const updatedDashboards = dashboards.filter(
          (dashboard) => dashboard.id !== dashboardToDelete,
        );
        // Actualiza el estado
        setDashboards(updatedDashboards);
      } else {
        console.error('Failed to delete the dashboard:', response?.message);
        setPopUpTitleError('Error deleting dashboard.');
        setOpenPopupError(true);
      }
    } catch (error) {
      console.error('Error deleting dashboard:', error);
      setPopUpTitleError('Error deleting dashboard.');
      setOpenPopupError(true);
    } finally {
      handleClosePopup();
      setGeneralLoading(false);
    }
  };

  return (
    <>
      <Loader loading={isLoading || isSubmitting} />
      <Box
        sx={{
          padding: 3,
          position: 'relative',
          minHeight: '100vh',
          bgcolor: '#f5f5f5',
        }}
      >
        <Box className={style.header}>
          <Grid container alignItems='center'>
            <Grid item xs={12} md={4} />
            <Grid item xs={10} md={4} className={style.headerContent}>
              <Typography variant='h2'>{t('LABEL.DASHBOARDS')}</Typography>
            </Grid>
            <Grid item xs={2} md={4} justifyContent='flex-end' display='flex'>
              <IconButton
                onClick={onClose}
                aria-label='close'
                sx={(theme) => ({
                  color: theme.palette.grey[500],
                })}
              >
                <CloseIcon sx={{ color: '#5762a3' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Tabs
            value={tabValue}
            onChange={onTabChange}
            aria-label='dashboard tabs'
            variant='scrollable'
            sx={{
              borderRadius: '12px',
              background: '#f5f5f5',
              boxShadow: '0px 4px 8.5px 0px rgba(162, 162, 162, 0.25)',
              padding: '14px',
              m: '8px 0',
            }}
          >
            <Tab
              label={t('LABEL.PAYMENT_PROCESSORS')}
              sx={{ textTransform: 'none' }}
            />
            <Tab
              label={t('LABEL.FRAUD_MANAGEMENT')}
              sx={{ textTransform: 'none' }}
            />
          </Tabs>
        </Box>
        {generalLoading ? (
          <Loader loading={generalLoading} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 8,
              justifyContent: 'center',
              mt: 4,
            }}
          >
            {dashboards?.length > 0 &&
              dashboards?.map((dashboard) => (
                <Card key={dashboard.id} className={style.card}>
                  {dashboard.logoFileUrl ? (
                    <img
                      src={dashboard.logoFileUrl}
                      alt={`${dashboard.title} logo`}
                      style={{ width: '100px', marginBottom: '30px' }}
                    />
                  ) : (
                    <Typography
                      variant='h5'
                      sx={{
                        my: '30px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: 'secondary.main',
                        textAlign: 'center',
                      }}
                    >
                      {dashboard.title}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '14px',
                      width: '100%',
                    }}
                  >
                    <ButtonComponent
                      variant='contained'
                      href={dashboard.url}
                      target='_blank'
                      className={style['card-buttons']}
                    >
                      {t('LABEL.LAUNCH_DASHBOARD')}
                    </ButtonComponent>
                    <ButtonComponent
                      variant='contained'
                      className={style['card-buttons']}
                      onClick={() => {
                        setSelectedDashboard(dashboard);
                        setOpenCredentialsDialog(true);
                      }}
                    >
                      {t('LABEL.VIEW_CREDENTIALS')}
                    </ButtonComponent>
                  </Box>
                  <IconButton
                    size='small'
                    className={style['icon-button']}
                    onClick={() => handleEditOpen(dashboard)}
                    sx={(theme) => ({
                      color: theme.palette.grey[500],
                    })}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size='small'
                    className={style['delete-icon']}
                    sx={(theme) => ({
                      color: theme.palette.grey[500],
                    })}
                    onClick={() => {
                      setDashboardToDelete(dashboard.id);
                      handleDeleteDashboard();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
              ))}

            <Card
              className={style.card}
              sx={{ p: 0 }}
              onClick={() => setOpenSaveDialog(true)}
            >
              <CardActionArea className={style['card-action-area']}>
                <AddCircleIcon sx={{ fontSize: 80, color: '#5762a3' }} />
                <Typography variant='body2' color='grey.600' sx={{ mt: 4 }}>
                  {t('LABEL.ADD_DASHBOARD')}
                </Typography>
              </CardActionArea>
            </Card>
          </Box>
        )}
      </Box>
      <Grid>
        <Dialog
          open={openSaveDialog || openEditDialog || openViewDialog}
          className={style.configureDashboard}
          PaperProps={{
            style: {
              borderRadius: '10px',
            },
          }}
        >
          <DialogTitle>
            <Typography
              variant='h4'
              textAlign='center'
              color='grey.600'
              fontSize={24}
              sx={{ mt: 4, lineHeight: '35px' }}
            >
              {openSaveDialog && t('LABEL.CONFIGURE_DASHBOARD')}
              {openEditDialog &&
                (formData.logoFileUrl ? (
                  <img
                    src={formData.logoFileUrl}
                    alt={`${formData.title} logo`}
                    style={{
                      width: '180px',
                      display: 'block',
                      margin: '0 auto',
                    }}
                  />
                ) : (
                  <Typography
                    variant='h5'
                    fontWeight='bold'
                    color='primary.main'
                    sx={{ my: 6 }}
                  >
                    {formData.title}
                  </Typography>
                ))}
            </Typography>
          </DialogTitle>
          <IconButton
            aria-label='close'
            onClick={handleDialogClose}
            sx={(theme) => ({
              color: theme.palette.grey[500],
              position: 'absolute',
              right: 8,
              top: 8,
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} textAlign='center'>
                {!openEditDialog && (
                  <Grid item xs={12}>
                    <Controller
                      name='processors'
                      control={control}
                      defaultValue=''
                      rules={
                        !openEditDialog
                          ? { required: t('ERROR_MESSAGE.USERNAME_REQUIRED') }
                          : {}
                      }
                      render={({ field }) => (
                        <Select
                          {...field}
                          id='select-box'
                          defaultValue=''
                          required
                          sx={(theme) => ({
                            width: '80%',
                            '& .MuiSelect-icon': {
                              color: theme.palette.grey[500],
                            },
                          })}
                          displayEmpty
                        >
                          <MenuItem selected value='' disabled>
                            {t('LABEL.SELECT_PLATFORM')}
                          </MenuItem>
                          {listOfProcessor.length > 0 &&
                            listOfProcessor?.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    label={t('PLACEHOLDER.USER_NAME')}
                    value={formData?.userName}
                    disabled={openEditDialog}
                    required
                    sx={{
                      width: '80%',
                      backgroundColor: fieldColor,
                      '& .MuiFormHelperText-root': {
                        fontSize: '0.75rem',
                        backgroundColor: white,
                        margin: 0,
                        paddingLeft: '10px',
                      },
                    }}
                    {...register('userName', {
                      required: t('ERROR_MESSAGE.USERNAME_REQUIRED'),
                      pattern: {
                        value: CommonConstants.validation.alphaNumeric,
                        message: t('ERROR_MESSAGE.USERNAME_INVALID'),
                      },
                    })}
                    helperText={
                      errors.userName
                        ? (errors.userName as FieldError).message
                        : ''
                    }
                  />
                </Grid>
                {!openEditDialog && (
                  <Grid item xs={12}>
                    <TextField
                      label={t('PLACEHOLDER.USER_PASSWORD')}
                      type={showPassword ? 'text' : 'password'}
                      value={formData?.password}
                      fullWidth
                      required={openSaveDialog}
                      sx={{
                        width: '80%',
                        backgroundColor: fieldColor,
                        '& .MuiFormHelperText-root': {
                          fontSize: '0.75rem',
                          backgroundColor: white,
                          margin: 0,
                          paddingLeft: '10px',
                        },
                      }}
                      {...register('password', {
                        required: t('ERROR_MESSAGE.PASSWORD_REQUIRED'),
                      })}
                      helperText={
                        errors.password
                          ? (errors.password as FieldError).message
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label={
                                showPassword
                                  ? 'hide the password'
                                  : 'display the password'
                              }
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge='end'
                              sx={(theme) => ({
                                color: theme.palette.grey[500],
                              })}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                {openEditDialog && (
                  <Grid item xs={12}>
                    <TextField
                      label={t('PLACEHOLDER.CURRENT_PASSWORD')}
                      type={showPassword ? 'text' : 'password'}
                      value={formData?.currentPassword}
                      fullWidth
                      required={openEditDialog ?? false}
                      sx={{
                        width: '80%',
                        backgroundColor: fieldColor,
                        '& .MuiFormHelperText-root': {
                          fontSize: '0.75rem',
                          backgroundColor: white,
                          margin: 0,
                          paddingLeft: '10px',
                        },
                      }}
                      {...register('currentPassword', {
                        required: t('ERROR_MESSAGE.CURRENT_PASSWORD_REQUIRED'),
                        validate: validateCurrentPassword,
                      })}
                      helperText={
                        errors.currentPassword
                          ? (errors.currentPassword as FieldError).message
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label={
                                showPassword
                                  ? 'hide the password'
                                  : 'display the password'
                              }
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge='end'
                              sx={(theme) => ({
                                color: theme.palette.grey[500],
                              })}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                {openEditDialog && (
                  <Grid item xs={12}>
                    <TextField
                      label={t('PLACEHOLDER.NEW_PASSWORD')}
                      type={showNewPassword ? 'text' : 'password'}
                      value={formData?.newPassword}
                      fullWidth
                      required={openEditDialog ?? false}
                      sx={{
                        width: '80%',
                        backgroundColor: fieldColor,
                        '& .MuiFormHelperText-root': {
                          fontSize: '0.75rem',
                          backgroundColor: white,
                          margin: 0,
                          paddingLeft: '10px',
                        },
                      }}
                      {...register('newPassword', {
                        required: t('ERROR_MESSAGE.NEW_PASSWORD_REQUIRED'),
                        validate: validateNewPassword,
                      })}
                      helperText={
                        errors.newPassword
                          ? (errors.newPassword as FieldError).message
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label={
                                showNewPassword
                                  ? 'hide the password'
                                  : 'display the password'
                              }
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge='end'
                              sx={(theme) => ({
                                color: theme.palette.grey[500],
                              })}
                            >
                              {showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                {openEditDialog && (
                  <Grid item xs={12}>
                    <TextField
                      label={t('PLACEHOLDER.CONFIRM_PASSWORD')}
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={formData?.confirmPassword}
                      fullWidth
                      required={openEditDialog ?? false}
                      sx={{
                        width: '80%',
                        backgroundColor: fieldColor,
                        '& .MuiFormHelperText-root': {
                          fontSize: '0.75rem',
                          backgroundColor: white,
                          margin: 0,
                          paddingLeft: '10px',
                        },
                      }}
                      {...register('confirmPassword', {
                        required: t('ERROR_MESSAGE.CONFIRM_PASSWORD_REQUIRED'),
                        validate: validatePasswordsMatch,
                      })}
                      helperText={
                        errors.confirmPassword
                          ? (errors.confirmPassword as FieldError).message
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label={
                                showConfirmPassword
                                  ? 'hide the password'
                                  : 'display the password'
                              }
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge='end'
                              sx={(theme) => ({
                                color: theme.palette.grey[500],
                              })}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} textAlign='center'>
                  <ButtonComponent
                    variant='contained'
                    type='submit'
                    disabled={!isValid || isSubmitting}
                  >
                    {t('BUTTON.SAVE')}
                  </ButtonComponent>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
        {openCredentialsDialog && selectedDashboard && (
          <ViewCredentialsDialog
            open={openCredentialsDialog}
            onClose={() => setOpenCredentialsDialog(false)}
            dashboard={selectedDashboard}
          />
        )}
      </Grid>
      <PopupComponent
        open={openPopup}
        onClose={handleClosePopup}
        title={popUpTitle}
        label={popUpLabel}
        confirmAction={{
          text: t('BUTTON.DELETE'),
          action: confirmDeleteDashboard,
        }}
      />
      <PopupComponent
        open={openPopupError}
        onClose={handleClosePopupError}
        title={popUpTitleError}
      />
    </>
  );
};

export default DashboardView;
