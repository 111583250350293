import { createTheme, ThemeOptions } from '@mui/material/styles';
import themeOverrides from 'src/components/theme-overrides';

import {
  fontColor1,
  fontColor2,
  white,
  red,
  primaryColor,
  secondaryColor,
  disabledColor,
  blue,
  green,
  allFontsFamilies,
  mdFontSize,
  bgFontSize,
  smFontSize,
  xsFontSize,
  xlFontSize,
  disabledButtonBgColor,
} from 'src/assets/styles/variables';

const themeOptions: ThemeOptions = {
  typography: {
    allVariants: {
      fontFamily: allFontsFamilies,
      fontSize: mdFontSize,
    },
    h1: {
      color: white,
      fontFamily: 'Rubik',
      fontSize: xlFontSize,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    h2: {
      color: fontColor1,
      fontFamily: 'Rubik',
      fontSize: bgFontSize,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '32px',
      textAlign: 'center',
    },
    h3: {
      color: fontColor1,
      fontFamily: 'Rubik',
      fontSize: smFontSize,
      fontStyle: 'normal',
      fontWeight: 400,
      textAlign: 'center',
    },
    h4: {
      color: fontColor1,
      fontFamily: 'Rubik',
      fontSize: xsFontSize,
      fontStyle: 'normal',
      lineHeight: '20px',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: fontColor1,
      secondary: fontColor2,
      disabled: disabledColor,
    },
    background: {
      default: white,
      paper: '#f5f5f5',
    },
    error: {
      main: red,
    },
    action: {
      active: fontColor2,
      hoverOpacity: 0.08,
      selectedOpacity: 0.16,
      disabled: disabledColor,
      disabledBackground: disabledButtonBgColor,
      disabledOpacity: 0.38,
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    divider: '#aeaeae',
    info: {
      main: blue,
    },
    success: {
      main: green,
    },
    warning: {
      main: '#ffa726',
    },
  },
  components: themeOverrides,
};

const theme = createTheme(themeOptions);

export default theme;
