import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Tab, Tabs, TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import TableComponent from 'src/components/table/table.component';
import ButtonComponent from 'src/components/button/button.component';
import PopupComponent from 'src/components/popup/popup-component.component';
import Loader from 'src/components/common/loader/loader.component';
import { TableColumnData, TableRowData } from 'src/types/table.model';
import classNames from 'classnames';
import styles from './manage-client-admins.module.scss';

interface ManageClientAdminsVIEWProps {
  activeTab: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  columns: TableColumnData<any>[];
  rows: TableRowData[];
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  onDeleteAdmin: () => void;
  onDeleteInvitation: () => void;
  selectedRows: string[];
  setSelectedRows: Dispatch<SetStateAction<string[]>>;
  openPopUp: boolean;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  popUpText: string;
  confirmAction: any;
  onClose: () => void;
  cancelDelete: () => void;
  paramMerchantId?: string;
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
  loading: boolean;
  generalLoading: boolean;
  totalRecords: number;
}

const ManageClientAdminsVIEW: FC<ManageClientAdminsVIEWProps> = ({
  activeTab,
  onTabChange,
  columns,
  rows,
  onSearchChange,
  setSearchText,
  searchText,
  onDeleteAdmin,
  onDeleteInvitation,
  selectedRows,
  setSelectedRows,
  openPopUp,
  setOpenPopup,
  popUpText,
  confirmAction,
  onClose,
  cancelDelete,
  paramMerchantId,
  page,
  rowsPerPage,
  onPageChange,
  loading,
  generalLoading,
  totalRecords,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.layout_main_container, {
        [styles.super_admin]: true,
      })}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={activeTab}
          variant='fullWidth'
          onChange={onTabChange}
          aria-label='Manage Admins'
          centered
          sx={{ paddingBottom: '14px', width: '70%' }}
        >
          <Tab
            label={t('LABEL.MANAGE_STATUS')}
            sx={{
              textTransform: 'none',
              borderBottom: '1px solid grey',
              width: '50%',
              textAlign: 'center',
            }}
          />
          <Tab
            label={t('LABEL.MANAGE_INVITATIONS')}
            sx={{
              textTransform: 'none',
              borderBottom: '1px solid grey',
              width: '50%',
              textAlign: 'center',
            }}
          />
        </Tabs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: '10px',
          pb: '20px',
        }}
      >
        <TextField
          label={t('PLACEHOLDER.TABLE_SEARCH')}
          value={paramMerchantId || searchText}
          onChange={onSearchChange}
          variant='outlined'
          size='small'
          sx={{
            width: '300px',
          }}
          disabled={!!paramMerchantId}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {searchText ? (
                  <CloseIcon
                    onClick={() => setSearchText('')}
                    color={!!paramMerchantId ? 'disabled' : 'action'}
                    sx={{
                      cursor: !!paramMerchantId ? 'not-allowed' : 'pointer',
                    }}
                  />
                ) : (
                  <SearchIcon
                    color={!!paramMerchantId ? 'disabled' : 'action'}
                    sx={{
                      cursor: !!paramMerchantId ? 'not-allowed' : 'pointer',
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box>
          <ButtonComponent
            variant='outlined'
            color='secondary'
            sx={{
              padding: '6px 10px',
              fontSize: '0.80rem',
              minWidth: 'auto',
            }}
            onClick={activeTab === 0 ? onDeleteAdmin : onDeleteInvitation}
            disabled={selectedRows.length === 0}
          >
            {t('BUTTON.DELETE')}
          </ButtonComponent>
        </Box>
      </Box>

      <TableComponent
        columns={columns}
        rows={rows}
        withCheckBox
        selectedRows={selectedRows}
        onRowSelect={setSelectedRows}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
        loading={loading}
        totalRecords={totalRecords}
      />

      {!!paramMerchantId && (
        <Box mt={4} display='flex' justifyContent='center'>
          <ButtonComponent variant='outlined' color='primary' onClick={onClose}>
            {t('LABEL.CLOSE')}
          </ButtonComponent>
        </Box>
      )}
      <PopupComponent
        open={openPopUp}
        onClose={cancelDelete}
        title={popUpText}
        confirmAction={confirmAction}
      />
      <Loader loading={generalLoading} />
    </div>
  );
};

export default ManageClientAdminsVIEW;
