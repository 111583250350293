import React from 'react';
import JoinMerchantView from './join-merchant.view';

const JoinMerchant: React.FC = () => {
  return (
    <div>
      <JoinMerchantView />
    </div>
  );
};

export default JoinMerchant;
