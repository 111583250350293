import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Switch, Typography } from '@mui/material';

import MerchantAdminService from 'src/containers/merchant-admin/merchant-admin.service';
import ButtonComponent from 'src/components/button/button.component';
import { superAdminSliceAction } from '../super-admin-reducer';
import ManageClientAdminsVIEW from './manage-client-admins.view';
import { TableColumnData, TableRowData } from 'src/types/table.model';
import UtilityService from 'src/services/utlits.service';
import ActiveClientServices from '../assign-admin-to-client-id/assign-admin-to-clientId.services';
import { useNavigate } from 'react-router-dom';

export interface ClientAdminRow extends TableRowData {
  admin: string;
  merchantClientId: string;
  companyName: string;
  email?: string;
  status: boolean;
  invitation?: string;
  winkTag?: string;
}

interface MerchantAdmin {
  winkTag: string;
  adminStatus: number;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  merchantClientId: string;
  merchantId: string;
  invitedBy?: string;
  requestToken?: string;
  clientUserId?: number;
}

const ManageClientAdmins: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const merchantSelected = location.state?.merchantSelected;
  const pathTo = location.state?.to;
  const paramMerchantId = merchantSelected?.merchantClientId || '';

  const [activeTab, setActiveTab] = useState(
    pathTo === 'invitations' ? 1 : pathTo === 'status' ? 0 : 0
  );
  const [searchText, setSearchText] = useState(paramMerchantId);
  const [rows, setRows] = useState<ClientAdminRow[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const [isMerchantFixed] = useState(!!paramMerchantId);
  const [popUpText, setPopUpText] = useState('');
  const [openPopUp, setOpenPopup] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<'confirmation' | 'feedback'>(
    'confirmation'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    if (!isMerchantFixed) {
      const handler = setTimeout(() => {
        setDebouncedSearchText(searchText);
        setPage(1);
      }, 500);
      return () => clearTimeout(handler);
    }
  }, [searchText, isMerchantFixed]);

  const fetchData = useCallback(
    async (currentPage: number) => {
      setIsLoading(true);
      try {
        const query = isMerchantFixed ? paramMerchantId : debouncedSearchText;
        setRows([]);
        if (query || !isMerchantFixed) {
          if (activeTab === 0) {
            const response = await MerchantAdminService.merchantAdmins(
              query || '',
              currentPage,
              rowsPerPage
            );

            if (response && response.merchantAdmins) {
              const apiRows = response.merchantAdmins.map(
                (admin: MerchantAdmin) => ({
                  id: UtilityService.generateUniqueId(),
                  admin: `${admin.firstName} ${admin.lastName}`,
                  merchantClientId: admin.merchantClientId,
                  companyName: admin.companyName,
                  email: admin.email,
                  status: admin.adminStatus === 0,
                  winkTag: admin.winkTag,
                  clientUserId: admin.clientUserId,
                })
              );
              setRows(apiRows);
              setTotalRecords(response.totalRecords || 0);
            }
          } else if (activeTab === 1) {
            const response = await MerchantAdminService.merchantInvitations(
              query || '',
              currentPage,
              rowsPerPage
            );

            if (response && response.merchantsInvitedAdmins) {
              const apiRows = response.merchantsInvitedAdmins.map(
                (invitation: MerchantAdmin) => ({
                  id: UtilityService.generateUniqueId(),
                  merchantId: invitation.merchantId,
                  admin: invitation.email || invitation.winkTag,
                  merchantClientId: invitation.merchantClientId,
                  companyName: invitation.companyName,
                  status: invitation.adminStatus === 0,
                  invitation: invitation.invitedBy,
                  winkTag: invitation.winkTag,
                  requestToken: invitation.requestToken,
                })
              );
              setTotalRecords(response.totalRecords || 0);
              setRows(apiRows);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      activeTab,
      debouncedSearchText,
      isMerchantFixed,
      paramMerchantId,
      rowsPerPage,
    ]
  );

  useEffect(() => {
    if (debouncedSearchText !== searchText) return;
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearchText, fetchData]);

  useEffect(() => {
    dispatch(superAdminSliceAction.setheadingName(t('LABEL.MERCHANT_ADMINS')));
  }, [dispatch, t]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedRows([]);
    setActiveTab(newValue);
    setPage(1);
    setTotalRecords(0);
  };

  const handlePageChange = (newPage: number) => setPage(newPage);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleDeleteInvitation = async () => {
    setOpenPopup(true);
    setPopUpText('You are deleting an invitation');
    setPopupType('confirmation');
  };

  const handleDeleteAdmin = async () => {
    setOpenPopup(true);
    setPopUpText('You are deleting an admin');
    setPopupType('confirmation');
  };

  const cancelDelete = () => {
    setOpenPopup(false);
    setPopUpText('');
  };

  const confirmDeleteInvitation = async () => {
    try {
      setGeneralLoading(true);

      const requestTokens = rows
        .filter((row) => selectedRows.includes(row.id))
        .map((row) => row.requestToken)
        .filter((token) => token !== null);

      const result = await MerchantAdminService.deleteAdminInvitation({
        requestTokens,
      });

      if (result?.data?.isSuccess) {
        setRows((prevRows) =>
          prevRows.filter((row) => !selectedRows.includes(row.id))
        );
        setPopUpText('Invitation successfully deleted!');
        setPopupType('feedback');
        setOpenPopup(true);
      } else {
        console.error('Some rows could not be deleted.');
        setPopUpText('Failed to delete invitation.');
        setPopupType('feedback');
        setOpenPopup(true);
      }
    } catch (error) {
      console.error('Error deleting selected rows:', error);
      setPopUpText('Error deleting invitation.');
      setPopupType('feedback');
      setOpenPopup(true);
    } finally {
      setSelectedRows([]);
      setGeneralLoading(false);
    }
  };

  const confirmDeleteAdmin = async () => {
    try {
      setGeneralLoading(true);

      const adminIdsToDelete = rows
        .filter((row) => selectedRows.includes(row.id))
        .map((row) => row.clientUserId)
        .filter((id): id is number => id !== undefined);

      const result = await MerchantAdminService.deleteAdmin({
        clientUserIds: adminIdsToDelete,
      });

      if (result?.data?.isSuccess) {
        setRows((prevRows) =>
          prevRows.filter((row) => !selectedRows.includes(row.id))
        );
        setPopUpText('Admin successfully deleted!');
        setPopupType('feedback');
        setOpenPopup(true);
      } else {
        console.error('Some admins could not be deleted.');
        setPopUpText('Failed to delete admin.');
        setPopupType('feedback');
        setOpenPopup(true);
      }
    } catch (error) {
      console.error('Error deleting selected admins:', error);
      setPopUpText('Error deleting admin.');
      setPopupType('feedback');
      setOpenPopup(true);
    } finally {
      setSelectedRows([]);
      setGeneralLoading(false);
    }
  };

  const handleResendInvite = async (row: ClientAdminRow) => {
    setPopupType('feedback');
    try {
      setGeneralLoading(true);
      const { merchantId, winkTag, admin: email } = row;
      const contactInfo = winkTag ? [winkTag] : [email];
      const response = await ActiveClientServices.sendInvitation(
        merchantId,
        winkTag ? [] : contactInfo,
        winkTag ? contactInfo : []
      );

      if (response.isSuccess && response.isEmailSent) {
        setOpenPopup(true);
        setPopUpText('Invitation successfully resent');
      } else {
        console.error('Failed to resend invitation:', response.message);
        setPopUpText('Failed to resend invitation');
      }
    } catch (error) {
      setPopUpText('Error re-sending invite');
      console.error('Error re-sending invite:', error);
    } finally {
      setGeneralLoading(false);
    }
  };

  const handleSwitchChange = async (row: ClientAdminRow) => {
    const newStatus = !row.status;
    try {
      setGeneralLoading(true);

      const response = await MerchantAdminService.updateAdminStatus({
        merchantClientId: row.merchantClientId,
        winkTag: row.winkTag || '',
        adminStatus: newStatus ? 0 : 1,
      });

      if (response?.status === 200) {
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id ? { ...r, status: newStatus } : r
          )
        );
      } else {
        console.error(
          `Failed to update status for: ${row.merchantClientId}. HTTP status: ${response?.status}`
        );
      }
    } catch (error) {
      console.error('Error updating admin status:', error);
    } finally {
      setGeneralLoading(false);
    }
  };

  const statusColumns: TableColumnData<ClientAdminRow>[] = [
    {
      id: 'admin',
      label: t('TABLE.COLUMN_ADMIN'),
      sort: true,
      withSquareColor: true,
    },
    {
      id: 'merchantClientId',
      label: t('TABLE.COLUMN_MERCHANTID'),
      sort: true,
    },
    { id: 'companyName', label: t('TABLE.COLUMN_COMPANY_NAME'), sort: true },
    { id: 'email', label: t('TABLE.COLUMN_EMAIL'), sort: true },
    {
      id: 'adminStatus',
      label: t('TABLE.COLUMN_ADMIN_STATUS'),
      align: 'center',
      render: (row) => (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          width='100%'
        >
          <Typography
            sx={{
              color: 'gray',
              fontWeight: 'bold',
              marginRight: '8px',
            }}
          >
            {t('LABEL.DISABLED')}
          </Typography>
          <Switch
            checked={row.status}
            onChange={() => handleSwitchChange(row)}
            color='primary'
          />

          <Typography
            sx={{
              color: row.status ? '#676C76' : 'gray',
              fontWeight: 'bold',
              marginLeft: '8px',
            }}
          >
            {t('LABEL.ACTIVE')}
          </Typography>

          <Box
            sx={{
              background: row.status
                ? 'linear-gradient(90deg, #22b2c3 0%, #5bd2cf 100%)'
                : 'linear-gradient(90deg, #E4318B 0%, #9042B8 100%)',
              height: '25px',
              width: '100px',
              textAlign: 'center',
              borderRadius: '5px',
              color: 'white',
              marginLeft: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {row.status ? t('LABEL.ACTIVE') : t('LABEL.DISABLED')}
          </Box>
        </Box>
      ),
    },
  ];

  const invitationColumns: TableColumnData<ClientAdminRow>[] = [
    {
      id: 'admin',
      label: t('TABLE.COLUMN_INVITED_USER'),
      sort: true,
      withSquareColor: true,
    },
    { id: 'merchantClientId', label: t('TABLE.COLUMN_MERCHANTID'), sort: true },
    { id: 'companyName', label: t('TABLE.COLUMN_COMPANY_NAME'), sort: true },
    { id: 'invitation', label: t('TABLE.COLUMN_INVITED_BY'), sort: true },
    {
      id: 'status',
      label: t('TABLE.STATUS'),
      align: 'center',
      render: () => (
        <Box
          sx={{
            background: 'linear-gradient(90deg, #FFB74D 0%, #E64A19 100%)',
            height: '25px',
            textAlign: 'center',
            borderRadius: '5px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 10px',
          }}
        >
          {t('LABEL.PENDING')}
        </Box>
      ),
    },
    {
      id: 'action',
      label: '',
      align: 'center',
      render: (row: ClientAdminRow) => (
        <ButtonComponent
          variant='contained'
          color='primary'
          gradient
          onClick={() => handleResendInvite(row)}
          sx={{
            marginRight: '10px',
            padding: '6px 10px',
            fontSize: '0.80rem',
            minWidth: 'auto',
          }}
        >
          {t('BUTTON.RE_SEND_INVITATION')}
        </ButtonComponent>
      ),
    },
  ];

  const backToSettings = () => {
    navigate('/active-merchants');
    dispatch(superAdminSliceAction.openSettingsModal(merchantSelected));
  };

  return (
    <ManageClientAdminsVIEW
      activeTab={activeTab}
      onTabChange={handleTabChange}
      columns={activeTab === 0 ? statusColumns : invitationColumns}
      rows={rows}
      onSearchChange={handleSearchChange}
      setSearchText={setSearchText}
      searchText={searchText}
      onDeleteInvitation={handleDeleteInvitation}
      onDeleteAdmin={handleDeleteAdmin}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      openPopUp={openPopUp}
      setOpenPopup={setOpenPopup}
      popUpText={popUpText}
      onClose={backToSettings}
      paramMerchantId={paramMerchantId}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={handlePageChange}
      loading={isLoading}
      generalLoading={generalLoading}
      totalRecords={totalRecords}
      cancelDelete={cancelDelete}
      confirmAction={
        popupType === 'confirmation'
          ? {
              text: t('BUTTON.DELETE'),
              action:
                activeTab === 0 ? confirmDeleteAdmin : confirmDeleteInvitation,
            }
          : undefined
      }
    />
  );
};

export default ManageClientAdmins;
