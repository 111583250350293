import React from 'react';
import HeaderComponent from '../common/header/header.component';
import SideDrawer from '../sidedrawer/sidedrawer.component';

const LayoutView: React.FC = () => (
  <>
    <HeaderComponent />
    <SideDrawer />
  </>
);

export default LayoutView;
