import { httpClient } from 'src/services/http.service';

import CommonConstants from 'src/constants/common.constant';
import MerchantDashboard from './dashboard.model';

const client = httpClient(true);
class DashboardServices {
  static getPaymentProcessorList = async () => {
    return client.get(
      CommonConstants.api.listOfPaymentProcessor,
      null,
      MerchantDashboard.processorList,
    );
  };
  static getFraudProcessorList = async () => {
    return client.get(
      CommonConstants.api.listOfFraudMProcessor,
      null,
      MerchantDashboard.processorList,
    );
  };
  static saveThirdPartyProcessor = async (data: any) => {
    return client.post(
      CommonConstants.api.saveThirdPartyProcessor,
      data,
      MerchantDashboard.processorList,
    );
  };
  static updateThirdPartyProcessor = async (id: any, data: any) => {
    return client.put(
      `${CommonConstants.api.thirdPartyCredential}/${id}`,
      data,
      MerchantDashboard.processorList,
    );
  };
  static getThirdPartyCredential = async (
    thirdPartyProcessorType: any,
    thirdPartyIntegrationType: any,
  ) => {
    return client.get(
      `${CommonConstants.api.getThirdPartyCredential}?thirdPartyProcessorType=${thirdPartyProcessorType}&thirdPartyIntegrationType=${thirdPartyIntegrationType}`,
      '',
      MerchantDashboard.processorList,
    );
  };
  static deleteThirdPartyCredential = async (id: string) => {
    return client.delete(
      `${CommonConstants.api.thirdPartyCredential}/${id}`,
      '',
      MerchantDashboard.processorList,
    );
  };
}

export default DashboardServices;
