import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './page404.module.scss';
const ErrorHandler: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div role='alert'>
        <div className={style.maindiv}>
          <h1>{t('LABEL.SOMETHING_WRONG')}</h1>
          <p>{t('LABEL.TRY_AGAIN')}</p>
        </div>
      </div>
    </>
  );
};

export default ErrorHandler;
