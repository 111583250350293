import { createSlice } from '@reduxjs/toolkit';

interface SuperAdminState {
  headingName: string;
  loading: boolean;
  environment: string;
  superAdminStatisticCount: {
    activeClientIdsCount: number;
    activeAdminsCount: number;
    newRequestsCount: number;
  };
  isSettingsModalOpen: boolean;
  merchantSelected: { [key: string]: any } | undefined;
  merchantList: {};
  rowActiveMerchantsColors: Record<string, string>;
}

const initialState: SuperAdminState = {
  headingName: 'Dashboard',
  loading: false,
  environment: 'Sandbox',
  superAdminStatisticCount: {
    activeClientIdsCount: 0,
    activeAdminsCount: 0,
    newRequestsCount: 0,
  },
  isSettingsModalOpen: false,
  merchantSelected: undefined,
  merchantList: {},
  rowActiveMerchantsColors: {},
};

const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    setheadingName(state, { payload }) {
      state.headingName = payload;
    },
    setSuperAdminLoading(state, { payload }) {
      state.loading = payload;
    },
    setSuperAdminStatisticCount(state, { payload }) {
      state.superAdminStatisticCount = payload;
    },
    setEnvironment(state, { payload }) {
      state.environment = payload;
    },
    openSettingsModal: (state, { payload }) => {
      state.isSettingsModalOpen = true;
      state.merchantSelected = payload;
    },
    closeSettingsModal: (state) => {
      state.isSettingsModalOpen = false;
    },
    setMerchantList: (state, { payload }) => {
      state.merchantList = payload;
    },
    setMerchantSelected: (state, { payload }) => {
      state.merchantSelected = payload;
    },
    setRowColorActiveMerchants: (state, { payload }) => {
      const { rowId, color } = payload;
      state.rowActiveMerchantsColors[rowId] = color;
    },
  },
});

const { actions } = superAdminSlice;
export const superAdminSliceAction = actions;
export default superAdminSlice.reducer;
