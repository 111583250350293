import yup from 'src/services/validator.service';
class MerchantModel {
  static readonly activeMerchantList = yup
    .object({
      merchantClientId: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
      companyName: yup.string(),
      phoneNumber: yup.string(),
      email: yup.string().nullable(),
      requestType: yup.number(),
      merchantId: yup.string(),
      merchantStatus: yup.string(),
      applicationAccessType: yup.number(),
    })
    .defined();

  static readonly winkTagList = yup
    .object({
      message: yup.string().nullable(),
      pageNumber: yup.number(),
      totalRecords: yup.number(),
      pageSize: yup.number(),
      data: yup.array().of(
        yup.object({
          userId: yup.number(),
          winkTag: yup.string().defined(),
        }),
      ),
    })
    .defined();

  static readonly joinMerchant = yup
    .object({
      isSuccess: yup.boolean().nullable(),
      message: yup.boolean().nullable(),
    })
    .defined();

  static readonly merchantAdmin = yup
    .object({
      message: yup.string().nullable(),
      pageNumber: yup.number(),
      totalRecords: yup.number(),
      pageSize: yup.number(),
      merchantAdmins: yup.array().of(
        yup.object({
          winkTag: yup.string(),
          adminStatus: yup.number(),
          firstName: yup.string(),
          lastName: yup.string(),
          email: yup.string(),
          companyName: yup.string(),
          merchantClientId: yup.string(),
        }),
      ),
    })
    .defined();

  static readonly merchantAdminInvitations = yup
    .object({
      message: yup.string().nullable(),
      isSuccess: yup.boolean(),
      pageNumber: yup.number(),
      totalRecords: yup.number(),
      pageSize: yup.number(),
      invitations: yup.array().of(
        yup.object({
          winkTag: yup.string(),
          adminStatus: yup.number(),
          firstName: yup.string(),
          lastName: yup.string(),
          email: yup.string(),
          companyName: yup.string(),
          merchantClientId: yup.string(),
        }),
      ),
    })
    .defined();
}
export default MerchantModel;
