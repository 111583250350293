import React from 'react';
import ApproveRequestView from './approve-request-view';
import { useSuperAdminInfo } from 'src/hooks/useIsSuperAdmin';
import styles from './approve-request.module.scss';
import classNames from 'classnames';

const ApproveRequest: React.FC = () => {
  const { containerClassName } = useSuperAdminInfo();

  return (
    <div
      className={classNames(styles.layout_main_container, {
        [styles.super_admin]: containerClassName.includes('super-admin'),
      })}
    >
      <ApproveRequestView />
    </div>
  );
};

export default ApproveRequest;
