import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { superAdminSliceAction } from '../super-admin-reducer';
// import ApproveRequest from '../../../containers/super-admin/admin-requests/approve-request-component';
import ActiveClientList from './active-client-list/active-client-list.component';
import { useTranslation } from 'react-i18next';

const ViewAllClientIds: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(superAdminSliceAction.setheadingName(t('LABEL.ACTIVE_MERCHANTS')));
  }, [dispatch, t]);

  return <ActiveClientList />;
};

export default ViewAllClientIds;
