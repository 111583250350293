import yup from 'src/services/validator.service';

class MerchantProfileModel {
  static readonly getProfile = yup.object().shape({
    merchantProfileDetails: yup.object().shape({
      merchant: yup.object().shape({
        firstName: yup.string().nullable(),
        lastName: yup.string().nullable(),
        companyName: yup.string().nullable(),
        email: yup.string().email().nullable(),
        phoneNumber: yup.string().nullable(),
        webURL: yup.string().nullable(),
        applicationDomainURL: yup.string().nullable(),
        redirectUris: yup.array().of(yup.string().nullable()),
      }),
      merchantAddress: yup.object().nullable(),
      merchantAdditionalInfo: yup.mixed().nullable(),
    }),
    message: yup.string().nullable(),
  });

  static readonly putProfile = yup.object().shape({
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    email: yup.string().email().nullable(),
    phoneNumber: yup.string().nullable(),
    companyName: yup.string().nullable(),
    streetAddress: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    postalCode: yup.string().nullable(),
    webURL: yup.string().url().nullable(),
    applicationDomainURL: yup.string().url().nullable(),
    applicationAccessType: yup.number().nullable(),
    redirectUris: yup.array().of(yup.string().nullable()),
    paymentGateway: yup.string().nullable(),
    developmentEnvironment: yup.string().nullable(),
    comments: yup.string().nullable(),
    country: yup.string().nullable(),
  });

  static readonly merchantProfileResponse = yup
    .object({
      merchantClientId: yup.string(),
      isSuccess: yup.boolean(),
      message: yup.string().nullable(),
    })
}
export default MerchantProfileModel;
