import { Components, Theme } from '@mui/material/styles';
import TextFieldOverrides from './text-field/theme';
import ButtonOverrides from './button/theme';
import LinkOverrides from './link/theme';
import RadioGroupOverrides from './radio-group/theme';
import TabsOverrides from './common/tabs/theme';
import TableOverrides from './table/theme';

const themeOverrides: Components<Theme> = {
  ...TextFieldOverrides,
  ...ButtonOverrides,
  ...LinkOverrides,
  ...RadioGroupOverrides,
  ...TabsOverrides,
  ...TableOverrides,
};

export default themeOverrides;
