import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Grid,
} from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { superAdminSliceAction } from '../../super-admin-reducer';

import ActiveClientServices from './active-client-list.services';
import MerchantAdminService from 'src/containers/merchant-admin/merchant-admin.service';

import TableComponent from 'src/components/table/table.component';
import Loader from 'src/components/common/loader/loader.component';
import { HandleRowClickType } from './active-client-list.component';

import { TableColumnData, TableRowData } from 'src/types/table.model';
import { disabledButtonColor, lightGreen } from 'src/assets/styles/variables';
import CommonConstants from 'src/constants/common.constant';
import style from './active-client-list.module.scss';
import UtilityService from 'src/services/utlits.service';

interface ActiveClientListProps {
  isFetching: boolean;
  handleRowClick: HandleRowClickType;
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
  setGeneralLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  generalLoading: boolean;
}

interface ClientAdminRow extends TableRowData {
  merchantClientId: string;
  name: string;
  company: string;
  mobile: string;
  disabled: boolean;
  merchantStatus: number;
  requestType: number;
  merchantId: string;
  applicationAccessType: number;
}

interface StatusSquareProps {
  status: string;
  isAdmin?: boolean;
}

const StatusSquare: React.FC<StatusSquareProps> = React.memo(
  ({ status, isAdmin }) => (
    <Box
      sx={{
        background: isAdmin
          ? status !== 'Active'
            ? 'linear-gradient(90deg, #E4318B 0%, #9042B8 100%)'
            : 'linear-gradient(90deg, #22b2c3 0%, #5bd2cf 100%)'
          : status === 'Requested'
            ? 'linear-gradient(90deg, #E4318B 0%, #9042B8 100%)'
            : 'linear-gradient(90deg, #22b2c3 0%, #5bd2cf 100%)',
        height: '25px',
        width: '100px',
        textAlign: 'center',
        alignContent: 'center',
        borderRadius: '5px',
        color: 'white',
      }}
    >
      {isAdmin ? status : status === 'Requested' ? 'Pending' : status}
    </Box>
  )
);

const ActiveClientListView: React.FC<ActiveClientListProps> = ({
  setIsFetching,
  isFetching,
  generalLoading,
  setShouldOpenDrawer,
  handleRowClick,
}) => {
  const [clientData, setClientData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userDataRedux = useSelector((state: any) => state.winkLogin.userData);
  const superAdmin =
    userDataRedux.userType === CommonConstants.userType.superAdmin;
  const settingsModalIsOpen = useSelector(
    (state: any) => state.superAdmin.isSettingsModalOpen
  );
  const merchantList = useSelector(
    (state: any) => state.superAdmin.merchantList
  );
  const rowColors = useSelector(
    (state: any) => state.superAdmin.rowActiveMerchantsColors
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);
    return () => clearTimeout(handler);
  }, [searchText]);

  useEffect(() => {
    dispatch(superAdminSliceAction.setheadingName(t('LABEL.APPROVED_REQUEST')));
  }, [dispatch, t]);

  const fetchClientData = useCallback(
    async (currentPage: number, pageSize: number) => {
      setIsFetching(true);
      try {
        let allClientData;
        if (superAdmin) {
          allClientData = await ActiveClientServices.getAllActiveClient(
            debouncedSearchText,
            currentPage,
            pageSize
          );
        } else {
          allClientData = await MerchantAdminService.activeMerchant();
        }

        const fetchedData = superAdmin
          ? allClientData?.clientList
          : allClientData?.merchants;

        fetchedData.forEach((row: any) => {
          if (!rowColors[row.merchantId]) {
            const color = UtilityService.generateLinearGradient();
            dispatch(
              superAdminSliceAction.setRowColorActiveMerchants({
                rowId: row.merchantId,
                color,
              })
            );
          }
        });

        setClientData(fetchedData || []);

        setTotalRecords(allClientData?.totalRecords || 0);
        dispatch(superAdminSliceAction.setMerchantList(fetchedData || []));
      } finally {
        setIsFetching(false);
      }
    },
    [setIsFetching, superAdmin, dispatch, debouncedSearchText, rowColors]
  );

  useEffect(() => {
    if (!isFetching && !settingsModalIsOpen) {
      fetchClientData(page, rowsPerPage);
    } else {
      merchantList.length > 0 && setClientData(merchantList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, debouncedSearchText]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPage(1);
  };

  const clientRows: ClientAdminRow[] = useMemo(
    () =>
      clientData?.map((val: any, index: any) =>
        Object.fromEntries([
          ['id', index.toString()],
          ['merchantClientId', val.merchantClientId ?? ''],
          ['name', `${val.firstName} ${val.lastName}`],
          ['company', val.companyName ?? ''],
          ['email', val.email ?? ''],
          ['product', val.applicationAccessType ?? null],
          ['disabled', false],
          ['status', val.merchantStatus],
          ['requestType', val.requestType],
          ['merchantId', val.merchantId],
        ])
      ) as ClientAdminRow[],
    [clientData, superAdmin]
  );

  const adminColumns: TableColumnData<ClientAdminRow>[] = useMemo(
    () => [
      {
        id: 'merchantClientId',
        label: t('TABLE.COLUMN_MERCHANTID'),
        withSquareColor: true,
        sort: true,
        minWidth: 210,
        align: 'left',
      },
      { id: 'name', label: t('TABLE.COLUMN_NAME'), sort: true },
      { id: 'company', label: t('TABLE.COLUMN_COMPANY'), sort: true },
      { id: 'email', label: t('TABLE.COLUMN_EMAIL'), sort: true },
      {
        id: 'product',
        label: t('TABLE.COLUMN_PRODUCT'),
        sort: true,
        render: (row) => (
          <Box sx={{ alignContent: 'center' }}>
            {row.product === CommonConstants.serviceType.login &&
              t('PLACEHOLDER.USER_SERVICE_TYPE_WL')}
            {row.product === CommonConstants.serviceType.payment &&
              t('PLACEHOLDER.USER_SERVICE_TYPE_WP')}
            {row.product === CommonConstants.serviceType.both &&
              t('PLACEHOLDER.USER_SERVICE_TYPE_B')}
          </Box>
        ),
      },
      {
        id: 'status',
        label: t('TABLE.STATUS'),
        align: 'center',
        sort: true,
        render: (row) => <StatusSquare status={row.status} isAdmin={true} />,
      },
      {
        id: 'actions',
        label: '',
        align: 'center',
        render: (row) => (
          <Box>
            <IconButton
              disabled={row?.status === CommonConstants.status.pending}
              onClick={() => {
                setShouldOpenDrawer(false);
                dispatch(superAdminSliceAction.openSettingsModal(row));
              }}
            >
              <SettingsOutlinedIcon
                sx={{
                  color:
                    row?.status === CommonConstants.status.pending
                      ? disabledButtonColor
                      : lightGreen,
                }}
              />
            </IconButton>
          </Box>
        ),
      },
    ],
    [dispatch, setShouldOpenDrawer, t]
  );

  const clientColumns: TableColumnData<ClientAdminRow>[] = useMemo(
    () => [
      {
        id: 'merchantClientId',
        label: t('TABLE.COLUMN_MERCHANTID'),
        withSquareColor: true,
        sort: true,
        minWidth: 210,
      },
      { id: 'name', label: t('TABLE.COLUMN_NAME'), sort: true },
      { id: 'company', label: t('TABLE.COLUMN_COMPANY'), sort: true },
      { id: 'email', label: t('TABLE.COLUMN_EMAIL'), sort: true },
      {
        id: 'product',
        label: t('TABLE.COLUMN_PRODUCT'),
        sort: true,
        render: (row) => (
          <Box style={{ alignContent: 'center' }}>
            {row.product === CommonConstants.serviceType.login &&
              t('PLACEHOLDER.USER_SERVICE_TYPE_WL')}
            {row.product === CommonConstants.serviceType.payment &&
              t('PLACEHOLDER.USER_SERVICE_TYPE_WP')}
            {row.product === CommonConstants.serviceType.both &&
              t('PLACEHOLDER.USER_SERVICE_TYPE_B')}
          </Box>
        ),
      },
      {
        id: 'status',
        label: t('TABLE.STATUS'),
        sort: true,
        render: (row) => <StatusSquare status={row.status} isAdmin={false} />,
      },
      {
        id: 'actions',
        label: '',
        align: 'center',
        render: (row) => (
          <Box>
            <IconButton
              disabled={row.status === 'Requested'}
              onClick={() => {
                setShouldOpenDrawer(false);
                dispatch(superAdminSliceAction.openSettingsModal(row));
              }}
            >
              <SettingsOutlinedIcon
                sx={{
                  color:
                    row.status === 'Requested'
                      ? disabledButtonColor
                      : lightGreen,
                }}
              />
            </IconButton>
          </Box>
        ),
      },
    ],
    [dispatch, setShouldOpenDrawer, t]
  );

  return (
    <>
      <Loader loading={generalLoading} />
      <Box className={style.header}>
        <Grid container alignItems='center'>
          <Grid item xs={12} md={4}>
            {/* Empy column to center content */}
          </Grid>
          <Grid item xs={12} md={4} className={style.headerContent}>
            <Typography variant='h2' className={style.headerTitle}>
              {superAdmin ? t('LABEL.ACTIVE_MERCHANTS') : t('LABEL.MERCHANTS')}
            </Typography>
          </Grid>
          {superAdmin && (
            <Grid item xs={12} md={4} className={style.searchContainer}>
              <TextField
                label={t('PLACEHOLDER.TABLE_SEARCH')}
                value={searchText}
                onChange={handleSearchChange}
                variant='outlined'
                size='small'
                sx={{ width: '100%', maxWidth: '300px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {searchText ? (
                        <CloseIcon onClick={() => setSearchText('')} />
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
        </Grid>

        <hr className={style.divider} />
      </Box>
      {isFetching ? (
        <TableComponent
          columns={superAdmin ? adminColumns : clientColumns}
          rows={[]}
          loading={true}
          page={0}
          rowsPerPage={0}
          onPageChange={() => {}}
          totalRecords={0}
        />
      ) : clientRows.length > 0 ? (
        <TableComponent
          columns={superAdmin ? adminColumns : clientColumns}
          totalRecords={totalRecords}
          rows={clientRows}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          withCheckBox={false}
          onRowClick={(row) => handleRowClick(row)}
        />
      ) : (
        <Box className={style.noMerchantBox}>
          <Typography className={style.noMerchant}>
            {t('MESSAGE.NO_ACTIVE_MERCHANT')}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ActiveClientListView;
