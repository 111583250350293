import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux-store/store';
import Loader from 'src/components/common/loader/loader.component';
import CommonConstants from 'src/constants/common.constant';
import MerchantProfileServices from './merchant-profile.services';
import { superAdminSliceAction } from '../super-admin/super-admin-reducer';
import MerchantProfileView from './merchant-profile.view';
import { useTranslation } from 'react-i18next';

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  webURL: string;
  applicationDomainURL: string;
  redirectDomain: string;
  productType: string;
  redirectUris: { url: string }[];
  naChecked: boolean;
}

const MerchantProfile: React.FC = () => {
  const [merchantProfile, setMerchantProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [openPopUp, setOpenPopup] = useState<boolean>(false);
  const [profileUpdated, setProfileUpdated] = useState<boolean>(false);

  const { t } = useTranslation();
  const userDataState = useSelector((state: any) => state.winkLogin.userData);
  const isSuperAdmin =
    userDataState?.userType === CommonConstants.userType.superAdmin;

  const merchantSelected = useSelector(
    (state: RootState) => state.superAdmin.merchantSelected,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMerchantProfile = async () => {
      setLoading(true);
      try {
        const { merchantProfileDetails } =
          await MerchantProfileServices.getMerchantProfile();
        if (
          merchantProfileDetails.merchant &&
          merchantProfileDetails.merchant !== merchantProfile
        ) {
          setMerchantProfile(merchantProfileDetails);
        }
      } catch (error) {
        console.error('Failed to fetch merchant profile:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!merchantProfile) {
      fetchMerchantProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToSettings = () => {
    isSuperAdmin
      ? navigate('/active-merchants')
      : navigate('/active-merchantId');
    dispatch(superAdminSliceAction.openSettingsModal(merchantSelected));
  };

  const handleSubmit = async (formData: FormData) => {
    if (loading) return;
    setLoading(true);
    try {
      const res :any = await MerchantProfileServices.putMerchantProfile(formData);
      if(res?.isSuccess) {
      setProfileUpdated(true);
      setPopUpText(t('MESSAGE.MERCHANT_PROFILE_UPDATED'));
      setOpenPopup(true);
      }
    } catch (error) {
      console.error('Error updating merchant profile:', error);
      setPopUpText(t('MESSAGE.MERCHANT_PROFILE_ERROR_ON_UPDATE'));
      setOpenPopup(true);
      setProfileUpdated(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader loading />;
  }

  if (!merchantProfile) {
    return <div>No profile data available.</div>;
  }

  return (
    <MerchantProfileView
      merchantProfile={merchantProfile}
      backToSettings={backToSettings}
      submitForm={handleSubmit}
      setOpenPopup={setOpenPopup}
      openPopUp={openPopUp}
      popUpText={popUpText}
      profileUpdated={profileUpdated}
    />
  );
};

export default MerchantProfile;
