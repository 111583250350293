import { configuration } from 'src/config';
import { toast } from 'react-toastify';
import i18n, { t } from 'i18next';
import { apm } from '@elastic/apm-rum';

class UtilityService {
  static errorLogHandler = (
    componentName: string,
    methodName: string,
    errorMessage: string,
    errorLog: string,
  ) => {
    return `${errorLog} component : {${componentName}} method:{${methodName}} ,Error:{${errorMessage}}`;
  };

  static consoleLog = (logMessage: any, logKey: any) => {
    const appEnv = configuration.environment;
    if (appEnv === 'develop' || appEnv === 'qa') {
      if (logKey) {
        console.debug(logKey, logMessage);
      } else {
        console.debug(logMessage);
      }
    }
  };

  static clearNumber = (value = '') => {
    return value?.replace(/\D+/g, '');
  };

  static formatExpirationDate = (e: any) => {
    const clearValue = this.clearNumber(e.target.value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  };

  static showToast = (messageObj: any) => {
    const toastPosition = {
      position: toast.POSITION.TOP_CENTER,
    };

    if (messageObj?.toastType === 'info') {
      const tostMsg = i18n.t(messageObj?.toastMsg);
      toast.info(tostMsg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        theme: 'colored',
        style: { width: '100%' },
      });
    }
    if (messageObj?.toastType === 'success') {
      toast.info(messageObj?.toastMsg, toastPosition);
    }
    if (messageObj?.toastType === 'error') {
      toast.error(messageObj?.toastMsg, toastPosition);
    }
  };

  static notifyParent = (messageObj: any, domainToSend: string) => {
    window?.parentIFrame?.sendMessage(messageObj, domainToSend);
  };

  static formatDomain = (domain: string) => {
    if (!domain) return '';
    if (!domain.startsWith('https://') && !domain.startsWith('http://')) {
      domain = `https://${domain}`;
    }
    if (!domain.endsWith('/')) {
      domain = `${domain}/`;
    }
    return domain;
  };

  static resizeIframeInParentWindow = (size: any) => {
    window?.parentIFrame?.size(size);
  };

  static getParentMessageItem = (itemName: string, dataObj: any) => {
    const parentMsg: any = dataObj;
    if (parentMsg[itemName] !== undefined) {
      return parentMsg[itemName];
    } else {
      return null;
    }
  };

  static isJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  static logout = () => {
    // clear redux storage
  };

  static formatPhoneNumberAllCountry = (phoneNumberString: string) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+' : '';
      return [
        intlCode,
        match[1],
        ' (',
        match[2],
        ') ',
        match[3],
        '-',
        match[4],
      ].join('');
    }
    return null;
  };

  static formatPhoneNumber = (phoneNumberString: string) => {
    const match = this.checkIsMatch(phoneNumberString);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };

  static checkIsMatch = (phoneNumberString: string) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    return cleaned.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
    // return cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
  };

  static detectCardType = (number: any) => {
    const re: any = {
      Electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      Maestro:
        /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      Dankort: /^(5019)\d+$/,
      Interpayment: /^(636)\d+$/,
      UnionPay: /^(62|88)\d+$/,
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      MasterCard:
        /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/,
      AmericanExpress: /^3[47][0-9]{13}$/,
      DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
    };
    for (const key in re) {
      if (re[key].test(number)) {
        return key;
      }
    }
    return null;
  };

  static isGuest = () => {
    //const token = getToken();
    const token = '';
    const isLoggedOut = sessionStorage.getItem('isLoggedOut');
    return (
      isLoggedOut === 'true' ||
      token === undefined ||
      token === null ||
      token === ''
    );
  };

  static isGuestCardLayout = () => {
    if (this.isGuest()) {
      return true;
    } else {
      return false;
    }
  };

  static AmountInIso = (currencyCode: any, amount: any) => {
    return `${currencyCode}${amount * 100}`;
  };

  static AmountInOriginal = (currencyCode: any, amount: any) => {
    const actualAmount: any = amount / 100;
    if (currencyCode === '') {
      return `${Number.parseFloat(actualAmount).toFixed(2)}`;
    } else {
      return `${currencyCode}${Number.parseFloat(actualAmount).toFixed(2)}`;
    }
  };

  static generateUniqueId = () => {
    return `id-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  };

  static getArrayFormUrl = (key: any, data: any) => {
    // add query string in URL as in form of array(& separated)
    let ids = '';
    if (data != null && data !== undefined) {
      const dataArr = data.split(',');
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      let i = 0;
      let adder = '';
      dataArr.forEach((item: any, index: any) => {
        if (index > 0) {
          adder = '&';
        }
        ids += `${adder}${key}=${item}`;
        i++;
      });
    }
    return ids;
  };

  static redirectTo = (returnUrl: any) => {
    // address location to redirect
  };

  static clearLoggedOutSession = () => {
    sessionStorage.removeItem('sessionId');
    sessionStorage.removeItem('isLoggedOut');
  };

  static capitalizedFirstLetter = (str: string) => {
    const arr = str.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  };

  static extractBaseUrl = (str: string) => {
    const splitString = str.split('/');
    const tempArray = [];
    for (let i = 0; i < 3; i++) {
      tempArray.push(splitString[i]);
    }
    return tempArray.join('').replace(':', '://');
  };

  static getAddressObject(addressComponents: any) {
    const ShouldBeComponent: any = {
      home: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      region: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: [
        'locality',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
      ],
      country: ['country'],
    };

    const address: any = {
      home: '',
      postal_code: '',
      street: '',
      region: '',
      city: '',
      country: '',
    };
    addressComponents.forEach((component: any) => {
      for (const shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === 'country' || shouldBe === 'region') {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });
    return address;
  }

  static logElk = (msg?: any) => apm.captureError(msg);

  static randColor = (): string => {
    return (
      '#' +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')
        .toUpperCase()
    );
  };

  static generateLinearGradient = (): string => {
    const color1 = UtilityService.randColor();
    const color2 = UtilityService.randColor();
    return `linear-gradient(132deg, ${color1} -8.37%, ${color2} 92.23%)`;
  };

  static handlePhoneChange = (e: any) => {
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
    let formattedValue = sanitizedValue;
    if (sanitizedValue.length <= 3) {
      formattedValue = sanitizedValue;
    } else if (sanitizedValue.length <= 6) {
      formattedValue = `${sanitizedValue.slice(0, 3)}-${sanitizedValue.slice(3)}`;
    } else if (sanitizedValue.length <= 10) {
      formattedValue = `${sanitizedValue.slice(0, 3)}-${sanitizedValue.slice(3, 6)}-${sanitizedValue.slice(6, 10)}`;
    } else {
      formattedValue = `${sanitizedValue.slice(0, 3)}-${sanitizedValue.slice(3, 6)}-${sanitizedValue.slice(6, 10)}`;
    }
    return formattedValue;
  };
  static formatPhoneField = (value: any) => {
    const sanitizedValue = value.replace(/\D/g, '');
    if (sanitizedValue.length !== 10) {
      return 'Phone number must have exactly 10 digits';
    }
    const areaCode = sanitizedValue.slice(0, 3);
    const exchangeCode = sanitizedValue.slice(3, 6);
    const lineNumber = sanitizedValue.slice(6, 10);

    if (parseInt(areaCode, 10) < 201 || parseInt(areaCode, 10) > 999) {
      return t('ERROR_MESSAGE.PHONE_INVALID');
    }
    if (parseInt(exchangeCode, 10) < 200 || parseInt(exchangeCode, 10) > 999) {
      return t('ERROR_MESSAGE.PHONE_INVALID');
    }
    if (parseInt(lineNumber, 10) < 1 || parseInt(lineNumber, 10) > 9999) {
      return t('ERROR_MESSAGE.PHONE_INVALID');
    }
    return true;
  }
  static validatePostalCode (e:any) {
    let formattedZip = e.target.value.replace(/[^0-9]/g, '');
      if (formattedZip.length > 5) {
       formattedZip = `${formattedZip.slice(0, 5)}-${formattedZip.slice(5, 9)}`;
      }
      return e.target.value = formattedZip
  }
}

export default UtilityService;
