import yup from 'src/services/validator.service';
class ActiveClientModel {
  static readonly sendInvitation = yup.object({
    isEmailSent: yup.boolean().defined(),
    isSuccess: yup.boolean().defined(),
    message: yup.string().nullable().defined(),
  });

  static readonly getWinkTagList = yup.object({
    pageNumber: yup.number().defined(),
    totalRecords: yup.number().defined(),
    pageSize: yup.number().defined(),
    data: yup.array().defined(),
    message: yup.string().nullable().defined(),
  });
}
export default ActiveClientModel;
